import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useParams, useSearchParams } from "react-router-dom";
import { useWorkflowById } from "../../../../../utils/reactQuery/workflows";
import { useSelector } from "react-redux";

export default function StatusDropDown({ workflowId }) {
  const [workflow, setWorkflow] = React.useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const stepId = searchParams.get("workflow_Step");
  const { id } = useParams();
  const { data: workflowSteps } = useWorkflowById(workflowId, id);
  // const gridView = useSelector((state) => state.search.gridView);

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  // if (gridView != false) {
  //   searchParams.delete("workflow_Step");
  // }

  // console.log("gridVIEW", gridView);

  const handleChange = (event) => {
    const workflowStepId = event.target.value;
    setWorkflow(workflowStepId);
    updateSearchParams("workflow_Step", workflowStepId);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">Status</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={workflow}
        label="status"
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {workflowSteps?.data?.data?.responseData.map((item) => (
          <MenuItem value={item.workflow_step_id}>{item.status}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
