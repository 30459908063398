import { Button, Stack } from '@mui/material'
import React from 'react'

const CardNotFound = ({handleReload, title, btnTitle,icon}) => {
  return (
    <Stack height={"80vh"} justifyContent={"center"} alignItems={"center"}>
    <div style={{ padding: "20px", textAlign: "center" }}>
      <img src={icon} alt="error" style={{ height: "140px" }} />
      <h2>{title}</h2>
      <Button
        variant="outlined"
        color="error"
        sx={{ marginTop: "16px" }}
        onClick={handleReload}
      >
       {btnTitle}
      </Button>
    </div>
  </Stack>
  )
}

export default CardNotFound