import React, { useRef, useEffect, useState } from "react";
import KanbanColumn from "./KanbanColumn";
import { useSearchParams } from "react-router-dom";

function KanbanContainor({
  workflowSteps,
  projects,
  onDragEnter,
  onDragEnd,
  callback,
}) {
  const boardRef = useRef(null);
  const [searchParams] = useSearchParams();
  const isGeneric = searchParams.get("is_generic");
  // const isTeam = Boolean(searchParams.get("switch"));
  const [maxHeight, setMaxHeight] = useState(0);
  const [isTeam, setIsTeam] = useState(searchParams.get("switch") === "true");

  useEffect(() => {
    const switchParam = searchParams.get("switch");
    setIsTeam(switchParam === 'true');
  }, [searchParams]);
  const priorityOrder = {
    Critical: 1,
    High: 2,
    Medium: 3,
    Low: 4,
  };
  
  const handleOnDrag = (e) => {
    const board = boardRef.current;
    if (!board) return;
    const { clientX, clientY } = e;
    const buffer = 50; // Distance from the edge of the container to start scrolling
    const scrollSpeed = 25; // Speed of the scroll

    // Horizontal scroll
    if (clientX - board.offsetLeft < buffer) {
      board.scrollBy({ left: -scrollSpeed, behavior: "smooth" });
    } else if (board.offsetLeft + board.clientWidth - clientX < buffer) {
      board.scrollBy({ left: scrollSpeed, behavior: "smooth" });
    }

    // Vertical scroll
    if (clientY - board.offsetTop < buffer) {
      board.scrollBy({ top: -scrollSpeed, behavior: "smooth" });
    } else if (board.offsetTop + board.clientHeight - clientY < buffer) {
      board.scrollBy({ top: scrollSpeed, behavior: "smooth" });
    }
  };

  const groupedCardByWorkFlowSteps = projects.reduce((acc, item) => {
    const stepId = item?.card?.workflow_step_id;
    const genStepId = item?.generic_step_id

    if (isGeneric == "true") {
      if (!acc[genStepId]) {
        acc[genStepId] = [];
      }
      acc[genStepId].push(item);
    } else {
      if (!acc[stepId]) {
        acc[stepId] = [];
      }
      acc[stepId].push(item);
    }
    return acc;
  }, {});

  useEffect(() => {
    const calculateMaxHeight = Object.values(groupedCardByWorkFlowSteps)
      ?.map((cardGroup) => Array.isArray(cardGroup) ? cardGroup.length : 0); // Get the length of each group or 0 for invalid entries

    const tallestHeight = Math.max(...calculateMaxHeight); // Spread the array to pass its values to Math.max

    setMaxHeight(tallestHeight);
  }, [groupedCardByWorkFlowSteps]);


  return (
    <div
      ref={boardRef}
      style={{
        display: "flex",
        overflowX: "scroll",
      }}
      onDragOver={handleOnDrag}>
      {workflowSteps.map((column) => {
        const filteredProjects =
          groupedCardByWorkFlowSteps?.[column?.workflow_step_id] ?
          groupedCardByWorkFlowSteps[column?.workflow_step_id]?.sort((a, b) =>
            priorityOrder[a?.card?.priority] - priorityOrder[b?.card?.priority]
          ) : [];

        return (
          <KanbanColumn
            callback={callback}
            key={column?.workflow_step_id}
            id={column?.workflow_step_id}
            name={column?.status}
            stage={column?.workflow_step_id}
            projects={filteredProjects}
            onDragEnter={onDragEnter}
            onDragEnd={onDragEnd}
            maxHeight={isTeam ? maxHeight : ""}
          />
        );
      })}

    </div>
  );
}

export default KanbanContainor;
