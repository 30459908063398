import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useLebels } from "../../../../../../utils/reactQuery/boards";
import { createAndUpdateLabel } from "../../../../../../sevices/apiCards";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

export default function ComboBox({
  labels,
  boardId,
  cardId,
  setShowLebel,
}) {
  const [newLabel, setNewLabel] = React.useState(
    labels.map((item) => item.label)
  );
  const [manualEntered, setManualEntered] = React.useState("");
  const [isPresent, setIsPresent] = React.useState(false);
  const queryClient = useQueryClient();
  const { isLoading, error, lebels: lebelsOpt, isError } = useLebels(boardId);

  const options =
    lebelsOpt?.data?.data?.responseData.map((item) => item.label) || [];

  const { mutate: createAndUpdateLabelMutate } = useMutation({
    mutationFn: createAndUpdateLabel,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`lebels`, boardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, boardId.toString()],
      });
      queryClient.invalidateQueries({
        queryKey: [`mineCards`],
      });
      queryClient.invalidateQueries({
        queryKey: [`watching`],
      });
      queryClient.invalidateQueries({
        queryKey: [`history`, cardId],
      });
      // setLoading(false);
      // console.log(res);
      //    reset();
      toast.success(res.data.message);
      setShowLebel(false);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
    },
  });

  const handleInputChange = (event, newInputValue) => {
    setNewLabel(newInputValue.filter((e) => e.length > 0));

  };

  const enterCallback = (e) => {
    if (e.key === "Enter") {
      if (!isPresent && manualEntered !== "" && manualEntered !== 0) {
        createAndUpdateLabelMutate({
          card_id: cardId,
          data: { labels: [...newLabel, manualEntered] },
        });
      }
      else if (labels.length != newLabel.length) {
        createAndUpdateLabelMutate({
          card_id: cardId,
          data: { labels: [newLabel] },
        });
      }
      setShowLebel(false);
    }
  };

  const handleBlur = (e) => {

    if (!isPresent && manualEntered !== "") {
      createAndUpdateLabelMutate({
        card_id: cardId,
        data: { labels: [...newLabel, manualEntered] },
      });
    }
    else if (labels.length != newLabel.length) {
      createAndUpdateLabelMutate({
        card_id: cardId,
        data: { labels: [newLabel] },
      });
    }
    setShowLebel(false);
  };

  const handleChange = (event) => {
    const currentLabel = `${event?.target?.value}`?.trim();
    const res = newLabel.some((item) => item?.toLowerCase() === currentLabel?.toLowerCase())

    if (!res) {
      setManualEntered(currentLabel);
      setIsPresent(false)
    } else {
      setIsPresent(true);
    }
  };


  return (
    <Autocomplete
      onBlur={handleBlur}
      autoFocus
      multiple
      id="size-small-outlined-multi"
      size="small"
      options={options}
      defaultValue={labels.map((item) => item.label)}
      value={newLabel}
      onInputChange={handleChange}
      onChange={handleInputChange}
      onKeyDown={enterCallback}
      sx={{
        width: "100%",
        height: "32px",
        marginRight: "0px",
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoFocus: true,
            }}
            style={{
              background: "white",
            }}
          />
        );
      }}
    />
  );
}