import React, { useEffect, useState } from "react";
import MultiSelctCheckboxDropdown from "../../../../components/CheckboxMultiselect";
import { IconButton } from "@mui/material";
import downArrowImg from "../../../../styles/svg/downArrow.svg";
import { useSelector } from "react-redux";
import { useWorkflowById } from "../../../../utils/reactQuery/workflows";
import { useParams, useSearchParams } from "react-router-dom";
const StatusColumHeader = () => {
  const [selectedNames, setSelectedNames] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const newSearchParams = new URLSearchParams(searchParams);
  const stepId = useSelector(
    (state) => state.listViewHeaderFilters.workFlowStepIds
  );
  const workflowId = useSelector(
    (state) => state.listViewHeaderFilters.workflowId
  );
  const { id: boardId } = useParams();
  const { data: workflows } = useWorkflowById(workflowId, boardId);
  const showStatus =
    workflows &&
    workflows.data?.data?.responseData?.filter((workflow) =>
      stepId.includes(workflow.workflow_step_id)
    );
  const statusSearch = searchParams.get("workflow_Step");

  useEffect(() => {
    if (!isNaN(Number(statusSearch))) {
      setSelectedNames([]);
    }
  }, [statusSearch]);
  const handleSelectionChange = (newSelection) => {
    setSelectedNames(newSelection);

    if (newSelection.length > 0) {
      newSearchParams.set("statusFilter", newSelection.join(","));
    } else {
      newSearchParams.delete("statusFilter");
    }
    setSearchParams(newSearchParams);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearFilter = () => {
    setSelectedNames([]);
    newSearchParams.delete("statusFilter");
    setSearchParams(newSearchParams);
  };

  return (
    <>
      <button className="remove-default-button-style">
        <span style={{ paddingLeft: "17px" }}>Status</span>
        <IconButton onClick={handleClick}>
          <img src={downArrowImg} alt="arrow" />
        </IconButton>
      </button>
      <MultiSelctCheckboxDropdown
        items={showStatus}
        selectedItems={selectedNames}
        onSelectionChange={handleSelectionChange}
        isButton={true}
        anchorEl={anchorEl}
        valueKey="workflow_step_id"
        displayKey="status"
        handleClose={() => setAnchorEl(null)}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
};

export default StatusColumHeader;
