import * as React from "react";
// import { useState, useEffect } from "react";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";
// import Avatar from "@mui/material/Avatar";
// import Typography from "@mui/material/Typography";
// import { Button } from "@mui/base";
import { useComment } from "../../../../../../utils/reactQuery/cards";
// import Loader from "../../../../../components/Loader";
// import EditTextFields from "../editComment/editComment";
// import ConfirmationDialog from "../../../../../components/Dialog/ConfirmationDialog";
import "./showComments.scss";
// import { useMutation, useQueryClient } from "@tanstack/react-query";
// import toast from "react-hot-toast";
// import { deleteComment } from "../../../../../sevices/apiComment";
import ListComment from "./ListComment";
import Loader from "../../../../../../components/Loader";
export default function AutoGridNoWrap({ cardId }) {
  const {
    isLoading: isCommentLoading,
    comment,
    isError: isCommentError,
  } = useComment(cardId);

  const sortCommentsByDateDescending = (comment) => {
    // Check if comments array is defined and not null before sorting
    if (comment && comment.length > 0) {
      return comment.sort(
        (a, b) => new Date(b.comment_time) - new Date(a.comment_time)
      );
    } else {
      return []; // Return an empty array if comments are not defined
    }
  };

  return (
    <>
      {isCommentLoading ? (
        <div className="comment-loader">
          <Loader color={"black"} />
        </div>
      ) : (
        sortCommentsByDateDescending(comment?.data?.data?.responseData).map(
          (data) => <ListComment data={data} cardId={cardId} />
        )
      )}
    </>
  );
}

// const commentList = [
//   {
//     image: "",
//     name: "Joy",
//     time: "March 12,2024 at 12:39 PM",
//     commentDesc:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam Etiam posuere dui vel urna egestas rutrum.",
//   },
//   {
//     image: "",
//     name: "Joel",
//     time: "March 12,2024 at 12:39 PM",
//     commentDesc:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam posuere dui vel urna egestas rutrum.",
//   },
//   {
//     image: "",
//     name: "Joel",
//     time: "March 12,2024 at 12:39 PM",
//     commentDesc:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam posuere dui vel urna egestas rutrum.",
//   },
//   {
//     image: "",
//     name: "Joel",
//     time: "March 12,2024 at 12:39 PM",
//     commentDesc:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam posuere dui vel urna egestas rutrum.",
//   },
// ];
