import * as React from "react";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useCards } from "../../../../../../utils/reactQuery/cards";
import DownArrow from "../../../../../../styles/svg/downArrow.svg";
import { updateParentCard } from "../../../../../../sevices/apiCards";
import ConfirmationDialog from "../../../../../../components/Dialog/ConfirmationDialog";
import DropdownWithSearchbar from "../../../../../../components/DropdownWithSearchbar";

export default function DependsOnDropdown({ cardId, card }) {
  const [boardCards, setBoardCards] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [changeParentDialog, setChangeParentDialog] = useState(false);
  const [parentId, setParentId] = useState(null);
  const open = Boolean(anchorEl);
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);
  const { parent_card_id, board_id } = card?.data?.data?.responseData;
  const { isLoading, cards, isError } = useCards(board_id);
  const data = cards?.data?.data?.responseData;

  useEffect(() => {
    if (data) {
      setBoardCards(data);
    }
  }, [data]);

  const filteredCards = boardCards?.filter((item) => {
    return (
      item?.card?.card_id !== cardId &&
      item?.card?.card_id !== parent_card_id &&
      !item?.card?.parent_card_id &&
      !item?.sub_tasks?.some(
        (subtaskItem) => subtaskItem === item?.card?.card_id
      ) &&
      (item?.key + "-" + item?.card?.card_id)
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  });

  const { mutate: updateCardmutate} = useMutation({
    mutationFn: updateParentCard,
    onSuccess: (res) => {
      setLoading(false)
      queryClient.invalidateQueries({
        queryKey: [`card`],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, String(board_id)],
      });
      queryClient.invalidateQueries({
        queryKey: [`mineCards`],
      });
      queryClient.invalidateQueries({
        queryKey: ["watching"]
      })
      toast.success(res.data.message);
      setChangeParentDialog(false);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false)
    },
  });

  const handleDependsClose = (cardId) => {
    setParentId(cardId);
    setChangeParentDialog(true);
  };
  const handleChangeParent = (event) => {
    setLoading(true)
    updateCardmutate({
      card_id: cardId,
      parent_card_id: parentId,
    });
  };
  return (
    <>
      {changeParentDialog && (
        <ConfirmationDialog
          open={changeParentDialog}
          handleClose={() => setChangeParentDialog(false)}
          message={
            parentId === 0
              ? "Are you sure you want to delete the parent?"
              : "Are you sure you want to change parent task."
          }
          callback={handleChangeParent}
          loading={loading}
          btnTitle="change"
          title={parentId === 0 ? "Remove parent" : "Parent Task Change"}
        />
      )}
      <div className="mr-2">
        <Button
          variant="outlined"
          color="primary"
          id="epics-board-dropdown"
          aria-haspopup="true"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          className="filter-btn white-button"
          endIcon={<img src={DownArrow} alt="" />}
          sx={{
            height: "34px",
            "&:hover": {
              outline: "none",
              backgroundColor: "rgb(240 249 254)",
              border: "1px solid #1b3e61",
            },
          }}
        >
          <p className="header-action-button-text mr-1">
            {parent_card_id
              ? "Change Parent"
              : "parent Task"}
          </p>
        </Button>

        <DropdownWithSearchbar
          anchorEl={anchorEl}
          open={open}
          setAnchorEl={setAnchorEl}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          filteredCards={filteredCards}
          handleConfirmationPopup={handleDependsClose}
          renderItemLabel={(item) => `${item.key}-${item.card.card_id}`}
        />
      </div>
    </>
  );
}
