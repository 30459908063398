import React from "react";
import { Stack, Tooltip } from "@mui/material";
import CustomizedProgressBars from "../../pages/board/components/progressBar/ProgressBar";
import { truncateText } from "../../utils/helpers";
import CardId from "../../components/dataGridColumn/CardId";

export const dataGridColumn = [
  {
    field: "col1",
    headerName: "Task Id",
    minWidth: 124,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => (
      <CardId boardKey={value?.value?.key} id={value?.value?.card?.card_id} />
    ),
  },
  {
    field: "col2",
    headerName: "Task Title",
    minWidth: 270,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: true,
    hide: true,
    renderCell: (value) => (
      <Tooltip title={value.value}>{truncateText(value.value, 60)}</Tooltip>
    ),
  },
  {
    field: "col3",
    headerName: "Task Status",
    minWidth: 182,
    // type: "boolean",
    headerAlign: "center",
    flex: 1,
    sortable: true,
    resizable: false,
    renderCell: (value) =>
      value.value ? <span className="card-status">{value.value}</span> : "",
  },

  {
    field: "col5",
    headerName: "Assignee",
    minWidth: 136,
    flex: 1,
    sortable: true,
    resizable: false,
    renderCell: (value) => <span>{value.value}</span>,
  },
  {
    field: "col6",
    headerName: "Label",
    minWidth: 136,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => (
      <span style={{ color: "#FD7401" }}>{value.value}</span>
    ),
  },
  {
    field: "col7",
    headerName: "Milestone",
    minWidth: 182,
    flex: 1,
    sortable: true,
    resizable: false,
    renderCell: (value) => (
      <span style={{ color: "#904EE2" }}>{value.value}</span>
    ),
  },
  {
    field: "col8",
    headerName: "Allocated Hours",
    minWidth: 182,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => (
      <span>
        {value.value} {value.value ? "hours" : ""}
      </span>
    ),
  },
  {
    field: "col9",
    headerName: "Hours Spent",
    minWidth: 182,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => (
      <span>
        {value.value} {value.value ? (value.value == 1 ? "hour" : "hours") : ""}
      </span>
    ),
  },
  {
    field: "col10",
    headerName: "Time Tracking",
    minWidth: 206,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => (
      <Stack sx={{ width: "100%" }}>
        <CustomizedProgressBars
          allocatedHours={value?.value.allotedHours}
          hoursSpent={value?.value.hoursSpent ? value?.value.hoursSpent : "0"}
        />
      </Stack>
    ),
  },
  {
    field: "col11",
    headerName: "Due Date",
    minWidth: 160,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => <span>{value.value}</span>,
  },
  {
    field: "col12",
    headerName: "Created Date",
    minWidth: 160,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => <span>{value.value}</span>,
  },
  {
    field: "col13",
    headerName: "Reporter",
    minWidth: 160,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => <span>{value.value}</span>,
  },
];
