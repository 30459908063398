import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import BasicTextFields from "./addComment/addComment";
import AutoGridNoWrap from "./showComments/showComment";
import ShowWorklog from "../../worklog/showWorklog";
import "./detailGrid.scss";
import Description from "./description/description";
import SidebarGrid from "../sidebar/sidebar";
import AttachmentCard from "../showDetailAttachment/showDetailAttachment";
import History from "./history";
import AllDetail from "./showAllDetail/AllDetail";
import EditTitle from "./editTitle";
import { currentUserId, isAdmin } from "../../../../../utils/constants";
import commentIcon from "../../../../../styles/svg/commentIcon.svg";
import worklogIcon from "../../../../../styles/svg/worklogIcon.svg";
import historyIcon from "../../../../../styles/svg/historyIcon.svg";
import { useCard } from "../../../../../utils/reactQuery/cards";
import { useResponsive } from "../../../../../hooks/useResponsive";

function BasicGrid({
  cardId,
  description,
  title,
  data,
  descRef,
  isfocus,
  handleDescriptionSave,
  cardsOnBoard,
}) {
  const [activeTab, setActiveTab] = useState("all");
  const [textEditor, setTextEditer] = useState(false);

  const { board : { owner_user_id : boardOwnerId }} = data;

  const isMobile = useResponsive("down", "md");
  const {
    isLoading: isCardLoading,
    card,
    isError: isCardError,
  } = useCard(cardId);

  const conditionCheck =
    isAdmin() ||
    boardOwnerId === currentUserId() ||
    card?.data?.data?.responseData?.assigned_to_user_id === currentUserId() ||
    card?.data?.data?.responseData?.reporter === currentUserId();

  const handleActiveTab = (activeItem) => {
    setActiveTab(activeItem);
  };

  const handleTextEditor = () => {
    if (
      isAdmin() ||
      boardOwnerId == currentUserId() ||
      data?.reporter == currentUserId()
    ) {
      setTextEditer(true);
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={isMobile ? 12 : 8}
            sx={{
              height: "100vh",
              overflowY: "auto",
              paddingTop: isMobile ? "8px !important" : "",
              paddingBottom: isMobile ? "100px !important" : "0",
            }}
          >
            <div>
              <EditTitle cardId={cardId} title={title} data={data} />
              <div className="description">Description</div>

              <Description
                cardId={cardId}
                description={description}
                setTextEditer={() => setTextEditer(false)}
                isTextEditor={textEditor}
                handleTextEditor={handleTextEditor}
                accessToUpdate={conditionCheck}
                descRef={descRef}
                isfocus={isfocus}
                handleDescriptionSave={handleDescriptionSave}
              />

              <AttachmentCard cardId={cardId} />

              {isMobile ? <SidebarGrid cardId={cardId} /> : ""}
              <div
                className={`d-flex ${
                  isMobile ? "mobile-activity-dropdown" : "right-btns"
                }`}
              >
                <div className="activity-message">Activity</div>

                {isMobile ? (
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={age}
                        label={
                          activeTab.charAt(0).toUpperCase() + activeTab.slice(1)
                        }
                        // onChange={handleChange}
                      >
                        <MenuItem
                          value="All"
                          onClick={() => handleActiveTab("all")}
                        >
                          All
                        </MenuItem>
                        <MenuItem
                          value="Worklog"
                          onClick={() => handleActiveTab("worklog")}
                        >
                          Worklogs
                        </MenuItem>
                        <MenuItem
                          value="Comment"
                          onClick={() => handleActiveTab("comment")}
                        >
                          Comments
                        </MenuItem>
                        <MenuItem
                          value="History"
                          onClick={() => handleActiveTab("history")}
                        >
                          History
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <div className="d-flex justify-content-center align-items-center activity-btn">
                    <div className="show">Show:</div>

                    <Button
                      sx={{
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "18.23px",
                        letterSpacing: "0.02em",
                        color: "#212B36",
                        border: "none",
                        opacity: "0.7",
                        backgroundColor: "#F2F4F6",
                        // transition: clicked ? "none" : "",
                        "&:hover": {
                          backgroundColor: "#F2F4F6",
                          border: "none",
                          // transition: "none !important",
                        },
                      }}
                      className={activeTab === "all" && "btn-active"}
                      variant="outlined"
                      onClick={() => {
                        handleActiveTab("all");
                      }}
                    >
                      All
                    </Button>

                    <Button
                      variant="outlined"
                      sx={{
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "18.23px",
                        letterSpacing: "0.02em",
                        color: "#212B36",
                        border: "none",
                        opacity: "0.7",
                        backgroundColor: "#F2F4F6",
                        "&:hover": {
                          backgroundColor: "#F2F4F6",
                          border: "none",
                        },
                      }}
                      className={activeTab === "worklog" && "btn-active"}
                      onClick={() => {
                        handleActiveTab("worklog");
                      }}
                    >
                      <img className="icon" alt="icon" src={worklogIcon} />
                      WORKLOG
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                        fontWeight: "500",
                        // lineHeight: "18.23px",
                        letterSpacing: "0.02em",
                        color: "#212B36",
                        border: "none",
                        opacity: "0.7",
                        backgroundColor: "#F2F4F6",
                        "&:hover": {
                          backgroundColor: "#F2F4F6",
                          border: "none",
                        },
                      }}
                      className={activeTab === "comment" && "btn-active"}
                      onClick={() => {
                        handleActiveTab("comment");
                      }}
                    >
                      <img className="icon" src={commentIcon} alt="" />
                      <div>COMMENTS</div>
                    </Button>
                    <Button
                      sx={{
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "18.23px",
                        letterSpacing: "0.02em",
                        color: "#212B36",
                        border: "none",
                        opacity: "0.7",
                        backgroundColor: "#F2F4F6",
                        // transition: clicked ? "none" : "",
                        "&:hover": {
                          backgroundColor: "#F2F4F6",
                          border: "none",
                          // transition: "none !important",
                        },
                      }}
                      className={activeTab === "history" && "btn-active"}
                      variant="outlined"
                      onClick={() => {
                        handleActiveTab("history");
                      }}
                    >
                      <img className="icon" src={historyIcon} alt="" />
                      HISTORY
                    </Button>
                  </div>
                )}
              </div>
              {activeTab === "all" && (
                <div>
                  <AllDetail cardId={cardId} />
                </div>
              )}
              {activeTab === "comment" && (
                <div>
                  <BasicTextFields cardId={cardId} />
                  <div>
                    <AutoGridNoWrap cardId={cardId} />
                  </div>
                </div>
              )}
              {activeTab === "worklog" && (
                <div>
                  <ShowWorklog textEditor={textEditor} cardId={cardId} />
                </div>
              )}
              {activeTab === "history" && <History cardId={cardId} />}
            </div>
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            {!isMobile ? (
              <>
                <SidebarGrid cardId={cardId} cardsOnBoard={cardsOnBoard} />
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default React.memo(BasicGrid);
