import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { Button, DialogContent, DialogTitle, IconButton } from "@mui/material";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import Form from "./Form";
import List from "./List";

import { createAttachments } from "../../../../sevices/apiBoards";
import { useResponsive } from "../../../../hooks/useResponsive";

export default function AnchorTemporaryDrawer({ toggleDrawer, state }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const userData = useSelector((state) => state.users.logInUser);
  const [name, setName] = useState([{ name: "" }]);
  const [file, setFile] = useState([{ file: null }]);
  const [attachments, setAttachments] = useState([{ file: null }]);
  const isMobile = useResponsive("down", "md");

  const handleNameChange = (e, index) => {
    const newName = [...name];
    newName[index] = { name: e.target.value };
    setName(newName);
  };
  const handleFileChange = (e, index) => {
    if (e.target.files.length === 0) {
      setName([{ name: "" }]);
      setFile([{ file: null }]);
    } else {
      setFile([...e.target.files]);
    }
  };

  const handleFile = () => {
    const target = document.getElementById("input-file-field");
    target.value = "";
  };

  const handleSubmit = () => {
    const formData = file.map((item, index) => ({
      name: name[index]?.name ?? item.name,
      file: file[index],
    }));

    const fileData = new FormData();
    fileData.append("board_id", id);
    fileData.append("user_id", userData.user_id);

    const namesArray = formData.map((item) => item.name);
    const filesArray = formData.map((item) => item.file);

    fileData.append("names", namesArray.join(", "));
    filesArray.forEach((file) => {
      fileData.append("files", file);
    });
    if (formData[0].file.name) {
      createAttachmentMutate(fileData);
    } else {
      toast.error("Please upload file");
    }
  };

  const resetState = () => {
    setName([{ name: "" }]);
    setFile([{ file: null }]);
  };
  const handleDrawerClose = (anchor) => {
    resetState();
    toggleDrawer(anchor, false)();
  };

  const { mutate: createAttachmentMutate, isPending } = useMutation({
    mutationFn: createAttachments,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`attachments-${id}`],
      });
      toast.success("Attachment added successfully");
      resetState();
      handleFile();
    },
    onError: (error) => {
      console.error("Error Add Attachments", error);
      toast.error(error.response.data.message);
    },
  });

  const list = (anchor) => (
    <Box
      sx={{
        width: isMobile
          ? 300
          : anchor === "top" || anchor === "bottom"
          ? "auto"
          : 520,
      }}
      role="presentation"
    >
      <DialogTitle
        id="customized-dialog-title"
        className="blue-text-color attachment-header-text d-flex justify-content-space-between dialog-title-bgcolor"
      >
        Attachments
        <div className="mr-40">
          <Button
            onClick={() => {
              handleSubmit();
            }}
            variant="contained"
            className="theme-bg-color add-attachment-btn"
            disabled={file[0].file === null || isPending}
          >
            {isPending ? "Loading..." : "Add"}
          </Button>
        </div>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => handleDrawerClose(anchor)}
        className="add-attachment-crossBtn"
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className={isMobile ? "attachment-content" : ""}>
        <Form
          file={file}
          name={name}
          handleNameChange={handleNameChange}
          handleFileChange={handleFileChange}
          isMobile={isMobile}
        />
        <Box sx={{ height: "24px" }}></Box>
        <List isMobile={isMobile} />
      </DialogContent>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={() => handleDrawerClose(anchor)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
