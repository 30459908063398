import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useForm } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import removeStep from "../../../../styles/svg/removeStep.svg";
import arrow from "../../../../styles/svg/arrow.svg";
import { MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import {
  deleteWorkflow,
  deleteWorkflowStep,
} from "../../../../sevices/apiWorkflows";
import Field from "../../../../components/field";
export default function RemoveStep({
  showRemoveSteps,
  setShowRemoveSteps,
  currentStep,
  fields,
  close,
}) {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    register,
    handleSubmit: updateStepSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const queryClient = useQueryClient();
  const { mutate: deleteWorkflowMutate } = useMutation({
    mutationFn: deleteWorkflowStep,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`adminWorkflow`],
      });
      queryClient.invalidateQueries({
        queryKey: [`boards`],
      });
      toast.success(res.data.message);
      close(false);
      // handleDeleteClose();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      //   setLoading(false);
      // handleDeleteClose();
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setShowRemoveSteps(false);
  };

  const onSubmit = (data) => {
    const { targetStep } = data;
    const selectedWorkflowStepId =
      targetStep ||
      (fields.length > 0
        ? fields.find(
            (item) => item.workflow_step_id !== currentStep.workflow_step_id
          )?.workflow_step_id
        : "");

    if (selectedWorkflowStepId) {
      deleteWorkflowMutate({
        workflow_step_id: currentStep.workflow_step_id,
        target_workflow_step_id: selectedWorkflowStepId,
      });
    }

    // setTargetStepId(data)
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Dialog
        fullScreen={fullScreen}
        open={showRemoveSteps}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="removeStepContainer"
        PaperProps={{
          sx: {
            borderRadius: "12px",
            maxHeight: "90vh",
            maxWidth: "500px",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title" className="removeStepdialog">
          <img src={removeStep} alt="" />
        </DialogTitle>
        <DialogContent className="content">
          <p className="content-heading">Remove Step</p>
          <DialogContentText>
            You are about to remove the step '{" "}
            <span className="content-span">{currentStep.status}</span>.' There
            are <span className="content-span">{}</span> tasks assigned to
            this step. Please reassign these tasks to another step before
            proceeding.
          </DialogContentText>
        </DialogContent>
        <form onSubmit={updateStepSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              <p className="ticket-reasign-step">Task Reassignment Step</p>
            </DialogContentText>

            <div className="ticket-reasign-step-container">
              <div className="initial-step">
                <TextField
                  className="mui-status-field"
                  disabled
                  value={currentStep.status}
                />
              </div>
              <img src={arrow} alt="" />
              <div className="initial-step">
                <Field
                  className="mui-status-field"
                  required
                  id={`targetStep`}
                  name={`targetStep`}
                  // error={errors?.workflow_id ? true : false}
                  // helperText={
                  //   errors?.workflow_id && errors.workflow_id.message
                  // }
                  select
                  // defaultValue={fields.filter((item)=>item.workflow_step_id !== currentStep.workflow_step_id)[0].workflow_step_id}
                  defaultValue={
                    fields.length > 0
                      ? fields.filter(
                          (item) =>
                            item.workflow_step_id !==
                            currentStep.workflow_step_id
                        )[0]?.workflow_step_id
                      : ""
                  }
                  {...register(`targetStep`)}
                >
                  {fields.length > 1 ? (
                    fields
                      .filter(
                        (item) =>
                          item.workflow_step_id !== currentStep.workflow_step_id
                      )
                      .map((option) => (
                        <MenuItem
                          key={`option.workflow_step_id`}
                          id={`option.workflow_step_id`}
                          value={option.workflow_step_id}
                        >
                          {option.status}
                        </MenuItem>
                      ))
                  ) : (
                    <MenuItem disabled value="">
                      No step to select
                    </MenuItem>
                  )}
                </Field>
              </div>
            </div>
          </DialogContent>

          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              className="remove-step-btn-save"
              autoFocus
              variant="contained"
              //   disabled={isloading}
              onClick={updateStepSubmit(onSubmit)}
              // type="submit"
            >
              <div className="btn-content">Update</div>
            </Button>
            <Button
              className="remove-step-btn-cancel"
              autoFocus
              variant="outlined"
              //   disabled={isloading}
              onClick={handleClose}
              // onClick={()=>close(false)}
            >
              <div className="btn-content">Cancel</div>
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
