import { Box, Divider, Tab, Tabs } from "@mui/material";
import React from "react";

function TabsController({ value, onChange }) {
  return (
    <>
      <Divider className="mt-2" />
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
        <Tabs
          value={value}
          onChange={(event, newValue) => onChange(event, newValue)}
          textColor="primary"
          indicatorColor="primary"
          aria-label="controlled tabs with specific values"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#003366",
              display: "none",
            },
          }}
          sx={{
            ".MuiTabs-flexContainer": {
              justifyContent: "flex-end",
            },
            minHeight: "32px",
          }}>
          <Tab
            value="users"
            label="Users"
            className={value === "users" ? "custom-tab-active" : "custom-tab"}
          />
          <Tab
            value="by-board"
            label="By Board"
            className={
              value === "by-board" ? "custom-tab-active" : "custom-tab"
            }
          />
          {/* <Tab
            value="by-team"
            label="By Team"
            className={value === "by-team" ? "custom-tab-active" : "custom-tab"}
          /> */}
        </Tabs>
      </Box>
      <Divider />
    </>
  );
}

export default TabsController;
