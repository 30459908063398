import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Button, Typography } from "@mui/material";

export default function RHFFileInputButton({
  name,
  label,
  startIcon,
  showError = true,
  children,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <input
            id={name}
            type="file"
            hidden
            onChange={(e) => {
              const file = e.target.files[0];
              field.onChange(file); // Updates React Hook Form value
            }}
            {...other}
          />
          <label htmlFor={name}>
            {children ? (
              children
            ) : (
              <Button
                variant="contained"
                component="span"
                startIcon={startIcon}>
                {label || "Upload"}
              </Button>
            )}
          </label>
          {!children && field.value && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Selected File: {field.value.name}
            </Typography>
          )}
          {error && showError && (
            <Typography color="error" variant="body2">
              {error.message}
            </Typography>
          )}
        </div>
      )}
    />
  );
}
