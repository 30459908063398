import { RouterProvider, createBrowserRouter, useNavigate } from "react-router-dom";
import { Auth } from "./Auth/Auth";
import { Pages } from "./Pages/Pages";


const router = createBrowserRouter([Auth, Pages]);

function Router() {
  return <RouterProvider router={router} />;
}

export default Router;
