import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import toast from "react-hot-toast";
import { updateUser } from "../../../../sevices/apiUser";
import { objectToFormData } from "../../../../utils/helpers";
import AdminLogo from "../../../../styles/svg/admin.svg";
import UserLogo from "../../../../styles/svg/not-owner.svg";
import { localStorageInstance } from "../../../../utils/localStorage";
const Admin = ({ isSuperAdmin, id }) => {
  const [status, setStatus] = useState(isSuperAdmin);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [activeDialog, setActiveDialog] = useState(false);
  const userId = localStorageInstance.getItem("userId");

  const handleCloseDialog = () => {
    setActiveDialog(false);
  };

  const handleOpenDialog = () => {
    setActiveDialog(true);
  };

  const { mutate: userMutate } = useMutation({
    mutationFn: updateUser,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      //    queryClient.invalidateQueries({
      //      queryKey: ["boardName", id],
      //    });
      setStatus((data) => !data);
      setLoading(false);
      toast.success(res.data.message);
      handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error User Mutate", error);
      toast.error(error.response.data.message);
      setLoading(false);
      handleCloseDialog();
    },
  });

  const callback = () => {
    if (isSuperAdmin == "Super Admin") {
      const data = objectToFormData({ role: "User" });
      setLoading(true);
      userMutate({ id, data });
    }
    if (isSuperAdmin == "User") {
      const data = objectToFormData({ role: "Super Admin" });
      setLoading(true);
      userMutate({ id, data });
    }
  };

  return (
    <>
      {activeDialog && (
        <ConfirmationDialog
          open={activeDialog}
          handleClose={handleCloseDialog}
          message={
            isSuperAdmin == "Super Admin"
              ? "Are you sure you want to Withdraw Admin Roll ?"
              : "Are you sure you want to Grant Admin Roll ?"
          }
          callback={callback}
          loading={loading}
          btnTitle={isSuperAdmin == "Super Admin" ? "Withdrawl" : "Grant"}
          title={
            isSuperAdmin == "Super Admin"
              ? "Withdrawl admin Roll"
              : "Grant Admin Roll"
          }
        />
      )}
      {isSuperAdmin == "Super Admin" ? (
        <img
          src={AdminLogo}
          onClick={userId !== id ? handleOpenDialog : () => ""}
          style={{
            cursor: userId === id ? "not-allowed" : "pointer",
            opacity: userId === id ? 0.6 : 1,
          }}
          alt="admin"
        />
      ) : (
        <img
          src={UserLogo}
          onClick={userId !== id ? handleOpenDialog : () => ""}
          style={{
            cursor: userId === id ? "not-allowed" : "pointer",
            opacity: userId === id ? 0.6 : 1,
          }}
          alt="admin"
        />
      )}
    </>
  );
};

export default Admin;
