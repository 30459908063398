import * as React from "react";
import { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import CloseRounded from "@mui/icons-material/CloseRounded";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useCard } from "../../../../../../../../utils/reactQuery/cards";
import Loader from "../../../../../../../../components/Loader";
import lockIcon from "../../../../../../../../styles/svg/lock-milestone.svg";
export default function SingleSelectDropdown({
  epicsName,
  cardId,
  handleEpicBlur,
  showEpic,
  setShowEpic,
}) {
  const [selectEpic, setSelectEpic] = useState("");
  const [openList, setOpenList] = useState(true);

  const {
    isLoading: isCardLoading,
    card,
    isError: isCardError,
  } = useCard(cardId);
  const data = card?.data?.data?.responseData;

  useEffect(() => {
    // Update the state if defaultValue changes
    if (data?.epic?.epic_id) {
      setSelectEpic(data.epic.epic_id);
    }
  }, [data?.epic?.epic_name]);

  const handleClear = () => {
    setSelectEpic(0);
    handleEpicBlur(0);
  };

  const handleClose = () => {
    setShowEpic(false);
  };

  // const handleBlur = (event)=>{

  //   // setShowEpic(false)
  //   setSelectEpic(event.target.value);
  //   handleEpicBlur(event.target.value)
  // }

  const handleChange = (event) => {
    setSelectEpic(event.target.value);
    handleEpicBlur(event.target.value);
  };

  return (
    <FormControl style={{ width: "100%" }} size="small">
      {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        // defaultValue={data?.epic?.epic_name}
        value={selectEpic}
        open={showEpic}
        // label="selectEpic"
        onChange={handleChange}
        onBlur={(event) => handleEpicBlur(event.target.value)}
        // onBlur={handleBlur}
        onClose={handleClose}
        // MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
        sx={{
          width: "100%", // Set width to 200px
          height: "32px", // Set height to 40px
          marginRight: "0px", // Set margin-right to 10px
        }}
        // autoFocus
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "160px", // Set max height for the dropdown list
            },
          },
        }}

        // endAdornment={
        //   selectEpic ? (
        //     <IconButton
        //       size="small"
        //       onClick={handleClear}
        //       onMouseDown={(event) => {

        //         event.preventDefault(); // Prevents the menu from closing
        //         event.stopPropagation(); // Prevents the onClick on the menu item from firing
        //       }}
        //       sx={{
        //         marginRight:'15px'
        //       }}
        //     >
        //       <CloseRounded />
        //     </IconButton>
        //   ) : null
        // }
      >
        {epicsName ? (
          <MenuItem value="0">
            <em>None</em>
          </MenuItem>
        ) : (
          <div className="loader-center" style={{ height: "30px" }}>
            <Loader color={"black"} />
          </div>
        )}
        {epicsName
          ?.sort((a, b) => a.is_locked - b.is_locked) // Sort: true (completed) first
          .map((item) => (
            <MenuItem
              value={item.epic_id}
              key={item.epic_id}
              disabled={item.is_locked}
            >
              {item.epic_name}
              {item.is_locked && <img src={lockIcon} alt="" className="ml-1" />}
            </MenuItem>
          ))}

        {/* <MenuItem
            sx={{
              height: "30px",
              width: "100%"
            }}
          >
            <div className="loader-center">
            <Loader color={"black"} />
            </div>
          </MenuItem>
        )
        } */}
      </Select>
    </FormControl>
  );
}
