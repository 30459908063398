import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expanded: ["panel1"],
};

const boardList = createSlice({
  name: "boardList",
  initialState,
  reducers: {
    changeExpand: {
      prepare(expanded, isExpanded) {
        return {
          payload: { expanded, isExpanded },
        };
      },
      reducer(state, action) {
        const { expanded, isExpanded } = action.payload;
        if (expanded === "allPanel") {
          state.expanded = ["panel1", "panel2", "panel3"];
        }
        if (isExpanded) {
          if (!state.expanded.includes(expanded)) {
            state.expanded.push(expanded);
          }
        } else {
          state.expanded = state.expanded.filter((panel) => panel !== expanded);
        }
      },
    },
  },
});

export const { changeExpand } = boardList.actions;

export default boardList.reducer;
