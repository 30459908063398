import axios from "axios";
import { getAPIBaseUrl } from "../utils/constants";

export const login = (data, loginWithEmail) => {
  const apiUrl = getAPIBaseUrl();
  return axios.post(
    loginWithEmail ? `${apiUrl}/login` : `${apiUrl}/ldap_auth`,
    data
  );
};

export const sendForgotPasswordlink = async (data) => {
  const apiUrl = getAPIBaseUrl();
  return await axios.post(`${apiUrl}/forget_password/send_link`, data);
};

export const checkLinkValid = async (data) => {
  const apiUrl = getAPIBaseUrl();
  return await axios.get(`${apiUrl}/check-valid-link?uuid=${data}`);
};

export const forgotPassword = async (data) => {
  const apiUrl = getAPIBaseUrl();
  return await axios.post(
    `${apiUrl}/forget_password?new_password=${data.password}&uuid=${data.uuid}`
  );
};

export const changePassword = async (data, token) => {
  const apiUrl = getAPIBaseUrl();
  return await axios.put(`${apiUrl}/change_password`, data, {
    headers: { token, "Content-Type": "application/x-www-form-urlencoded" },
  });
};
