import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  workflowId: null,
  workFlowStepIds: [],
  milestoneIds: [],
  assigneeIds: [],
  userIds: [],
};

const listViewColumnHeaderSclice = createSlice({
  name: "listViewHeaderFilters",
  initialState,
  reducers: {
    setWorkflowIds: {
      reducer(state, action) {
        state.workFlowStepIds = action.payload.stepIds;
        state.workflowId = action.payload.workflowId;
      },
    },
    setMilestoneIds: {
      reducer(state, action) {
        state.milestoneIds = action.payload;
        // state.workflowId = action.payload.workflowId;
      },
    },
    setAssigneeIds: {
      reducer(state, action) {
        state.assigneeIds = action.payload;
      },
    },
  },
});
export const { setWorkflowIds, setMilestoneIds, setAssigneeIds } =
  listViewColumnHeaderSclice.actions;
export default listViewColumnHeaderSclice.reducer;
