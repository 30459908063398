import React, { useEffect } from "react";
import { useWorkflowById } from "../../../../utils/reactQuery/workflows";
import GroupedTeam from "./GroupedTeam";
import { useTeams } from "../../../../utils/reactQuery/teams";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";

function Team({ groupingUser }) {
  const [workflow_id] = useCustomSearchParams("workflow_id");
  const { data , refetch} = useWorkflowById(workflow_id || 1, 528);
  const { teams: teamQuery } = useTeams();
  const allTeams = teamQuery?.data.data.responseData;

  useEffect(() => {
    refetch();
  }, [workflow_id]);

  const divideUserInTeams = Object.groupBy(groupingUser,(user)=> user.team_id);
  let teams = [];

  Object.keys(divideUserInTeams).forEach((key) => {
    teams.push({ team: allTeams?.find(team=> team.team_id === key * 1)?.team, users: divideUserInTeams[key] });
  });

  return (
    <>
      {teams.map((team, idx) =>
        team?.users.length ? (
          <GroupedTeam key={`grouped-team-${idx}`} team={team} data={data} />
        ) : (
          ""
        )
      )}
      {teams.length == 0 && (
        <div className="loader-center">
          <p>Select User</p>
        </div>
      )}
    </>
  );
}

export default Team;
