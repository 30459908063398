import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams, useSearchParams } from "react-router-dom";

import searchIcone from "../../../../../../../styles/svg/search-icon.svg";
import { addSubtask } from "../../../../../../../sevices/apiCards";
import ConfirmationDialog from "../../../../../../../components/Dialog/ConfirmationDialog";
import DropdownWithSearchbar from "../../../../../../../components/DropdownWithSearchbar";
import { useCards } from "../../../../../../../utils/reactQuery/cards";

const AssignSubtask = ({ ref, cardDetail }) => {
  const [childId, setChildId] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [conformationPopup, setConformationPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const open = Boolean(anchorEl);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const board_id = searchParams.get("brd_id");
  const card_id = Number(searchParams.get("card_id"));
  const { id } = useParams();
  const { cards } = useCards(cardDetail.board_id);
  const cardsOnBoard = cards?.data?.data?.responseData;
  const filteredCards = cardsOnBoard?.filter((item) => {
    return (
      item?.card?.card_id !== card_id &&
      item?.card?.card_id !== cardDetail?.parent_card_id &&
      !item?.card?.parent_card_id &&
      !item?.sub_tasks?.some(
        (subtaskItem) => subtaskItem === item?.card?.card_id
      ) &&
      (item?.key + "-" + item?.card?.card_id)
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  });

  const { mutate: updateCardmutate } = useMutation({
    mutationFn: addSubtask,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`cards`, id ?? board_id],
      });
      queryClient.invalidateQueries({
        queryKey: [`subTasks`],
      });
      queryClient.invalidateQueries({
        queryKey: [`mineCards`],
      });
      toast.success(res.data.message);
      setConformationPopup(false);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      setConformationPopup(false);
    },
  });

  const handleConfirmationPopup = (cardId) => {
    setChildId(cardId);
    setConformationPopup(true);
  };
  const handleDependsClose = (event) => {
    updateCardmutate({
      parentId: Number(card_id),
      childId: childId,
    });
  };

  return (
    <>
      {conformationPopup && (
        <ConfirmationDialog
          open={conformationPopup}
          handleClose={() => setConformationPopup(false)}
          message="Are you sure you want to subtask."
          callback={handleDependsClose}
          // loading={loading}
          btnTitle="Assign"
          title="Assign subtask"
        />
      )}
      <Button
        className="assign-subtask-box"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <img
          src={searchIcone}
          width={14}
          alt=""
          style={{ cursor: "pointer" }}
        />
        <Typography className="assign-subtask">Assign subtask</Typography>
      </Button>
      <DropdownWithSearchbar
        anchorEl={anchorEl}
        open={open}
        setAnchorEl={setAnchorEl}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filteredCards={filteredCards}
        handleConfirmationPopup={handleConfirmationPopup}
        renderItemLabel={(item) => `${item.key}-${item.card.card_id}`}
        ref={ref}
        showNone={false}
      />
    </>
  );
};

export default AssignSubtask;
