import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

const wrapText = (text, maxChars) => {
  const words = text.split(" ");
  const lines = [];
  let currentLine = "";

  words.forEach((word) => {
    if ((currentLine + word).length <= maxChars) {
      currentLine += `${word} `;
    } else {
      lines.push(currentLine.trim());
      currentLine = `${word} `;
    }
  });

  if (currentLine) lines.push(currentLine.trim());
  return lines;
};


export default function PieChartComp({ data, totalHours }) {
  const COLORS = [
    "#8979FF",
    "#6FD195",
    "#537FF1",
    "#FFAE4C",
    "#3CC3DF",
    "#FF928A",
  ];
  const labelPositions = [];

  const capitalize = (str) =>
    str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
      
  const renderCustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;

    // Base coordinates for the slice
    const sliceX = cx + outerRadius * Math.cos(-midAngle * RADIAN);
    const sliceY = cy + outerRadius * Math.sin(-midAngle * RADIAN);

    // Intermediate point for the bend
    const bendX = cx + (outerRadius + 70) * Math.cos(-midAngle * RADIAN);
    const bendY = cy + (outerRadius + 70) * Math.sin(-midAngle * RADIAN);

    // Final position for the label
    const labelX = bendX > cx ? bendX + 70 : bendX - 70; // Adjust horizontal distance
    let labelY = bendY;

    // Prevent overlap by dynamically adjusting labelY
    if (labelPositions.length > 0) {
      const lastY = labelPositions[labelPositions.length - 1];
      if (Math.abs(labelY - lastY) < 24) {
        labelY = lastY + 24; // Add vertical spacing
      }
    }
    labelPositions.push(labelY);

    // Prepare label text
    const labelText = capitalize(`${data[index].name} (${data[index].value})`);
    const wrappedText = wrapText(labelText, 24);

    return (
      <>
        {/* Line from slice to bend */}
        <line
          x1={sliceX}
          y1={sliceY}
          x2={bendX}
          y2={bendY}
          stroke={COLORS[index % COLORS.length]}
          strokeWidth={1}
        />
        {/* Line from bend to label */}
        <line
          x1={bendX}
          y1={bendY}
          x2={labelX}
          y2={labelY}
          stroke={COLORS[index % COLORS.length]}
          strokeWidth={1}
        />
        {/* Multi-line label */}
        {wrappedText.map((line, i) => (
          <text
            key={i}
            x={labelX}
            y={i === 1 ? labelY + 8 : labelY + i * 10}
            fill="black"
            textAnchor={labelX > cx ? "start" : "end"}
            dominantBaseline={i === 0 ? "central" : "hanging"}
            fontSize={8}>
            {line}
          </text>
        ))}
      </>
    );
  };

  return (
    <ResponsiveContainer width={"100%"} height={600}>
      <PieChart width={700} height={600}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          label={renderCustomLabel}
          outerRadius={180}
          innerRadius={80}
          fill="#8884d8"
          dataKey="value">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        {/* Center text */}
        <text
          x="50%"
          y="45%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={16}
          fontWeight="bold"
          fill="#333">
          TOTAL
        </text>
        <text
          x="50%"
          y="53%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={18}
          fontWeight="bold"
          fill="#333">
          {Number.isInteger(totalHours)
            ? totalHours
            : parseFloat(totalHours.toFixed(2))}
        </text>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}
