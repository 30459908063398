import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { updateCard } from "../../../../sevices/apiCards";
import OpenCardDetail from "../../../board/components/Kanban/openCardDetail";
import { useSearchParams } from "react-router-dom";

import { updateWorkflowStep } from "../../../../sevices/apiCards";
import KanbanContainor from "./KanbanContainor";

function KanbanBoard({ workflowSteps, cards, id, callback }) {
  const queryClient = useQueryClient();
  const [projects, setProjects] = useState(cards);
  const [draggedOverCol, setDraggedOverCol] = useState(0);
  const epicSearch = useSelector((state) => state.epic.searchEpic);
  const [searchParams, setSearchParams] = useSearchParams();


  // Redux state
  const searchText = useSelector((state) => state.search.searchText);
  const filterUser = searchParams.get("search");

  useEffect(() => {
    setProjects(cards);
  }, [cards]);

  useEffect(() => {
    const debounceDelay = 1000;
    const debouncedFilter = debounce(() => {
      if (searchText) {
        const filteredBySearch = cards?.filter(
          (cardData) =>
            (cardData?.card?.title
              ? `${cardData?.card?.title}`?.toLowerCase().includes(searchText)
              : "") ||
            (cardData?.card?.assigned_to_user?.first_name
              ? `${cardData?.card?.assigned_to_user?.first_name}`
                  ?.toLowerCase()
                  .includes(searchText)
              : "") ||
            (cardData?.card?.assigned_to_user?.last_name
              ? `${cardData?.card?.assigned_to_user?.last_name}`
                  ?.toLowerCase()
                  .includes(searchText)
              : "") ||
            cardData?.key?.toLowerCase().includes(searchText) ||
            cardData?.card?.card_id
              ?.toString()
              ?.toLowerCase()
              .includes(searchText) ||
            cardData?.labels?.some((ele) =>
              ele?.toLowerCase().includes(searchText)
            ) ||
            cardData?.card?.epic?.epic_name?.toLowerCase().includes(searchText)
        );
        setProjects(filteredBySearch);
      } else {
        setProjects(cards);
      }
    }, debounceDelay);

    if (filterUser) {
      const filteredCardsByUser = cards?.filter(
        (el) => el?.card?.assigned_to_user_id == filterUser
      );
      setProjects(filteredCardsByUser);
    } else if (epicSearch && epicSearch?.length > 0) {
      const filterEpics = cards?.filter((item) =>
        epicSearch?.includes(item?.card?.epic_id)
      );

      setProjects(filterEpics);
    } else {
      debouncedFilter();
    }

    return () => {
      debouncedFilter.cancel();
    };
  }, [searchText, filterUser, epicSearch]);

  const { mutate: updateMutate } = useMutation({
    mutationFn: updateWorkflowStep,
    onSuccess: (res) => {
      callback?.();
      toast.success(res?.data?.message);
    },
    onError: (error) => {
      console.error("An error occurred while updating task", error);
      toast.error(error?.response?.data?.message);
      queryClient.invalidateQueries({
        queryKey: [`mineCards`],
      });
    },
  });

  //this is called when a Kanban card is dragged over a column (called by column)
  const handleOnDragEnter = (e, stageValue) => {
    setDraggedOverCol(stageValue);
  };

  //this is called when a Kanban card dropped over a column (called by card)
  const handleOnDragEnd = (e, project) => {
    const updatedProjects = projects?.slice(0);

    const updatedProjectIndex = updatedProjects.findIndex(
      (projectObject) => projectObject?.card?.card_id === project?.card_id
    );

    if (updatedProjectIndex !== -1) {
      updateMutate({
        card_id: project?.card_id,
        workflow_step_id: draggedOverCol,
      });
      updatedProjects[updatedProjectIndex].card.workflow_step_id =
        draggedOverCol;
      setProjects(updatedProjects);
    }
  };

  return (
    <>
      <KanbanColumnMemo
        projects={projects}
        workflowSteps={workflowSteps}
        onDragEnter={handleOnDragEnter}
        onDragEnd={handleOnDragEnd}
        callback={callback}
      />
    </>
  );
}

// Memoized KanbanColumn component
const KanbanColumnMemo = React.memo(KanbanContainor);

export default KanbanBoard;
