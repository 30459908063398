import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Stack } from "@mui/material";

import Item from "./Item";
import Avatar from "../../../../components/Avatar/index";
import {
  createAvtarName,
  fixedDigitsAfterDecimal,
  hasImage,
} from "../../../../utils/helpers";
import ExpandBoardIcon from "../../../../styles/svg/expand_board.svg";
import ShrinkBoard from "../../../../styles/svg/shrinkBoard.svg";
import PieChartComp from "./PieChart";
import { useResponsive } from "../../../../hooks/useResponsive";

function ByBoardTab({ worklogs }) {
  const activeBoard = useSelector((state) => state.activeBoard.activeBoardList);
  const checkedList = useSelector((state) => state.search.boardSearch);
  const [expandedPanel, setExpandedPanel] = useState([]);
  const totalHours = useSelector((state) => state.search.totalWorkHours);
  const [filterWorklogs, setFilteredWorklogs] = useState([worklogs]);
  const searchText = useSelector((state) => state.search.searchText);
  const isMobile = useResponsive("down", "md");

  const handleExpandAccordianChange = (boardIndex) => (event, isExpanded) => {
    const boardData = expandedPanel.map((element, index) => {
      if (element?.board_id === boardIndex) {
        return { ...element, isExpand: isExpanded };
      } else {
        return { ...element };
      }
    });
    setExpandedPanel(boardData);
  };

  const selectedBoard = checkedList.map((boardId) =>
    activeBoard.find((board) => board.board_id === boardId)
  );

  const handleExpandAll = (event) => {
    event.stopPropagation();
    if (event.target.checked) {
      const expandData = activeBoard.map((element, index) => {
        return { board_id: element.board_id, isExpand: true };
      });
      setExpandedPanel(expandData);
    } else {
      const expandData = activeBoard.map((element, index) => {
        return { board_id: element.board_id, isExpand: false };
      });
      setExpandedPanel(expandData);
    }
  };

  useEffect(() => {
    if (activeBoard) {
      const expandData = activeBoard.map((element, index) => {
        return { board_id: element.board_id, isExpand: false };
      });
      setExpandedPanel(expandData);
    }
  }, []);

  useEffect(() => {
    let worklogInstance = worklogs.filter((cardData) => {
      return (
        cardData?.key?.toLowerCase().includes(searchText) ||
        cardData?.cardlog?.card?.card_id.toString().includes(searchText) ||
        cardData?.cardlog?.user?.first_name
          ?.toLowerCase()
          .includes(searchText) ||
        cardData?.cardlog?.user?.last_name
          ?.toLowerCase()
          .includes(searchText) ||
        cardData?.cardlog?.card?.title.toString().includes(searchText) ||
        cardData?.cardlog?.work_performed?.toString().includes(searchText)
      );
    });
    setFilteredWorklogs(worklogInstance);
  }, [searchText]);

  return (
    <div>
      {selectedBoard.length > 0 && !isMobile && (
        <div className="d-flex justify-content-center">
          <PieChartComp
            data={selectedBoard
              .map((boardData) => {
                return {
                  name: boardData.board_name,
                  value: fixedDigitsAfterDecimal(
                    worklogs
                      .filter(
                        (worklog) =>
                          worklog?.cardlog?.card?.board_id ===
                          boardData.board_id
                      )
                      .reduce((sum, item) => sum + item?.cardlog.hours_spent, 0)
                  ),
                };
              })
              .filter((ele) => ele.value !== 0)}
            totalHours={totalHours}
          />
        </div>
      )}
      <div className="worklog_board_cont">
        <div className="worklog_board_header">
          <p>Board Name</p>
          <Stack direction={"row"} alignItems={"center"}>
            <p>Hours</p>
            <div className="w-25 text-end">
              <label
                class="custom-checkbox"
                // onClick={() => setExpandAll((data) => !data)}
              >
                <input
                  type="checkbox"
                  id={`expand-all-checkbox`}
                  onClick={handleExpandAll}
                  checked={expandedPanel.every((element) => element.isExpand)}
                />
                <span class="checkmark"></span>
                <p className="team-status-title">Expand-All</p>
              </label>
            </div>
          </Stack>
        </div>
        {selectedBoard.length > 0 ? (
          selectedBoard
            .filter((boardDetails, index) => {
              return (
                worklogs
                  .filter(
                    (worklog) =>
                      worklog?.cardlog?.card?.board_id === boardDetails.board_id
                  )
                  .reduce((sum, item) => sum + item?.cardlog.hours_spent, 0) !==
                0
              );
            })
            .map((boardData, idx) => {
              return (
                <Accordion
                  key={idx}
                  expanded={
                    expandedPanel?.find(
                      (board) => board?.board_id === boardData.board_id
                    )?.isExpand || false
                  }
                  onChange={handleExpandAccordianChange(boardData.board_id)}>
                  <AccordionSummary
                    sx={{ marginRight: "30px" }}
                    expandIcon={
                      expandedPanel?.find(
                        (board) => board?.board_id === boardData.board_id
                      )?.isExpand ? (
                        <img src={ShrinkBoard} alt="shrink board" />
                      ) : (
                        <img src={ExpandBoardIcon} alt="expand board" />
                      )
                    }
                    aria-controls="panel1-content"
                    id="panel1-header">
                    <div className="worklog_board_accordian_header">
                      <div className="d-flex align-items-center">
                        <Avatar
                          key={`board-avtar-${boardData.board_id}`}
                          user_image={boardData.board_owner_image}
                          className="sidebar-boardData-owner user-avatar"
                          title={boardData.board_owner_name}
                          style={
                            !hasImage(boardData.board_owner_image) && {
                              bgcolor:
                                boardData?.board_owner_profile_color
                                  ?.background,
                              color: boardData?.board_owner_profile_color?.text,
                            }
                          }>
                          {`${createAvtarName(
                            boardData.board_owner_name
                          )}`.toUpperCase()}
                        </Avatar>

                        <p className="worklog_board_name ml-2">
                          {boardData.board_name}
                        </p>
                      </div>
                      <p className="total_project_hours">
                        {fixedDigitsAfterDecimal(
                          worklogs
                            .filter(
                              (worklog) =>
                                worklog?.cardlog?.card?.board_id ===
                                boardData.board_id
                            )
                            .reduce(
                              (sum, item) => sum + item?.cardlog.hours_spent,
                              0
                            )
                        )}
                      </p>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="pl-38">
                    <Accordion sx={{ boxShadow: "none" }}>
                      {/* {teams?.data?.data?.responseData?.map((team) => (
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header">
                        {team.team}
                      </AccordionSummary> */}
                      <AccordionDetails>
                        {filterWorklogs.filter(
                          (worklog) =>
                            worklog?.cardlog?.card?.board_id ===
                            boardData.board_id
                        ).length > 0 ? (
                          filterWorklogs
                            .filter(
                              (worklog) =>
                                worklog?.cardlog?.card?.board_id ===
                                boardData.board_id
                            )
                            .map((worklog, idx) => (
                              <Item worklog={worklog} key={idx} />
                            ))
                        ) : (
                          <p className="align_text_center">No worklog found</p>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                </Accordion>
              );
            })
        ) : (
          <p className="loader-center">No Board Selected</p>
        )}
      </div>
    </div>
  );
}

export default ByBoardTab;
