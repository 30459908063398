import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { List, ListItem, ListItemText, Menu, Stack } from "@mui/material";

import Avatar from "../../../../../../components/Avatar";
import "./addComment.scss";
import { addComment } from "../../../../../../sevices/apiComment";
import { useResponsive } from "../../../../../../hooks/useResponsive";
import MentionUserTextEditor from "./mentionUser";
import { useBoardUsers } from "../../../../../../utils/reactQuery/users";
import { hasImage, removeHtmlTagsAndEntities } from "../../../../../../utils/helpers";

export default function BasicTextFields({ cardId }) {
  const [comment, setComment] = useState("");
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { isLoading, error, data, isError } = useBoardUsers(
    searchParams.get("brd_id") || id
  );
  const [showEditor, setShowEditor] = useState(false);
  const queryClient = useQueryClient();
  const user = useSelector((state) => state.users.logInUser);
  const isMobile = useResponsive("down", "md");
  const firstName = user.first_name[0];
  const lastName = user.last_name[0];
  const name = `${firstName || ""}${lastName || ""}`;
  const tooltipName = `${user.first_name || ""} ${user.last_name || ""}`;
  const [mentionListVisible, setMentionListVisible] = useState(false);
  const [elementPos, setElementPos] = useState();
  const [cursorPosition, setCursorPosition] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const editorRef = useRef(null);
  const [selectedId, setSelectedId] = useState([]);
  const [sortUser, setSortUser] = useState("");
  const [isDisable, setIsDisable] = useState(true)
  const { mutate: addCommentmutate } = useMutation({
    mutationFn: addComment,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`history`, String(cardId)],
      });
      queryClient.invalidateQueries({
        queryKey: [`comment`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`feeds`, id],
      });
      queryClient.invalidateQueries({
        queryKey: [`commentBoard`, id],
      });
      // setApiCall(true)
      // setLoading(false);
      //    reset();
      setComment("");
      setIsDisable(true)
      toast.success(res?.data?.message);
      setShowEditor(false);
    },
    onError: (error) => {
      console.error("Error assig board", error);
      toast.error(error?.response?.data?.message);
    },
  });

  const handleCancel = () => {
    setShowEditor(false);
  };
  const handleSave = (e) => {
    // const trimmedComment = comment.trim();
    if (comment && !isDisable) {
      var helper = [];
      selectedId.map((ele) => {
        for (let i = 0; data?.data?.data?.responseData?.length > i; i++) {
          if (data?.data?.data?.responseData[i]?.user_id === ele) {
            if (
              comment
                .toLowerCase()
                .includes(data?.data?.data?.responseData[i]?.username?.toLowerCase())
            ) {
              helper.push(ele);
            }
          }
        }
      });
      addCommentmutate({
        card_id: cardId,
        comment: comment,
        tagged_user: [...new Set(helper)].join(",") || null,
      });
      setShowEditor(false);
    }
  };

  const anchorHelper = document.getElementById("forAnchor");

  React.useEffect(() => {
    setElementPos(anchorHelper?.getBoundingClientRect());
  }, [anchorHelper]);

  const handleMention = (cursorPos, editor) => {
    const anchorEl = document.getElementById("forAnchor");
    setElementPos((ele) => ({
      top: ele.top + anchorEl.getBoundingClientRect().top,
      left: ele.left + anchorEl.getBoundingClientRect().left,
    }));
    setCursorPosition(cursorPos - 1);
    setAnchorEl(anchorEl);
    setMentionListVisible(true);
  };

  const handleUserSelect = (user) => {
    const quillEditor = editorRef.current.getEditor(); // Access the Quill editor
    setSelectedId([...selectedId, user.user_id]);

    quillEditor.deleteText(cursorPosition, 1); // Remove '@'
    quillEditor.insertText(cursorPosition, `@${user.username} `); // Insert user's name
    quillEditor.setSelection(cursorPosition + user.username.length + 1); // Move cursor

    setMentionListVisible(false); // Hide mention list
  };

  const handleComment = (e)=>{
    const commentData = removeHtmlTagsAndEntities(editorRef?.current?.value).trim()
    if(commentData){
      setComment(e)
      setIsDisable(false)
    }else{
      setIsDisable(true)
      setComment("")
    }
  }
  return (
    <>
      <div className="d-flex  add-comment">
        <div>
          <Avatar
            title={tooltipName}
            className={" header-avatar add-comment-avtar"}
            user_image={user.user_image}
            style={
              !user.user_image && {
                bgcolor: user?.profile_color?.background,
                color: user?.profile_color?.text,
              }
            }
          >
            {name}
          </Avatar>
        </div>
        {showEditor && (
          <div
            className={
              isMobile ? "mobile-comment-textEditor" : "comment-textEditor"
            }
            id={"forAnchor"}
            style={{ position: "relative" }}
          >
            <MentionUserTextEditor
              value={comment}
              onChange={handleComment}
              onMention={handleMention}
              setEelementPros={setElementPos}
              minHeight={"140px"}
              ref={editorRef}
            />
            <Menu
              open={mentionListVisible}
              anchorEl={anchorEl}
              sx={{
                "& .MuiPaper-root": {
                  top: `${elementPos?.top}px !important`,
                  left: `${elementPos?.left}px !important`,
                },
              }}
              onClose={() => {
                setSortUser("");
                setMentionListVisible(false);
              }}
            >
              <Stack alignItems={"center"} mx={1}>
                <TextField
                  placeholder="search..."
                  fullWidth
                  value={sortUser}
                  autoFocus
                  onChange={(e) => setSortUser(e.target.value)}
                  sx={{
                    // height: "50px", // Adjust the height
                    "& .MuiInputBase-input": {
                      padding: "8px 10px", // Adjust the padding of the input
                    },
                  }}
                />
              </Stack>
              <List
                sx={{
                  maxHeight: "200px",
                  overflow: "auto",
                  paddingTop: "0px !important",
                }}
              >
                <List>
                  {data?.data?.data?.responseData
                    .filter((ele) =>
                      ele?.username?.toLowerCase()?.includes(sortUser)
                    )
                    .map((user) => (
                      <ListItem
                        button
                        key={user.id}
                        onClick={() => handleUserSelect(user)}
                      >
                        <Avatar
                          title={`${user.username}`}
                          user_image={user?.user_image}
                          className="user-avatar"
                          style={
                            !hasImage(user?.user_image) && {
                              bgcolor: user?.profile_color?.background,
                              color: user?.profile_color?.text,
                            }
                          }
                        >
                          {user?.username}
                        </Avatar>

                        <ListItemText
                          sx={{ marginLeft: "8px" }}
                          primary={user?.username}
                        />
                      </ListItem>
                    ))}
                </List>
              </List>
            </Menu>
            <div className="text-editor-btn-div">
              <Button
                onClick={handleSave}
                className="theme-bg-color text-editor-btn1"
                variant="contained"
                disabled={isDisable}
              >
                <div className="text-editor-btn-content">Save</div>
              </Button>
              <Button
                onClick={handleCancel}
                className="text-editor-btn2"
                variant="outlined"
              >
                <div className="text-editor-btn-content">Cancel</div>
              </Button>
            </div>
          </div>
        )}
        {!showEditor && (
          <div
            className="add-comment-field"
            onClick={() => {
              setShowEditor(true);
            }}
          >
            <p>Add a comment...</p>
          </div>
        )}
      </div>
    </>
  );
}
