import * as React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import moment from "moment";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import AddWorklogCustomizedDialogs from "../../worklog/addWorklog/addWorklogDialog";
import CustomizedProgressBars from "./progressbar";
import Avatar from "../../../../../components/Avatar";
import { useCard } from "../../../../../utils/reactQuery/cards";
import { currentUserId, isAdmin } from "../../../../../utils/constants";
import "./sidebar.scss";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import toast from "react-hot-toast";
import {
  AddToWatchList,
  assignCard,
  RemoveToWatchList,
  updateCard,
} from "../../../../../sevices/apiCards";
import ComboBox from "./components/multiselectSearch";
import CustomDateFormatter from "../../dateTimeFormateConverter/dateTimeFormateConverter";
import { differenceInDays, hasImage } from "../../../../../utils/helpers";
import DatePickerSmall from "./components/datePicker";
import AssignCard from "../../Kanban/AssignCard";
import CardLastSeenDetail from "./cardLastSeenDetail/CardLastSeenDetail";
import { useSelector } from "react-redux";
import Epic from "./components/epic/epic";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import { useResponsive } from "../../../../../hooks/useResponsive";
import SubTask from "./components/subTask/SubTask";
import criticalIcon from "../../../../../styles/svg/critical_priority.svg";
import highIcon from "../../../../../styles/svg/high_priority.svg";
import mediumIcon from "../../../../../styles/svg/medium_priority.svg";
import lowIcon from "../../../../../styles/svg/low_priority.svg";

export default function SidebarGrid({ cardId }) {
  const [addWorklog, setAddWorklog] = useState(false);
  const [isAllocatedHours, setAllocatedHours] = useState(false);
  const [inputAllocatedHours, setInputAllocatedHours] = useState(0);
  const [label, setLabel] = useState([]);
  const [showLebel, setShowLebel] = useState(false);
  const [showEpic, setShowEpic] = useState(false);
  const [depends, setDepends] = useState(false);

  const [date, setDate] = useState(null);
  const [data, setData] = useState(null);
  const [dueDate, setDueDate] = useState(false);
  const queryClient = useQueryClient();
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const isMobile = useResponsive("down", "md");
  const {
    isLoading: isCardLoading,
    card,
    isError: isCardError,
  } = useCard(cardId);

  useEffect(() => {
    setLabel(
      data?.labels.map((item) => ({
        label: item.label,
        label_id: item.label_id,
      }))
    );
    setDate(data?.due_date);
    setData(card?.data?.data?.responseData);
    setInputAllocatedHours(data?.allocated_hours || 0);
    return () => { };
  }, [card, data]);

  const { board: { board_id: id, owner_user_id: boardOwnerId, key: boardKey } } = card?.data?.data?.responseData;
  const user = useSelector((state) => state.users.logInUser);

  const firstName = user.first_name[0];
  const lastName = user.last_name[0];
  const name = `${firstName || ""}${lastName || ""}`;
  const tooltipName = `${user.first_name || ""} ${user.last_name || ""}`;
  const assigneeFirstName = data?.assigned_to_user?.first_name[0];
  const assigneeLastImage = data?.assigned_to_user?.last_name[0];
  const assigneeName = `${assigneeFirstName || ""}${assigneeLastImage || ""}`;
  const assigneetooltipName = `${data?.assigned_to_user?.first_name || ""} ${
    data?.assigned_to_user?.last_name || ""
  }`;
  const reportertooltipName = `${data?.reported_by?.first_name || ""} ${
    data?.reported_by?.last_name || ""
  }`;
  const reporterHeadShotName = `${data?.reported_by?.first_name[0] || ""}${
    data?.reported_by?.last_name[0] || ""
  }`;

  const conditionCheck =
    isAdmin() ||
    boardOwnerId === currentUserId() ||
    card?.data?.data?.responseData?.assigned_to_user_id === currentUserId() ||
    card?.data?.data?.responseData?.reporter === currentUserId();

  const { mutate: updateCardmutate } = useMutation({
    mutationFn: updateCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`lebels`, data?.board_id],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, String(id)],
      });
      queryClient.invalidateQueries({
        queryKey: ["milestone_history", milestone],
      });
      queryClient.invalidateQueries({
        queryKey: ["epic_id", milestone],
      });
      queryClient.invalidateQueries({
        queryKey: [`mineCards`],
      });
      queryClient.invalidateQueries({
        queryKey: [`watching`],
      });
      queryClient.invalidateQueries({
        queryKey: [`history`, String(cardId)],
      });
      toast.success(res.data.message);
      setDueDate(false);
      setShowEpic(false);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
    },
  });

  const { mutate: assignCardMutate } = useMutation({
    mutationFn: assignCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`boards`],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, String(id)],
      });
      queryClient.invalidateQueries({
        queryKey: [`userCards`],
      });
      queryClient.invalidateQueries({
        queryKey: [`mineCards`],
      });
      queryClient.invalidateQueries({
        queryKey: [`history`, String(cardId)],
      });

      toast.success(res.data.message);
      setDueDate(false);
      setShowEpic(false);
    },
    onError: (error) => {
      console.error("Error assig board", error);
      toast.error(error.response.data.message);
    },
  });

  const handleInputBlur = () => {
    if (data?.allocated_hours != parseInt(inputAllocatedHours)) {
      if (
        inputAllocatedHours != undefined &&
        /^[0-9]*\.?[0-9]*$/.test(parseInt(inputAllocatedHours))
      ) {
        updateCardmutate({
          card_id: cardId,
          data: {
            allocated_hours: inputAllocatedHours,
          },
        });
      }
    } else {
    }
    setAllocatedHours(false);
  };
  const handleDueDate = (e) => {
    if (
      isAdmin() ||
      boardOwnerId == currentUserId() ||
      data?.reporter == currentUserId()
    ) {
      setDueDate(true);
    }
  };
  const handleDuedateBlur = (event) => {
    setDueDate(event);
  };

  const handleShowEpic = () => {
    if (
      isAdmin() ||
      boardOwnerId == currentUserId() ||
      data?.assigned_to_user_id == currentUserId() ||
      data?.reporter == currentUserId()
    ) {
      setShowEpic(true);
    }
  };

  const handleShowLebel = () => {
    if (
      isAdmin() ||
      boardOwnerId == currentUserId() ||
      data?.assigned_to_user_id == currentUserId() ||
      data?.reporter == currentUserId()
    ) {
      setShowLebel(true);
    }
  };

  const handleEpicBlur = (epic_id) => {
    setShowEpic(false);
    if (data?.epic?.epic_id != epic_id) {
      updateCardmutate({
        card_id: cardId,
        data: {
          epic_id: epic_id,
        },
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (data?.allocated_hours != inputAllocatedHours) {
        if (
          inputAllocatedHours !== undefined &&
          inputAllocatedHours !== null &&
          inputAllocatedHours !== ""
        ) {
          e.preventDefault();
          updateCardmutate({
            card_id: cardId,
            data: {
              allocated_hours: inputAllocatedHours,
            },
          });
        }
      }

      setAllocatedHours(false);
    }
  };

  const handleAllocatedHoursInput = () => {
    if (
      isAdmin() ||
      data?.reporter == currentUserId() ||
      boardOwnerId == currentUserId()
    ) {
      setAllocatedHours(true);
      setInputAllocatedHours(data?.allocated_hours);
    }
  };
  const handleAssignClick = () => {
    assignCardMutate({
      card_id: cardId,
      data: {
        assigned_to_user_id: currentUserId(),
      },
    });
  };
  const handleInputHourChange = (e) => {
    let newValue = e.target.value;
    if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
      setInputAllocatedHours(newValue);
    } else {
      setInputAllocatedHours("");
    }
  };
  const handleDueDateKeyDown = (newDate) => {
    updateCardmutate({
      card_id: cardId,
      data: {
        due_date: newDate ? moment(newDate).format("YYYY-MM-DD") : 0,
      },
    });

    setDueDate(false);
  };

  const handleAddWatchList = () => {
    AddToWatchList(cardId)
      .then((res) => {
        toast.success(res.data.message);
        queryClient.invalidateQueries({
          queryKey: [`card`, cardId],
        });
        queryClient.invalidateQueries({
          queryKey: [`watching`],
        });
      })
      .catch((err) => {
        console.log("err in watchlist", err);
        toast.error(err.response.data.message);
      });
  };

  const handleRemoveWatchList = () => {
    RemoveToWatchList(cardId)
      .then((res) => {
        toast.success(res.data.message);
        queryClient.invalidateQueries({
          queryKey: [`card`, cardId],
        });
        queryClient.invalidateQueries({
          queryKey: [`watching`],
        });
      })
      .catch((err) => {
        console.log("err in watchlist", err);
        toast.error(err.response.data.message);
      });
  };

  const CustomRadio = styled(Radio)(({ theme }) => ({
    height: "18px", // Set the height
    width: "18px", // Set the width
    marginLeft: "6px",
    // "&.Mui-checked": {
    //   color: "#4caf50", // Active color
    // },
    // "&.MuiRadio-root": {
    //   color: "#ccc", // Inactive color
    // },
    "& svg": {
      height: "16px", // Ensure the SVG inside the radio scales
      width: "16px", // Ensure the SVG inside the radio scales
    },
  }));

  const handlePriority = (value) => {
    updateCardmutate({
      card_id: cardId,
      data: {
        priority: value.target.value,
      },
    });
  };

  return (
    <>
      {addWorklog && (
        <AddWorklogCustomizedDialogs
          cardId={cardId}
          addWorklog={addWorklog}
          setAddWorklog={setAddWorklog}
          heading={"Add Work Log"}
          buttonName={"Save"}
        />
      )}
      <Box>
        <Grid container className="sidebar-block">
          <Grid
            item
            className={isMobile ? "mobile-sidebar-heading" : "sidebar-heading"}
          >
            <div className="sidebar-heading-content">
              <CardLastSeenDetail cardDetail={data} />
            </div>
          </Grid>
          <Grid item className="sidebar-details">
            <Grid item className="sidebar-details-content">
              <Grid item className="sidebar-worklog">
                <div className="sidebar-worklog-detail">Details</div>
                <div className="sidebar-Addworklog-btn">
                  {data?.in_watchlist ? (
                    <Button onClick={handleRemoveWatchList}>
                      Remove from Watchlist
                    </Button>
                  ) : (
                    <Button onClick={handleAddWatchList}>
                      Add to Watchlist
                    </Button>
                  )}
                </div>
              </Grid>

              <div className="box">
                <Grid item className="priority_cont">
                  <p className="mr-3">Priority </p>
                  <FormControl className="d-flex">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      value={data?.priority || ""}
                      onChange={handlePriority}
                      name="row-radio-buttons-group"
                    >
                      {["Critical", "High", "Medium", "Low"].map(
                        (value, idx) => (
                          <FormControlLabel
                            key={value}
                            value={value}
                            sx={{ width: "110px" }}
                            control={
                              <CustomRadio
                                disabled={
                                  !(
                                    isAdmin() ||
                                    data?.reporter == currentUserId() ||
                                    boardOwnerId == currentUserId()
                                  )
                                }
                              />
                            }
                            label={
                              <Stack direction="row" alignItems={"center"}>
                                <img
                                  src={
                                    idx === 0
                                      ? criticalIcon
                                      : idx === 1
                                      ? highIcon
                                      : idx === 2
                                      ? mediumIcon
                                      : lowIcon
                                  }
                                  alt="icon"
                                  className="priority_icon"
                                  style={{
                                    marginTop: "3px",
                                    marginRight: "4px",
                                  }}
                                />
                                <span style={{ fontSize: "14px" }}>
                                  {value}
                                </span>
                              </Stack>
                            }
                          />
                        )
                      )}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </div>

              <div className="box">
                <Grid item className="allocated-hours ">
                  <p>Allocated Hours</p>
                  <div
                    className="taskdetail-sidebar-right-col"
                    onClick={handleAllocatedHoursInput}
                  >
                    {isAllocatedHours ? (
                      <TextField
                        id="outlined-basic-input"
                        // type="number"
                        value={inputAllocatedHours}
                        fullWidth
                        className="taskdetail-sidebar-right-col"
                        size={"small"}
                        variant="outlined"
                        onChange={handleInputHourChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handleInputBlur}
                        autoFocus
                      />
                    ) : (
                      <div className="allocated-hours-input">
                        {data?.allocated_hours ? (
                          <p>{data?.allocated_hours}</p>
                        ) : (
                          <p className="due-date-input-allocate-hour-none">
                            None
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </Grid>
              </div>
              <div className="box">
                <Grid item className="allocated-hours ">
                  <p>Time Tracking</p>
                  <div className="time-tracking-input taskdetail-sidebar-right-col">
                    <CustomizedProgressBars
                      allocatedHours={data?.allocated_hours}
                      hoursSpent={data?.total_hours_spent}
                    />
                  </div>
                </Grid>
              </div>
              <div className="box">
                <Grid item className="allocated-hours ">
                  <p>Hours Spent</p>
                  <div className="taskdetail-sidebar-right-col">
                    <div className="allocated-hours-input">
                      <p>
                        {data?.total_hours_spent ? (
                          data?.total_hours_spent
                        ) : (
                          <p className="due-date-input-none">None</p>
                        )}
                      </p>
                    </div>
                  </div>
                </Grid>
              </div>
              <div className="box">
                <Grid item className="allocated-hours ">
                  <p>Assignee</p>
                  <div className="assignee-detail taskdetail-sidebar-right-col">
                    <div className="assignee-image">
                      <AssignCard
                        tooltipName={
                          data?.assigned_to_user
                            ? assigneetooltipName
                            : tooltipName
                        }
                        project={data}
                        name={data?.assigned_to_user ? assigneeName : name}
                        accessForAssign={conditionCheck}
                        cardId={cardId}
                      />
                    </div>
                    <div className="assignee">
                      <div className="assignee-name">
                        {data?.assigned_to_user && (
                          <p>{`${data?.assigned_to_user?.first_name} ${data?.assigned_to_user?.last_name}`}</p>
                        )}
                      </div>
                      <div className="sidebar-assigntome-btn">
                        {data?.assigned_to_user ? null : (
                          <Button onClick={handleAssignClick} sx={{}}>
                            Assign to me
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              </div>
              <div className="box">
                <Grid item className="allocated-hours ">
                  {" "}
                  <p>Label</p>
                  <div className="taskdetail-sidebar-right-col">
                    {showLebel ? (
                      <ComboBox
                        labels={label}
                        boardId={data?.board_id}
                        cardId={cardId}
                        setShowLebel={setShowLebel}
                      />
                    ) : (
                      <div className="due-date" onClick={handleShowLebel}>
                        {" "}
                        <div className="due-date-input">
                          {" "}
                          {data?.labels.length !== 0 ? (
                            data?.labels.map((item) => item.label).join(", ")
                          ) : (
                            <p className="due-date-input-none">None</p>
                          )}{" "}
                        </div>{" "}
                      </div>
                    )}
                  </div>
                  {/* </div> */}
                </Grid>
              </div>

              <div className="box">
                <Grid item className="allocated-hours ">
                  {" "}
                  <p>Milestone</p>
                  <div className="taskdetail-sidebar-right-col">
                    {showEpic ? (
                      <Epic
                        handleEpicBlur={handleEpicBlur}
                        cardId={cardId}
                        showEpic={showEpic}
                        setShowEpic={setShowEpic}
                        boardId={data?.board_id}
                      />
                    ) : (
                      <div className="due-date" onClick={handleShowEpic}>
                        {" "}
                        <div className="due-date-input">
                          {" "}
                          {data?.epic?.epic_name ? (
                            data?.epic?.epic_name
                          ) : (
                            <p className="due-date-input-none">None</p>
                          )}{" "}
                        </div>{" "}
                      </div>
                    )}
                  </div>
                </Grid>
              </div>

              <div className="box">
                <Grid item className="allocated-hours ">
                  {" "}
                  <p>Due Date</p>
                  <div className="taskdetail-sidebar-right-col">
                    {dueDate ? (
                      <DatePickerSmall
                        date={date}
                        handleDueDateKeyDown={handleDueDateKeyDown}
                        handleDuedateBlur={handleDuedateBlur}
                      />
                    ) : (
                      <div className="due-date" onClick={handleDueDate}>
                        {" "}
                        <div className="due-date-input">
                          {" "}
                          {data?.due_date ? (
                            moment
                              .utc(data?.due_date)
                              .local()
                              .format("MM/DD/YYYY")
                          ) : (
                            <p className="due-date-input-none">None</p>
                          )}{" "}
                        </div>{" "}
                      </div>
                    )}
                  </div>
                </Grid>
              </div>

              <div className="box">
                <Grid item className="allocated-hours ">
                  <p>Reporter</p>
                  <div className="reporter-detail taskdetail-sidebar-right-col ">
                    <div className="reporter-image">
                      <Avatar
                        title={reportertooltipName}
                        className={" header-avatar add-comment-avtar"}
                        user_image={data?.reported_by?.user_image}
                        style={
                          !hasImage(data?.reported_by?.user_image) && {
                            bgcolor:
                              data?.reported_by?.profile_color?.background,
                            color: data?.reported_by?.profile_color?.text,
                          }
                        }
                      >
                        {reporterHeadShotName}
                      </Avatar>
                    </div>
                    <div className="reporter-name">
                      {data?.reported_by ? (
                        <p>{`${data?.reported_by?.first_name} ${data?.reported_by?.last_name}`}</p>
                      ) : (
                        <p>None</p>
                      )}
                    </div>
                    <div></div>
                  </div>
                </Grid>
              </div>
              {data && boardKey && (
                <SubTask cardDetail={data} boardKey={boardKey} />
              )}
            </Grid>
          </Grid>

          <div className="created-date-box">
            <div className="created-date">
              Created {<CustomDateFormatter date={data?.date_created} />}
              <div className="updated-date">
                Updated{" "}
                {differenceInDays(data?.updated_at) > 0
                  ? `${differenceInDays(data?.updated_at)} days ago`
                  : "Today"}{" "}
              </div>
            </div>
          </div>
        </Grid>
      </Box>
    </>
  );
}
