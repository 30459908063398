import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { updateWorkflowStep } from "../../../../sevices/apiCards";
import KanbanContainor from "./KanbanContainor";
import OpenCardDetail from "./openCardDetail";

function KanbanBoard({ workflowSteps, cards, id, boardName }) {
  const queryClient = useQueryClient();
  const [projects, setProjects] = useState(cards);
  const epicSearch = useSelector((state) => state.epic.searchEpic);
  const [sameWorkFlowIdcards, setSameWorkFlowIdcards] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const noMileStoneSearch = searchParams.get("noMilestone");
  const searchText = useSelector((state) => state.search.searchText);
  const filterUser = searchParams.get("search");
  const cardId = searchParams.get("card_id");

  useEffect(() => {
    const debounceDelay = 1000;
    let allFilteredCards = [...cards];

    const debouncedFilter = debounce(() => {
      if (searchText) {
        allFilteredCards = allFilteredCards?.filter(
          (cardData) =>
            (cardData?.card?.title
              ? `${cardData?.card?.title}`?.toLowerCase()?.includes(searchText)
              : "") ||
            (cardData?.card?.assigned_to_user?.first_name
              ? `${cardData?.card?.assigned_to_user?.first_name}`
                  ?.toLowerCase()
                  ?.includes(searchText)
              : "") ||
            (cardData?.card?.assigned_to_user?.last_name
              ? `${cardData?.card.assigned_to_user?.last_name}`
                  ?.toLowerCase()
                  ?.includes(searchText)
              : "") ||
            cardData?.key?.toLowerCase()?.includes(searchText) ||
            cardData?.card?.card_id
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchText) ||
            cardData?.card?.epic?.epic_name
              ?.toLowerCase()
              ?.includes(searchText) ||
            (cardData?.labels
              ? cardData?.labels?.some((ele) =>
                  ele?.toLowerCase()?.includes(searchText)
                )
              : "")
        );

        setProjects(allFilteredCards);
      }
    }, debounceDelay);

    if (filterUser) {
      allFilteredCards = allFilteredCards?.filter(
        (el) => el?.card?.assigned_to_user_id == filterUser
      );
    }
    if (epicSearch && epicSearch?.length > 0) {
      allFilteredCards = allFilteredCards?.filter((item) =>
        epicSearch?.includes(item?.card?.epic_id)
      );
    }
    if (searchText) {
      // Call the debounced filter function whenever searchText, id, or cards change
      debouncedFilter();
    }

    if (noMileStoneSearch === "true") {
      allFilteredCards = allFilteredCards?.filter(
        (item) => item?.card?.epic_id == null
      );
    }

    setProjects(allFilteredCards);

    return () => {
      // Cleanup the debounce function on component unmount
      debouncedFilter.cancel();
    };
  }, [searchText, filterUser, epicSearch, id, cards, noMileStoneSearch]);

  useEffect(() => {
    if (cardId) {
      const result = projects.find((item) => { return item.card?.card_id == Number(cardId) });
      const filterCardsByWorkflowStep = projects.filter(
        (cardData) =>
          (result?.card?.workflow_step_id == cardData?.card?.workflow_step_id)
      )

      setSameWorkFlowIdcards(filterCardsByWorkflowStep);
    }else {
      setSameWorkFlowIdcards([]);
    }
  }, [cardId])

  //Mutate a card //

  const { mutate: updateMutate } = useMutation({
    mutationFn: updateWorkflowStep,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
      toast.success(res?.data?.message);
    },
    onError: (error) => {
      console.error("An error occurred while updating task", error);
      toast.error(error?.response?.data?.message);
      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
    },
  });

  const dragCardInBoard = (source, destination, draggableId) => {
    const updatedProjects = projects?.slice(0);
    const updatedProjectIndex = updatedProjects?.findIndex(
      (projectObject) => projectObject?.card?.card_id == draggableId
    );
    if (updatedProjectIndex !== -1) {
      updateMutate({
        card_id: draggableId * 1,
        workflow_step_id: destination?.droppableId * 1,
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, `${draggableId}`],
      });
      updatedProjects[updatedProjectIndex].card.workflow_step_id =
        destination.droppableId * 1;
      setProjects(updatedProjects);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;

    if (source.droppableId === destination.droppableId) return;

    dragCardInBoard(source, destination, draggableId);
  };
  return (
    <>
      {/* <div className="vertical-scrollbar width-100"> */}
      {projects && cardId && <OpenCardDetail cardsOnBoard={sameWorkFlowIdcards} />}
      <DragDropContext onDragEnd={onDragEnd}>
        <KanbanColumnMemo
          boardName={boardName}
          projects={projects}
          workflowSteps={workflowSteps}
        />
      </DragDropContext>
    </>
  );
}

// Memoized KanbanColumn component
const KanbanColumnMemo = React.memo(KanbanContainor);

export default KanbanBoard;
