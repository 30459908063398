import React from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import GraphicElements from "../../../styles/svg/graphic_elements.svg";
import mobileBg from "../../../styles/svg/login_bg_mobile.svg";
import { useResponsive } from "../../../hooks/useResponsive";
import { useAssets } from "../../../utils/reactQuery/domainAssets";
import { getAPIBaseUrl } from "../../../utils/constants";
import TenantSiteName from "../../../components/TenantSiteName";
import { changePassword } from "../../../sevices/apiAuth";
import ForgotPasswordMessage from "../../../utils/Enums";

function ChangePassword({ authToken, onSuccess }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginWithEmail, setLoginWith] = React.useState(false);
  const [apiError, setApiError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, reset, watch, formState } = useForm({
    defaultValues: {},
  });
  const isMobile = useResponsive("down", "md");
  const { errors } = formState;
  const { logo } = useAssets();
  const apiUrl = getAPIBaseUrl();
  const password = watch("new_password") || "";

  const submit = (data) => {
    setApiError("");
    setLoading(true);
    changePassword(data, authToken)
      .then((res) => {
        onSuccess();
      })
      .catch((err) => {
        console.error(err);
        if (err.code == "ERR_NETWORK") {
          setApiError("An unexpected error occurred. Please try again later");
        } else setApiError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const requirements = ForgotPasswordMessage.REQUIREMENTS;
  const validations = [
    password?.length >= 8 && password?.length <= 15,
    /[A-Z]/.test(password),
    /[a-z]/.test(password),
    /\d/.test(password),
    /[!@#^*_?{}\-]/.test(password),
  ];
  const allRequirementsMet = validations.every((validation) => validation);

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${isMobile ? mobileBg : GraphicElements})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "auto",
        minHeight: "100vh",
      }}
      disableGutters={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: 440,
          justifyContent: "center",
          padding: "40px 15px",
        }}
      >
        {logo ? (
          <img
            src={`${apiUrl}/${logo}`}
            alt="not found"
            width={isMobile ? 251 : ""}
            className="login_logo"
          />
        ) : (
          <TenantSiteName />
        )}
        <Box
          component="form"
          onSubmit={handleSubmit(submit)}
          noValidate
          sx={{
            marginTop: 4,
            padding: isMobile ? "32px 16px" : "40px",
            borderRadius: "16px",
            boxShadow: "0px 4px 30px 0px rgba(27, 62, 97, 0.06)",
            background: "#ffffff",
          }}
        >
          <Typography
            className="text-center black-primary-color"
            component={"h1"}
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold" }}
          >
            Create New Password
          </Typography>
          <p className="create-new-password-info">
            Secure your account by setting a strong password.
          </p>
          {apiError && <Alert severity="error">{apiError}</Alert>}

          <List className="ml-3">
            {requirements.map((requirement, index) => (
              <ListItem key={index} sx={{ alignItems: "center", padding: 0 }}>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  {validations[index] ? (
                    <CheckCircleIcon sx={{ color: "green" }} />
                  ) : (
                    <RadioButtonUncheckedIcon sx={{ color: "gray" }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  className="create-new-password-info"
                  sx={{ textAlign: "left" }}
                  primary={requirement}
                />
              </ListItem>
            ))}
          </List>

          <TextField
            margin="normal"
            required
            fullWidth
            name="new_password"
            label="New Password"
            type="password"
            id="new_password"
            error={errors?.new_password ? true : false}
            helperText={errors?.new_password && errors.new_password.message}
            autoComplete="current-password"
            {...register("new_password", {
              required: "New Password is required",
            })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="confirmPassword"
            label={"Confirm Password"}
            name="confirmPassword"
            type="password"
            error={errors?.confirmPassword ? true : false}
            helperText={
              errors?.confirmPassword && errors.confirmPassword.message
            }
            autoComplete="Confirm Password"
            {...register("confirmPassword", {
              required: "Confirm Password is required",
              validate: (value) =>
                value === watch("new_password") || "Passwords do not match",
            })}
            className="primary-input-field"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="btn-blue"
            sx={{ mt: "14px", padding: "13px 16px" }}
            disabled={loading || !allRequirementsMet}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ChangePassword;
