import React from "react";
import Avatar from "../../../../../components/Avatar";
import { Grid, Tooltip } from "@mui/material";
import moment from "moment";
import CustomizedProgressBars from "../../../../board/components/progressBar/ProgressBar";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import CustomDateFormatter from "../../../../board/components/dateTimeFormateConverter/dateTimeFormateConverter";
import { createAvtarName, hasImage } from "../../../../../utils/helpers";
import { useResponsive } from "../../../../../hooks/useResponsive";

function FeedLabelHistory({ history, boardName }) {
  const navigate = useNavigate();
  const [data, updateSearchParams] = useCustomSearchParams("card_id");
  const isMobile = useResponsive("down", "md");
  const openBoard = () => {
    navigate(`/board/${boardName?.data?.data?.responseData?.board_id}`);
  };

  return (
    <>
      {history ? (
        <Grid
          container
          spacing={2}
          sx={{
            width: isMobile
              ? "calc(100% - 10px) !important"
              : "calc(100% + 16px) !important",
          }}
          className="worklog-item d-flex justify-content-space-between">
          <Grid
            item
            xs={isMobile ? 12 : 9}
            sx={{ paddingLeft: isMobile && "8px !important" }}>
            <div className="d-flex w-100">
              <Avatar
                // key={`user-avtar-${el.user_id}`}
                username={`${history?.response_object?.user_object?.username}`}
                user_image={history?.response_object?.user_object?.user_image}
                title={`${history?.response_object?.user_object?.username}`}
                className="user-avtar-dropdown user-avatar mr-8"
                style={
                  !hasImage(
                    history?.response_object?.user_object?.user_image
                  ) && {
                    bgcolor:
                      history?.response_object?.user_object?.user_profile_color
                        ?.background,
                    color:
                      history?.response_object?.user_object?.user_profile_color
                        ?.text,
                  }
                }>
                {createAvtarName(
                  `${history?.response_object?.user_object?.username}` || ""
                )}
              </Avatar>
              <div className="w-100">
                <div className="d-flex justify-content-space-between ">
                  <div className={"d-flex align-items-center"}>
                    <div>
                      <Tooltip title={"ABC"}>
                        <a
                          className="board-anquor cursor-pointer"
                          onClick={openBoard}>
                          {`${boardName?.data?.data?.responseData?.key}`.toUpperCase()}
                        </a>
                      </Tooltip>
                      -
                      <a
                        className="board-anquor cursor-pointer mr-1"
                        onClick={() => {
                          updateSearchParams("card_id", history?.card_id);
                        }}>
                        {history?.card_id}:
                      </a>
                    </div>
                    <p className="worklog-title mr-2">
                      {history?.response_object?.user_object?.username}
                    </p>
                    {history?.response_object?.added_epic && (
                      <div className="card-epic-tag mr-1">
                        <p className="card-epic-tag-text">
                          {history?.response_object?.added_epic}
                        </p>
                      </div>
                    )}
                    {history?.response_object?.removed_epic && (
                      <div className="card-epic-tag mr-1">
                        <p className="card-epic-tag-text">
                          {history?.response_object?.removed_epic}
                        </p>
                      </div>
                    )}
                    {
                      <div className="d-flex">
                        {history?.response_object?.added_labels &&
                          history?.response_object?.added_labels?.map(
                            (el) =>
                              el != "" && (
                                <div className="card-level-tag  mr-1">
                                  <p className="card-level-tag-text">{el}</p>
                                </div>
                              )
                          )}
                        {history?.response_object?.removed_labels &&
                          history?.response_object?.removed_labels?.map(
                            (el) =>
                              el != "" && (
                                <div className="card-level-tag mr-1">
                                  <p className="card-level-tag-text">{el}</p>
                                </div>
                              )
                          )}
                      </div>
                    }
                  </div>
                  {/* <p className="hours">{worklog.hours_spent} Hour</p> */}
                </div>
                <p
                  className="worklog-worklog comment-content"
                  dangerouslySetInnerHTML={{
                    __html: history?.history_text,
                  }}></p>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 3}
            sx={{ paddingTop: isMobile && "0px !important" }}>
            <div className={"right-details d-flex"}>
              <p className="worklog-date-time comment-date comment">
                Updated on{" "}
              </p>
              <p className="worklog-date-time comment-date">
                {/* {!sameDay && <img src={lateIcon} className="late-icon" />} */}

                {isMobile
                  ? moment
                      .utc(history?.at_time)
                      .local()
                      .format("MMM D, YYYY [at] h:mm A")
                  : moment
                      .utc(history?.at_time)
                      .local()
                      .format("MMMM D, YYYY [at] h:mm A")}
              </p>
            </div>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default FeedLabelHistory;
