import React from "react";
// import RouterErrorPage from "../../Layouts/Router/RouterErrorPage";
import Protected from "./Protected";
import ForgotPassword from "../../pages/auth/forgotPassword/ForgotPassword";
import ResetPassword from "../../pages/auth/forgotPassword/ResetPassword";
import LoginWrapper from "../../pages/auth/LoginWrapper";

export const Auth = {
  element: <Protected />,
  //   errorElement: <RouterErrorPage />,
  children: [
    {
      element: <LoginWrapper />,
      path: "/",
    },
    {
      element: <ForgotPassword />,
      path: "/forgotpassword",
    },
    {
      element: <ResetPassword />,
      path: "/resetpassword",
    },
  ],
};
