import * as yup from "yup";

export const adminSettingCompanySchemas = yup
  .object({
    company_name: yup
      .string()
      .test(
        "len",
        "Checkpoint name must be between 1 and 30 characters long",
        (val) => val && val.length <= 30
      )
      .required("Name is required"),
    domain: yup
      .string()
      // .matches(/^[^.]*$/, "Domain must not contain a dot (.)")
      .required("URL is required"),
    // email: yup.string().required("Mail is required"),
  })
  .required();

  export const adminSettingLogoSchemas = yup
    .object({
      logo: yup
        .mixed()
        .nullable() // Allow null or undefined values
        .test("fileType", "Unsupported file format", (value) => {
          if (!value) return true; // Skip the test if value is null or undefined
          return ["image/jpeg", "image/png"].includes(value.type);
        })
        .test("fileSize", "File size exceeds 200KB", (value) => {
          if (!value) return true; // Skip the test if value is null or undefined
          return value.size <= 200 * 1024;
        }),
      favicon: yup
        .mixed()
        .nullable()
        .test("fileType", "Unsupported file format", (value) => {
           if (!value) return true;
          return value && ["image/jpeg", "image/png"].includes(value.type);
        })
        .test("fileSize", "File size exceeds 200KB", (value) => {
           if (!value) return true;
          return value && value.size <= 200 * 1024;
        }),
    })
    .required();


  export const adminSettingLDAPSettingsSchemas = yup
    .object({
      ldap_url: yup
        .string()
        .nullable()
        .notRequired()
        .test(
          "is-valid-ldap-url",
          "Invalid LDAP URL format",
          (value) =>
            !value ||
            /^(ldap[s]?:\/\/)?(?:\[([^\]]+)\]|([^:/?#]+))\:(\d+)$/.test(value)
        ),
      ldap_base_dn: yup.string(),
      ldap_user: yup.string(),
      ldap_password: yup.string(),
    })
    .required();

// export const adminSettingPageSchemas = yup
//   .object({
//     checkpoint_name: yup
//       .string()
//       .test(
//         "len",
//         "Checkpoint name must be between 1 and 30 characters long",
//         (val) => val && val.length <= 30
//       )
//       .required("Name is required"),
//     url: yup.string().required("URL is required"),
//     email: yup.string().required("Mail is required"),
//     favIcon: yup.string().required("Favicon is required"),
//     ldapUrl: yup.string().required("LDAP URL is required"),
//     searchBase: yup.string().required("Search base is required"),
//     bindDn: yup.string().required("Bind DN is required"),
//     password: yup.string().required("Password is required"),
//     logoImage: yup
//       .mixed()
//       .required("Logo is required")
//       .test("fileType", "Unsupported file format", (value) => {
//         return value && ["image/jpeg", "image/png"].includes(value.type);
//       })
//       .test("fileSize", "File size exceeds 2MB", (value) => {
//         return value && value.size <= 2 * 1024 * 1024;
//       }),
//     favIcon: yup
//       .mixed()
//       .required("Fav icon is required")
//       .test("fileType", "Unsupported file format", (value) => {
//         return value && ["image/jpeg", "image/png"].includes(value.type);
//       })
//       .test("fileSize", "File size exceeds 2MB", (value) => {
//         return value && value.size <= 2 * 1024 * 1024;
//       }),
//   })
//   .required();
