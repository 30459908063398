import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { isAdmin } from "../../../../../utils/constants";
import { updateBoard } from "../../../../../sevices/apiBoards";

function UpdateName({ boardName }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // React Hook Form
  const { register, handleSubmit, reset, getValues, formState, setValue } =
    useForm({
      defaultValues: {
        board_name: boardName.data.responseData.board_name,
      },
    });
  const { errors } = formState;

  useEffect(() => {
    setValue("board_name", boardName.data.responseData.board_name);
  }, [id]);

  const { mutate } = useMutation({
    mutationFn: updateBoard,
    onSuccess: (res) => {
      
      queryClient.invalidateQueries({
        queryKey : ["boards"],
        exact : true
      });
      queryClient.invalidateQueries({
        queryKey: ["boardName", id],
      });
      setLoading(false);
      toast.success(res.data.message);
      //  handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
      //  handleCloseDialog();
    },
  });

  const submit = (data) => {
    /**
     * Change Board Name
     */
    setOpen(false);
    if (data.board_name != boardName.data.responseData.board_name) {
      setLoading(true);
      mutate({ data, board_id: id });
    }
  };

  const openField = () => {
    if (boardName?.data?.responseData?.is_owner || isAdmin()) setOpen(true);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(submit)();
    }
  };
  // console.log("getValues", formState);
  return (
    <>
      {open ? (
        <>
          <Box
            component="form"
            onSubmit={handleSubmit(submit)}
            noValidate
            className="board-field-name"
            onBlur={handleSubmit(submit)}
            onKeyDown={handleKeyDown}
          >
            <TextField
            className="board-heading-name"
              // label={"Board Name"}
              // sx={{height: '1.375em',}}
              required
              fullWidth
              size="small"
              id="board_name"
              name="board_name"
              error={errors?.board_name ? true : false}
              helperText={errors?.board_name && errors.board_name.message}
              {...register("board_name")}
              autoFocus
            />
          </Box>
        </>
      ) : (
        <p className="board-heading-name" onClick={openField}>
          {boardName?.data?.responseData?.board_name}
        </p>
      )}
    </>
  );
}

export default UpdateName;
