import React from 'react'

function TenantSiteName() {
  return (
    <div>
      <h1 className="uppercase" style={{ color: "#1b3e61" }}>
        {window.location.hostname.split('.')[0]}
      </h1>
    </div>
  );
}

export default TenantSiteName