import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

import { createCard } from "../../../../sevices/apiCards";
import FieldCreateEditCard from "./FieldCreateEditCard";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useResponsive } from "../../../../hooks/useResponsive";

function CreateCard({ stage }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [createBtnOpen, setCreateBtnOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const isMobile = useResponsive("down", "sm");

  const { mutate: createMutate } = useMutation({
    mutationFn: createCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
      setLoading(false);
      setTitle("");
      toast.success(res.data.message);
      setCreateBtnOpen(false);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
    },
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleEnterKeyPress();
    }
  };

  const handleEnterKeyPress = () => {
    const data = {
      title,
      workflow_step_id: stage,
      board_id: id,
      ...(milestone && { epic_id: milestone }),
    };

    if (title) {
      createMutate(data);
      queryClient.invalidateQueries({
        queryKey: ["epic_id", milestone],
      });
      queryClient.invalidateQueries({
        queryKey: ["milestone_history", milestone],
      });
    }
  };

  return (
    <div style={isMobile ? { width: "100%" } : {}}>
      {createBtnOpen ? (
        <div
          style={{ marginBottom: "10px", width: isMobile ? "100%" : "auto" }}>
          <FieldCreateEditCard
            handleKeyDown={handleKeyDown}
            setTitle={setTitle}
            value={title}
            setCreateBtnOpen={() => setCreateBtnOpen(false)}
          />
          {/* <FormControl fullWidth>
            <TextareaAutosize
              aria-label="empty textarea"
              placeholder="What needs to be done?"
              minRows={4} // Set the minimum number of rows
              className="create-card-textarea"
              onKeyDown={handleKeyDown}
              onChange={(e) => setTitle(e.target.value)}
              onBlur={() => setCreateBtnOpen(false)}
              autoFocus
            />
            <InputAdornment position="end">
              <img src={CheckBox} className=" mb-6" />
              <ExpandMoreIcon className="mb-6" />
            </InputAdornment>
          </FormControl> */}
        </div>
      ) : (
        <div className="d-flex justify-content-end create-card-div">
          <Button
            size="small"
            className="create-card-btn"
            sx={{ color: "rgba(27, 62, 97, 1)" }}
            onClick={() => setCreateBtnOpen(true)}>
            <AddIcon sx={{ fontSize: "16px" }} />
            <p className="create-card-btn-text"> Create New</p>
          </Button>
        </div>
      )}
    </div>
  );
}

export default CreateCard;
