import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEpic } from "../../../../../../../utils/reactQuery/epics";
import SingleSelectDropdown from "./singleSelectDropdown/singleSelectDropdown";
const Epic = ({ cardId, handleEpicBlur, showEpic, setShowEpic, boardId }) => {
  // const [epic, setEpic] = useState(null)
  const { id } = useParams();
  const { isLoading, epics, isError } = useEpic(boardId);

  const handleEpic = (epic_id) => {
    handleEpicBlur(epic_id);
  };
  const names = epics?.data?.data?.responseData?.filter(
    (item) => item.completed == false
  );
  // const names = epics?.data?.data?.responseData;

  return (
    <>
      <SingleSelectDropdown
        epicsName={names}
        cardId={cardId}
        handleEpicBlur={handleEpicBlur}
        showEpic={showEpic}
        setShowEpic={setShowEpic}
      />
    </>
  );
};

export default Epic;
