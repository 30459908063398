import React, { useState } from "react";
import { Button } from "@mui/material";

import "./epicbutton.scss";
import addEpicIcone from "../../../../../styles/svg/add-epic.svg";
import AddMilestone from "../../../../../components/Milestone/addMilestone";

const CreateEpic = () => {
  const [addMilestonePop, setAddMileStonePop] = useState(false);

  return (
    <>
      <div className="create-epic-btn" onClick={() => setAddMileStonePop(true)}>
        <Button
          sx={{
            backgroundColor: "#1B3E61",
            "&:hover": {
              backgroundColor: "#1B3E61",
              border: "none",
            },
          }}
          variant="contained"
        >
          <img src={addEpicIcone} alt="add milestone" /> Create New
        </Button>
      </div>

      <AddMilestone
        open={addMilestonePop}
        handleClose={() => setAddMileStonePop(false)}
        title={"Create Milestone"}
        btnTitle={"Create"}
      />
    </>
  );
};

export default CreateEpic;
