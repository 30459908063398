import { FormHelperText, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import workflowStepdotsIcon from "../../../../../styles/svg/workflowStepdotsIcon.svg";
import adminWorkflowDelete from "../../../../../styles/svg/adminWorkflowDelete.svg";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteWorkflowStep } from "../../../../../sevices/apiWorkflows";
import toast from "react-hot-toast";
import { Controller } from "react-hook-form";
import { useGenericWorkflowStatus } from "../../../../../utils/reactQuery/workflows";

const DraggableField = ({
  field,
  index,
  remove,
  edit,
  setOpenRemoveStep,
  register,
  setCurrentStep,
  control,
  errors,
  setValue,
}) => {
  const queryClient = useQueryClient();
  const { genericWorkflowStatus } = useGenericWorkflowStatus();
  const [showGenericError, setShowGenericError] = useState(false);
  const { mutate: deleteWorkflowStepMutate } = useMutation({
    mutationFn: deleteWorkflowStep,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`adminWorkflow`],
      });
      toast.success(res.data.message);

      remove(index);
    },
    onError: (error) => {
      setOpenRemoveStep(true);
      console.error("Error Delete Attachments", error);
    },
  });

  const handleDelete = (field) => {
    if (field.workflow_step_id) {
      setCurrentStep(field);

      deleteWorkflowStepMutate({ workflow_step_id: field.workflow_step_id });
    } else {
      remove(index);
    }
  };

  let hasError =
    errors?.workflow_steps && errors?.workflow_steps[index]?.status?.message
      ? true
      : false;
  const hasGeneric = (isError) => {
    setShowGenericError(isError);
    return isError;
  };
  return (
    <>
      <Draggable key={field.id} draggableId={field.id} index={index}>
        {(provided) => (
          <div
            className="workflowStep-container"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            key={field.id}>
            <div
              className="workflowStep"
              style={{ border: hasError && "1px solid red" }}>
              <img src={workflowStepdotsIcon} alt="" />
              <TextField
                id={`workflow_steps.${index}.status`}
                size="small"
                variant="standard"
                autoFocus
                error={hasError}
                {...register(`workflow_steps.${index}.status`, {
                  required: "workflow step is required",
                })}
                onChange={(e) => {
                  const value = e.target.value;
                  setValue(
                    `workflow_steps.${index}.status`,
                    value.trimStart(),
                    {
                      shouldValidate: true,
                    }
                  );
                }}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    padding: "0px",
                    marginLeft: "10px",
                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "rgba(33, 43, 54, 1)",
                      padding: "0px",
                      textTransform: "uppercase",
                    },
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "0px",
                    marginLeft: "10px",
                    "& fieldset": {
                      border: "0px",
                    },
                  },
                }}
              />
            </div>

            <Controller
              name={`workflow_steps.${index}.generic_step_id`}
              control={control}
              rules={{
                required: "true",
                validate: (value) =>
                  value !== "None" || "Please select a valid option",
              }}
              render={({ field, fieldState }) => (
                <>
                  <Select
                    {...field}
                    value={field.value || ""}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    error={hasGeneric(!!fieldState?.error)}
                    displayEmpty
                    sx={{ minWidth: 120, marginLeft: "10px" }}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {genericWorkflowStatus?.data?.data?.responseData?.map(
                      (ele, idx) => (
                        <MenuItem key={idx} value={ele?.workflow_step_id}>
                          {ele.status}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </>
              )}
            />

            {!edit ? (
              <div
                className="workflowStep-delete"
                onClick={() => remove(index)}>
                <img src={adminWorkflowDelete} alt="" />
              </div>
            ) : (
              <div
                className="workflowStep-delete"
                onClick={() => handleDelete(field)}>
                <img src={adminWorkflowDelete} alt="" />
              </div>
            )}
          </div>
        )}
      </Draggable>
      {hasError && (
        <FormHelperText error>workflow step is required</FormHelperText>
      )}
      {!hasError && showGenericError && (
        <FormHelperText error>Generic workflow step is required</FormHelperText>
      )}
    </>
  );
};

export default DraggableField;
