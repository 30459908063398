import { Box, Button, InputAdornment, Stack, Typography } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import { adminSettingPageSchemas } from "../../../schemas/adminSettingPage";
import RHFTextField from "../../../components/RFH/RFHtextfield";
import RHFFileInputButton from "../../../components/RFH/RFHfileInput";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useAssets } from "../../../utils/reactQuery/domainAssets";
import { getAPIBaseUrl } from "../../../utils/constants";
import CompanyInfoForm from "./CompanyInfoForm";
import BrandingLogo from "./BrandingLogo";
import LDAPSettingsBindCred from "./LDAPSettingsBindCred";
import { useTenant } from "../../../utils/reactQuery/tenant";
import Loader from "../../../components/Loader";

function SystemSettingComp() {
  const { data, isLoading } = useTenant();
  const { responseData = null } = data?.data?.data || {};
  const {
    company_name = "",
    domain = "",
    ldap_host = "",
    ldap_port = "",
    ldap_user = "",
    ldap_base_dn = "",
  } = responseData || {};

  return (
    <>
      <Typography className="admin-header-heading">System Setting</Typography>
      {isLoading ? (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      ) : (
        <>
          <CompanyInfoForm
            initialData={{
              company_name,
              domain: domain.split(".")?.[0],
            }}
          />
          <BrandingLogo />
          <LDAPSettingsBindCred
            initialData={{
              ldap_url:
                ldap_host && ldap_port ? ldap_host + ":" + ldap_port : "",
              ldap_user,
              ldap_base_dn,
            }}
          />
        </>
      )}
    </>
  );
}

export default SystemSettingComp;
