import React from "react";
import { useDispatch } from "react-redux";
import { MenuItem } from "@mui/material";

import {
  setAllGroupUser,
  setGroupUser,
} from "../../../../redux/TaskGroupingSlice";

function TeamFilter({ isButton = false, team, user }) {
  const dispatch = useDispatch();
  const allTeams =
    team?.data.data.responseData?.map((team) => {
      return {
        ...team,
        users: team.users.filter((user) => user.active),
      };
    }) || [];

  const setTeam = (teamUser, isChecked) => {
    console.log("team user === ", teamUser, isChecked);
    const selectedUser = user;
    dispatch(setAllGroupUser([]));

    if (isChecked) {
      selectedUser.forEach((element) => {
        if (element.team_id !== teamUser.team_id) {
          dispatch(setGroupUser(element));
        }
      });
    } else {
      teamUser.users.forEach((element) => {
        dispatch(setGroupUser(element));
      });

      selectedUser.forEach((element) => {
        if (element.team_id !== teamUser.team_id) {
          dispatch(setGroupUser(element));
        }
      });
    }
  };

  return (
    <div className="mr-1">
      {allTeams.map((team, idx) =>
        team && team?.users && team?.users.length ? (
          <MenuItem className="custom-border" key={`team-list-${idx}`}>
            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={team?.users?.every((ele) =>
                  user.some((element) => element.user_id === ele.user_id)
                )}
                onClick={(event) => {
                  event.stopPropagation();
                  setTeam(
                    team,
                    team?.users?.every((ele) =>
                      user.some((element) => element.user_id === ele.user_id)
                    )
                  );
                }}
              />
              <span className="checkmark"></span>
              {team.team}
            </label>
          </MenuItem>
        ) : (
          ""
        )
      )}
    </div>
  );
}

export default TeamFilter;
