import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Avatar from "@mui/material/Avatar";
import { useParams } from "react-router-dom";
import { useBoardUsers } from "../../../../../utils/reactQuery/users";
import "./ImportCardMuiSelect.scss";
import { useResponsive } from "../../../../../hooks/useResponsive";
import { getAPIBaseUrl } from "../../../../../utils/constants";
export default function MuiSelectDropdown({ handleUserId }) {
  const [selectedUser, setSelectedUser] = React.useState(null);
  const isMobile = useResponsive("down", "md");
  const { id } = useParams();
  const getUrl = getAPIBaseUrl();
  const handleChange = (event) => {
    const user = event.target.value;

    setSelectedUser(event.target.value);
    handleUserId(user);
  };

  const { data } = useBoardUsers(id);

  return (
    <Box
      sx={{
        minWidth: 120,
        display: "flex",
        justifyContent: "right",
        marginBottom: "20px",
      }}
    >
      <p className="select-assignee">Assignee :</p>
      <FormControl sx={{ m: 0, minWidth: "170px" }} size="small">
        <InputLabel id="demo-simple-select-label">Select Assignee</InputLabel>
        <Select
          value={selectedUser}
          label="Select Assignee"
          onChange={handleChange}
          inputProps={{ "aria-label": "Without label" }}
          className="selected-assignee"
          MenuProps={{
            PaperProps: {
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                width: "250px",
                maxHeight: "250px",
                overflowY: "auto",
                left: isMobile ? "" : "760px !important",

                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 14,
                  width: 14,
                  height: 14,
                  bgcolor: "white",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            },
            transformOrigin: { horizontal: "Right", vertical: "top" },
            anchorOrigin: { horizontal: "Right", vertical: "bottom" },
          }}
        >
          {data?.data?.data?.responseData.map((item) => (
            <MenuItem
              value={item.user_id}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Avatar
                alt=""
                src={getUrl + item.user_image}
                sx={{
                  marginRight: "15px",
                  width: "20px",
                  height: "20px",
                  border: "0.5px solid rgba(192,192,192, .5)",
                }}
              />
              <span>{item.username}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
