import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";

import Users from "./Users/Users";
import GlobalSearch from "../../../../components/Search";
import attachFileIcon from "../../../../styles/svg/attachment.svg";
import AttachmentBar from "../attachments";
import Archive from "./Archive";
import BoardName from "./BoardName";
import { isAdmin } from "../../../../utils/constants";
import EpicButton from "./epicButton/EpicButton";
import { filterUser } from "../../../../redux/usersSlice";
import { searchCard, setGridView } from "../../../../redux/SearchSlice";
import gridActiveIcon from "../../../../styles/svg/grid-view-active.svg";
import gridInactiveIcon from "../../../../styles/svg/grid-view-inactive.svg";
import listActiveIcon from "../../../../styles/svg/list-view-active.svg";
import listInactiveIcon from "../../../../styles/svg/list-view-inactive.svg";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import MilestoneSection from "../MilestoneSection";
import { useResponsive } from "../../../../hooks/useResponsive";
import filterIcon from "../../../../styles/svg/epic-icon.svg";
import StatusDropDown from "./statusDropdown/StatusDropDown";
import { localStorageInstance } from "../../../../utils/localStorage";

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 250,
  transition: "transform 1s ease-in-out",
}));

function BoradHeader({ boardName, boardId, ownerId, workflowId, setLoading }) {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(["boardName", id]);
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const isMobile = useResponsive("down", "md");
  const [filterDrawer, setFilterDrawer] = useState(false);
  const showGridView = useSelector((state) => state.search.gridView);
  const [attachmentDialog, setAttachmentDialog] = useState({
    right: false,
  });

  const handleChange = (event) => {
    const value = event.target?.value?.trimStart();
    setInputValue(value);
    debouncedSearch(value);
  };
  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchCard(value));
    }, 300)
  ).current;

  const [inputValue, setInputValue] = useState("");
  const gridView = useSelector((state) => state.search.gridView);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setAttachmentDialog((prev) => ({ ...prev, [anchor]: open }));
  };

  const removeSearchParams = (paramsToRemove) => {
    // Create a copy of the current search params
    const newSearchParams = new URLSearchParams(searchParams);

    // Remove each parameter in the paramsToRemove array
    paramsToRemove.forEach((param) => {
      newSearchParams.delete(param);
    });

    // Set the updated search params
    setSearchParams(newSearchParams);
  };
  const filterUserId = searchParams.get("search");
  const epicFilter = searchParams.get("epic");
  const clearFilters = () => {
    removeSearchParams(["search", "epic"]);
    dispatch(filterUser(null));
    dispatch(searchCard([]));
  };
  const trigger = useScrollTrigger();

  return (
    <>
      <Grid
        container
        item
        xs={12}
        className="remove-container-width fixed-ui"
        sx={{ top: trigger ? "0px" : isMobile ? "50px" : "" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={6} md={12} lg={4} className="d-flex align-items-center">
          {boardName && <BoardName boardName={boardName} />}
        </Grid>
        <Grid item xs={6} md={7} lg={8}>
          <div className="d-flex justify-content-end">
            {isMobile ? (
              <Stack direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  className="white-button attach-btn"
                  onClick={() =>
                    setAttachmentDialog({ ...attachmentDialog, right: true })
                  }
                  sx={{
                    height: "38px",
                    padding: "18px 5px",
                    minWidth: "35px",
                    "&:hover": {
                      outline: "none",
                      backgroundColor: "rgb(240 249 254)",
                      border: "none",
                    },
                  }}
                >
                  <img
                    src={attachFileIcon}
                    className="header-filter-icon"
                    alt="not found"
                  />
                </Button>
                <img
                  src={filterIcon}
                  alt="filter"
                  style={{ marginRight: "8px" }}
                  onClick={() => setFilterDrawer(true)}
                />
              </Stack>
            ) : (
              <Stack spacing={1} direction="row">
                {(filterUserId || epicFilter) && (
                  <Button
                    size="small"
                    endIcon={<ClearIcon />}
                    className="capitalize"
                    onClick={clearFilters}
                  >
                    Clear Filter
                  </Button>
                )}
                <Users
                  boardName={boardName}
                  boardId={boardId}
                  ownerId={ownerId}
                />
                {!showGridView && <StatusDropDown workflowId={workflowId} />}
                <EpicButton boardNameDetails={boardName} />
                <Stack className="grid-list-cont">
                  <IconButton
                    aria-label="grid-view"
                    sx={{ borderRadius: "0px" }}
                  >
                    <img
                      src={gridView ? gridActiveIcon : gridInactiveIcon}
                      alt="gridIcon"
                      onClick={() => {
                        const newSearchParams = new URLSearchParams(
                          searchParams
                        );
                        newSearchParams.delete("workflow_Step");
                        setSearchParams(newSearchParams);
                        if (!gridView) {
                          dispatch(setGridView());
                        }
                        localStorageInstance.setItem({
                          key: "showGridView",
                          value: true,
                        });
                      }}

                      // onClick={() => (gridView ? "" : dispatch(setGridView()))}
                    />
                  </IconButton>
                  <IconButton
                    aria-label="list-view"
                    sx={{
                      borderRadius: "0px",
                      borderLeft: "1px solid rgba(145, 158, 171, 0.2)",
                    }}
                  >
                    <img
                      src={gridView ? listInactiveIcon : listActiveIcon}
                      alt="listIcon"
                      // onClick={() => (gridView ? dispatch(setGridView()) : "")}
                      onClick={() => {
                        // gridView ? dispatch(setGridView()) : "";
                        if (gridView) {
                          dispatch(setGridView());
                        }
                        setLoading();
                        localStorageInstance.setItem({
                          key: "showGridView",
                          value: false,
                        });
                      }}
                    />
                  </IconButton>
                </Stack>
                <Button
                  variant="outlined"
                  color="primary"
                  className="white-button attach-btn"
                  onClick={() =>
                    setAttachmentDialog({ ...attachmentDialog, right: true })
                  }
                  sx={{
                    height: "38px",
                    padding: "18px 5px",
                    minWidth: "35px",
                    "&:hover": {
                      outline: "none",
                      backgroundColor: "rgb(240 249 254)",
                      border: "1px solid #1b3e61",
                    },
                  }}
                >
                  <img
                    src={attachFileIcon}
                    className="header-filter-icon"
                    alt="not found"
                  />
                  {/* &nbsp; <p className="header-action-button-text">Attach</p> */}
                </Button>
                <GlobalSearch
                  placeholder={"Search..."}
                  searchText={inputValue}
                  handleChange={handleChange}
                />
                <Archive
                  isOwner={cachedData?.data?.data?.responseData?.is_owner}
                  isAdmin={isAdmin()}
                  boardId={boardId}
                />
              </Stack>
            )}
          </div>
        </Grid>
        {milestone && <MilestoneSection boardName={boardName} />}
      </Grid>
      {attachmentDialog && (
        <AttachmentBar state={attachmentDialog} toggleDrawer={toggleDrawer} />
      )}
      {filterDrawer && (
        <Drawer
          open={filterDrawer}
          anchor="right"
          onClose={() => setFilterDrawer(false)}
        >
          <AnimatedDrawerContent
            style={{
              transform: filterDrawer ? "translateX(0)" : "translateX(100%)",
            }}
          >
            <Box p={1}>
              {/* <Stack direction="row" justifyContent="end">
                <Typography mb={1}>Filter</Typography>

                {(filterUserId || epicFilter) && (
                  <Button
                    size="small"
                    endIcon={<ClearIcon />}
                    className="capitalize"
                    onClick={clearFilters}>
                    Clear Filter
                  </Button>
                )}
              </Stack> */}
              <Typography color="#1B3E61" mb={1} fontWeight={600}>
                Select user
              </Typography>
              <Users
                boardName={boardName}
                boardId={boardId}
                ownerId={ownerId}
              />
              <Typography color="#1B3E61" mb={1} mt={1} fontWeight={600}>
                Select milestone
              </Typography>
              <EpicButton boardNameDetails={boardName} />

              <div className="mt-2">
                <Archive
                  isOwner={cachedData?.data?.data?.responseData?.is_owner}
                  isAdmin={isAdmin()}
                  boardId={boardId}
                />
              </div>
            </Box>
          </AnimatedDrawerContent>
        </Drawer>
      )}
    </>
  );
}

export default BoradHeader;
