import React, { useEffect, useState } from "react";
import User from "./User";
import Filter from "./Filter";
import Grouping from "../../Grouping";
import { useResponsive } from "../../../../hooks/useResponsive";
import {
  Box,
  Button,
  Drawer,
  Stack,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import { styled } from "@mui/system";
import attachFileIcon from "../../../../styles/svg/attachment.svg";
import filterIcon from "../../../../styles/svg/epic-icon.svg";
import AttachmentBar from "../../../board/components/attachments";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useUserWorkflowList } from "../../../../utils/reactQuery/workflows";
import { useDispatch } from "react-redux";
import { useBoards } from "../../../../utils/reactQuery/boards";
import { setActiveBoards } from "../../../../redux/ActiveBoardSlice";

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 250,
  transition: "transform 1s ease-in-out",
}));

function Header() {
  const isMobile = useResponsive("down", "md");
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [attachmentDialog, setAttachmentDialog] = useState({
    right: false,
  });
  const [workflowId, setWorkflowId] = useCustomSearchParams("workflow_id");
  const trigger = useScrollTrigger();
  const dispatch = useDispatch();
  const { isLoading, boards, isError } = useBoards();

  useEffect(() => {
    if (boards) {
      dispatch(
        setActiveBoards(
          boards?.data?.data?.responseData
            .filter((el) => !el.is_archive)
            .sort(function (a, b) {
              return a.board_name.localeCompare(b.board_name);
            }) || []
        )
      );
    }
  }, [boards]);
  const { userWorkflows } = useUserWorkflowList();
  useEffect(() => {
    if (workflowId) {
      //eat five star do nothing
    } else {
      if (userWorkflows?.data?.data?.responseData[0]?.workflow_id) {
        setWorkflowId(
          "workflow_id",
          userWorkflows?.data?.data?.responseData[0]?.workflow_id
        );
      }
    }
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setAttachmentDialog((prev) => ({ ...prev, [anchor]: open }));
  };

  return (
    <div
      className="d-flex justify-content-space-between"
      style={{
        paddingTop: isMobile ? "12px" : "",
        paddingBottom: isMobile ? "17px" : "",
        backgroundColor: "#fff",
        position: "sticky",
        top: trigger ? "0px" : isMobile ? "50px" : "",
        zIndex: 100,
      }}
    >
      {isMobile ? (
        <div style={{ flexGrow: "1", marginLeft: "8px" }}>
          <User />
        </div>
      ) : (
        <User />
      )}
      {isMobile ? (
        <div>
          <Stack direction="row" justifyContent={"space-between"}>
            {/* <Button
              variant="outlined"
              color="primary"
              className="white-button attach-btn"
              onClick={() =>
                setAttachmentDialog({ ...attachmentDialog, right: true })
              }
              sx={{
                height: "38px",
                padding: "18px 5px",
                minWidth: "35px",
                "&:hover": {
                  outline: "none",
                  backgroundColor: "rgb(240 249 254)",
                  border: "none",
                },
              }}>
              <img
                src={attachFileIcon}
                className="header-filter-icon"
                alt="not found"
              />
            </Button> */}
            <img
              src={filterIcon}
              alt="filter"
              style={{ marginRight: "8px" }}
              onClick={() => setFilterDrawer(true)}
            />
          </Stack>
        </div>
      ) : (
        <div className="d-flex">
          <Filter />
        </div>
      )}

      {filterDrawer && (
        <Drawer
          open={filterDrawer}
          anchor="right"
          onClose={() => setFilterDrawer(false)}
        >
          <AnimatedDrawerContent
            style={{
              transform: filterDrawer ? "translateX(0)" : "translateX(100%)",
            }}
          >
            <Box p={1}>
              {/* <Stack direction="row" justifyContent="end">
                <Typography mb={1}>Filter</Typography>

                {(filterUserId || epicFilter) && (
                  <Button
                    size="small"
                    endIcon={<ClearIcon />}
                    className="capitalize"
                    onClick={clearFilters}>
                    Clear Filter
                  </Button>
                )}
              </Stack> */}
              <Filter />
            </Box>
          </AnimatedDrawerContent>
        </Drawer>
      )}
    </div>
  );
}

export default Header;
