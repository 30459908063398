import React, { useEffect, useState } from "react";
import MultiSelctCheckboxDropdown from "../../../../components/CheckboxMultiselect";
import { IconButton } from "@mui/material";
import downArrowImg from "../../../../styles/svg/downArrow.svg";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
const MilestoneColumnHeader = () => {
  const [selectedNames, setSelectedNames] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const newSearchParams = new URLSearchParams(searchParams);
  const milestone = useSelector(
    (state) => state.listViewHeaderFilters.milestoneIds
  );

  const milestoneSearch = searchParams.get("milestone");

  useEffect(() => {
    if (!isNaN(Number(milestoneSearch))) {
      setSelectedNames([]);
    }
  }, [milestoneSearch]);

  const handleSelectionChange = (newSelection) => {
    setSelectedNames(newSelection);

    if (newSelection.length > 0) {
      newSearchParams.set("columnMilestone", newSelection.join(","));
    } else {
      newSearchParams.delete("columnMilestone");
    }
    setSearchParams(newSearchParams);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearFilter = () => {
    setSelectedNames([]);
    newSearchParams.delete("columnMilestone");
    setSearchParams(newSearchParams);
  };

  return (
    <>
      <button className="remove-default-button-style">
        Milestone
        <IconButton onClick={handleClick}>
          <img src={downArrowImg} alt="arrow" />
        </IconButton>
      </button>
      <MultiSelctCheckboxDropdown
        items={milestone}
        selectedItems={selectedNames}
        onSelectionChange={handleSelectionChange}
        isButton={true}
        anchorEl={anchorEl}
        valueKey="epic_id"
        displayKey="epic_name"
        handleClose={() => setAnchorEl(null)}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
};

export default MilestoneColumnHeader;
