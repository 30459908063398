import React, { useState } from "react";
import DeleteIcon from "../../../../styles/svg/delete.svg";
import { deleteCard } from "../../../../sevices/apiCards";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { useResponsive } from "../../../../hooks/useResponsive";
import { Divider, Typography } from "@mui/material";

const DeleteCard = ({
  cardId,
  boardId,
  kanbanCardDelete,
  setShowDetails,
  className="",
  // parentCardId,
  // isCompletedDependency,
  // disableCard,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useResponsive("down", "md");
  const queryClient = useQueryClient();

  const { mutate: deleteCardMutate } = useMutation({
    mutationFn: deleteCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`cards`, boardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`mineCards`],
      });
      queryClient.invalidateQueries({
        queryKey : ["watching"]
      })
      setLoading(false);
      toast.success(res.data.message);
      searchParams.delete("card_id");
      setSearchParams(searchParams);
      setShowDelete(false);
      setShowDetails(false);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
      setShowDelete(false);
    },
  });

  const callback = (e) => {
    e.stopPropagation();
    setLoading(true);
    deleteCardMutate(cardId);
  };

  const handleCardDelete = (e) => {
    e.stopPropagation();
    setShowDelete(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setShowDelete(false);
  };

  return (
    <span className={className}>
      {showDelete && (
        <ConfirmationDialog
          open={showDelete}
          handleClose={handleClose}
          message="Are you sure you want to delete this item? This action cannot be undone."
          callback={callback}
          loading={loading}
          btnTitle="Delete"
          title="Delete Task"
        />
      )}
      {isMobile ? (
        <>
          {/* <Divider className="mb-4" /> */}
          <Typography
            className={"workflow_steps d-flex align-items-center delete-card"}
            onClick={(e) => {
              e.stopPropagation();
              handleCardDelete(e);
            }}
            sx={
              {
                // opacity: (
                //   disableCard ? false : !isCompletedDependency && parentCardId
                // )
                //   ? 0.5
                //   : 1,
                // pointerEvents: (
                //   disableCard ? false : !isCompletedDependency && parentCardId
                // )
                //   ? "none"
                //   : "auto",
              }
            }
          >
            <img
              src={DeleteIcon}
              className=" delete-card-button mr-2"
              alt="Delete"
              style={{ height: "fit-content" }}
            />
            Delete Card
          </Typography>
        </>
      ) : (
        // <p>
        //   <img
        //     src={DeleteIcon}
        //     className={kanbanCardDelete ? "delete-card" : "delete-card-button"}
        //     onClick={(e) => {
        //       e.stopPropagation();
        //       handleCardDelete(e);
        //     }}
        //     alt="Delete"
        //     style={{ height: "fit-content" }}
        //   />
        //   Delete Card
        // </p>
        <img
          src={DeleteIcon}
          className={kanbanCardDelete ? "delete-card" : "delete-card-button"}
          onClick={(e) => {
            e.stopPropagation();
            handleCardDelete(e);
          }}
          alt="Delete"
          style={{ height: "fit-content" }}
        />
      )}
    </span>
  );
};

export default DeleteCard;
