import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, InputAdornment, Stack, Typography } from "@mui/material";

import { adminSettingCompanySchemas } from "../../../schemas/adminSettingPage";
import RHFTextField from "../../../components/RFH/RFHtextfield";
import {
  checkDomain,
  updateTenantInfo,
} from "../../../sevices/apiSystemSettings";
import useMutate from "../../../hooks/useMutate";

function CompanyInfoForm({ initialData }) {
  const [loading, setLoading] = useState(false);
  const { mutate } = useMutate(updateTenantInfo, [], () => setLoading(false));
  const domainUrl = window.location.hostname;

  const methods = useForm({
    resolver: yupResolver(adminSettingCompanySchemas),
    defaultValues: initialData,
  });

  const onSubmit = async (formData) => {
    const domainError = await validateDomain(formData.domain);
    if (domainError) {
      methods.setError("domain", {
        type: "manual",
        message: domainError,
      });
      return;
    }
    setLoading(true);

    if (initialData.domain === formData.domain) {
      mutate({
        company_name: formData.company_name,
      });
    } else {
      mutate(formData);
    }
  };

  const validateDomain = async (domain) => {
    if (/\s/.test(domain)) {
      return "Domain must not contain spaces.";
    }

    if (!/^[a-zA-Z0-9-]+$/.test(domain)) {
      return "Domain must not contain special characters.";
    }

    if (domain && domain !== initialData?.domain) {
      try {
        const res = await checkDomain({ domain });
        const { duplicate_domain } = res.data.data.responseData;

        if (duplicate_domain) {
          return "The domain you selected is already taken.";
        }
      } catch (err) {
        console.error(err);
        return "An error occurred while checking the domain.";
      }
    }

    return null; // No error
  };

  return (
    <FormProvider {...methods}>
      <div className="mt-32 form-section">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <Typography className="setting-heading mb-4 ml-0">
              Company Info
            </Typography>
            <Stack direction="row" spacing={2.5}>
              <RHFTextField
                name="company_name"
                label="Name"
                defaultPadding={false}
                sx={{ backgroundColor: "#fff" }}
              />
              <RHFTextField
                label="URL"
                name="domain"
                onBlur={(e) => validateDomain(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="domain-static">
                      .{domainUrl.split(".").splice(1).join(".")}
                    </InputAdornment>
                  ),
                }}
                defaultPadding={false}
                sx={{ backgroundColor: "#fff" }}
              />
              <RHFTextField
                name="email"
                label="E-mail address"
                defaultPadding={false}
                sx={{ backgroundColor: "#fff" }}
              />
            </Stack>
          </div>

          <Stack alignItems="end" className="mt-32">
            <Button
              variant="contained"
              type="submit"
              sx={{ padding: "8px 32px" }}
              className="theme-bg-color"
              disabled={loading}>
              Update
            </Button>
          </Stack>
        </form>
      </div>
    </FormProvider>
  );
}

export default CompanyInfoForm;
