import React, { useEffect, useRef, useState } from "react";
import "./description.scss";
import Button from "@mui/material/Button";
import ReactQuill from "react-quill";
import { useResponsive } from "../../../../../../hooks/useResponsive";
import { ReactQuillsProps } from "../../../../../../utils/helpers";

const Description = ({
  description,
  setTextEditer,
  accessToUpdate,
  descRef,
  isfocus,
  handleDescriptionSave,
}) => {
  const [textContent, setTextContent] = useState(description);
  const isMobile = useResponsive("down", "md");
  const isUnder321 = useResponsive("down", "400");

  const handleCancel = () => {
    setTextEditer(false);
  };

  useEffect(() => {
    setTextContent(description);
  }, [description]);

  return (
    <>
      <div className={isMobile ? "mobile-text-editor-box" : "text-editor-box"}>
        <ReactQuill
          readOnly={!accessToUpdate}
          modules={ReactQuillsProps.modules}
          formats={ReactQuillsProps.formats}
          onFocus={() => isfocus(true)}
          onBlur={() => {
            // Delay the handleSave function by 300 milliseconds
            isfocus(false);
            // handleDescriptionSave();
            setTimeout(() => {
              handleDescriptionSave();
            }, 300);
          }}
          ref={descRef}
          value={textContent}
          onChange={(value) => (accessToUpdate ? setTextContent(value) : "")}
          style={{ height: isMobile ? "80%" : "90%" }}
          className="react-quill-description"
        />
      </div>
      <div
        className={
          isUnder321 ? "mobile-textEditor-btn-div" : "text-editor-btn-div"
        }
      >
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleDescriptionSave();
          }}
          sx={{
            "&:disabled": {
              backgroundColor: "rgb(0,0,0,0.12) !important",
              color: "rgb(0,0,0,0.26)",
              cursor: "not-allowed",
            },
          }}
          disabled={!accessToUpdate}
          className="text-editor-btn1"
          variant="contained"
        >
          <div className="text-editor-btn-content">Save</div>
        </Button>
        <Button
          onClick={handleCancel}
          className="text-editor-btn2"
          variant="outlined"
        >
          <div className="text-editor-btn-content">Cancel</div>
        </Button>
      </div>
    </>
  );
};

export default Description;
