import { Controller, useFormContext } from "react-hook-form";

import { TextField } from "@mui/material";

// ----------------------------------------------------------------------

export default function RHFTextField({
  name,
  defaultPadding = true,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          fullWidth
          error={!!error}
          sx={
            defaultPadding && {
              "& .MuiInputBase-input": { padding: "9.5px 14px" },
            }
          }
          helperText={error ? error?.message : ""}
          {...field}
          onChange={(e) => {
            const trimmedValue = e.target.value.trimStart();
            field.onChange(trimmedValue); // Call RHF's onChange with trimmed value
          }}
          {...other}
        />
      )}
    />
  );
}
