import React, { useEffect, useState } from "react";
import MultiSelctCheckboxDropdown from "../../../../components/CheckboxMultiselect";
import { IconButton } from "@mui/material";
import downArrowImg from "../../../../styles/svg/downArrow.svg";
import { useSelector } from "react-redux";
import { useWorkflowById } from "../../../../utils/reactQuery/workflows";
import { useParams, useSearchParams } from "react-router-dom";
const AssignColumnHeader = () => {
  const [selectedNames, setSelectedNames] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const assignee = useSelector(
    (state) => state.listViewHeaderFilters.assigneeIds
  );
  const searchAssignee = searchParams.get("search");

  useEffect(() => {
    if (!isNaN(Number(searchAssignee))) {
      setSelectedNames([]);
    }
  }, [searchAssignee]);

  const newSearchParams = new URLSearchParams(searchParams);
  const handleSelectionChange = (newSelection) => {
    setSelectedNames(newSelection);

    if (newSelection.length > 0) {
      newSearchParams.set("assigneeFilter", newSelection.join(","));
    } else {
      newSearchParams.delete("assigneeFilter");
    }
    setSearchParams(newSearchParams);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearFilter = () => {
    setSelectedNames([]);
    newSearchParams.delete("assigneeFilter");
    setSearchParams(newSearchParams);
  };

  return (
    <>
      <button className="remove-default-button-style d-flex">
        Assignee
        <IconButton onClick={handleClick}>
          <img src={downArrowImg} alt="arrow" />
        </IconButton>
      </button>
      <MultiSelctCheckboxDropdown
        items={assignee}
        selectedItems={selectedNames}
        onSelectionChange={handleSelectionChange}
        isButton={true}
        anchorEl={anchorEl}
        valueKey="user_id"
        // displayKey="first_name"
        renderDisplay={(item) => `${item.first_name} ${item.last_name}`}
        handleClose={() => setAnchorEl(null)}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
};

export default AssignColumnHeader;
