import {
  Box,
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Search from "../Search";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Avatar from "../Avatar";
import UploadImage from "./uploadImage";
import { localStorageInstance } from "../../utils/localStorage";
import { hasImage } from "../../utils/helpers";
import LogoutIcon from "../../styles/svg/tabler_logout.svg";
import userIcon from "../../styles/svg/users-icon-2.svg";
import workflowIcon from "../../styles/svg/workflow.svg";
import missingStatusIcon from "../../styles/svg/missing-status-report-icon.svg";
import boardsIcon from "../../styles/svg/boards-icon.svg";
import { useDispatch } from "react-redux";
import { searchBoard } from "../../redux/SearchSlice";
import gearIcon from "../../styles/svg/worklogIcon 2.svg";
import _ from "lodash";
import { isAdmin } from "../../utils/constants";
import { changeExpand } from "../../redux/boardListSlice";
import { setSideBarDrawer } from "../../redux/Sidebar";
import MergeBoard from "../../pages/board/components/mergeBoard/MergeBoard";
import mergeBoardIcon from "../../styles/svg/mergeBoardIcon.svg";
import systemSettingIcon from "../../styles/svg/systemSettingIcon.svg";
import "./header.scss";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";
import { useCustomSearchParams } from "../../hooks/useCustomSearchParams";

export default function Profile() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector((state) => state.users.logInUser);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [openMergeBoard, setOpenMergeBoard] = useState(false);
  const userName =
    Object.hasOwn(user, "first_name") &&
    `${user.first_name[0]}${user.last_name[0]}`.toUpperCase();
  const [showLogoutDialog, setShowLogoutDialog] = useState(false)
  const [isLogoutLoading, setIsLogoutLoading] = useState(false)
  const [boardParam, setBoardParam, removeBoardParam] =
      useCustomSearchParams("find-brd");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = (action) => {
    setIsLogoutLoading(true)
      localStorageInstance.clear();
      queryClient.clear();
      navigate(`/`);
      setIsLogoutLoading(false)
  };

  const handleClose = (action) => {
      setAnchorEl(null);
  };

   useEffect(()=>{
      setInputValue(boardParam || '');
      dispatch(searchBoard(boardParam || ""));
      if (isAdmin && boardParam) {
        dispatch(changeExpand("allPanel"));
      }
    },[])

  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchBoard(value));
      setBoardParam("find-brd", value);
    }, 300) // 300ms delay
  ).current;

  const handleChange = (event) => {
    if (isAdmin) {
      dispatch(changeExpand("allPanel"));
    }
    const value = event.target.value.trimStart();
    setInputValue(value);
    debouncedSearch(value);
  };

  const handleNavigate = (boardNumber) => {
    const today = new Date();
    const yesterday = new Date();

    // Subtract 7 days from the current date
    yesterday.setDate(today.getDate() - 1);

    const date = {
      startDate: yesterday,
      endDate: yesterday,
      key: "selection",
    };

    const newSearchParams = new URLSearchParams({
      tab: "worklog",
      board: boardNumber,
      epic: null,
      missingStatus: JSON.stringify(date),
    });
    navigate(`/admin-report?${newSearchParams.toString()}`);
    setAnchorEl(null);
    dispatch(setSideBarDrawer(false));
  };

  const handleMergeBoard = () => {
    setOpenMergeBoard(true);
  };

  const handleSystemSetting = () => {
    navigate("/system-setting");
    setAnchorEl(null);
  };

  const handleLogoutDialog = ()=>{
    setShowLogoutDialog(true)
  }

  return (
    <>
    {
      showLogoutDialog && (
        <ConfirmationDialog
        open={showLogoutDialog}
        handleClose={()=> setShowLogoutDialog(false)}
        loading={isLogoutLoading}
        callback={handleLogout}
        message="Are you sure you want to log out?"
        btnTitle="Logout"
        title="Log Out"
      />
      )
    }
      {openMergeBoard && (
        <MergeBoard
          close={setOpenMergeBoard}
          open={openMergeBoard}
          admin={true}
        />
      )}
      <Grid item xs={5} className="">
        <div className="header-right">
          <Box mx={2} mb={2}>
            <Search
              searchText={inputValue}
              handleChange={handleChange}
              placeholder={"Find Board"}
              boardSearch='true'
            />
          </Box>
          {Object.hasOwn(user, "first_name") && (
            <Box mb={1}>
              <Stack direction="row" alignItems="center" ml={1}>
                <Button
                  fullWidth
                  id="user-profile-button"
                  aria-controls={open ? "user-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  // endIcon={
                  //   <KeyboardArrowDownIcon className="profile-down-arrow" />
                  // }
                  className=""
                >
                  <Avatar
                    title={`${user.first_name} ${user.last_name}`}
                    user_image={user?.user_image}
                    className="user-avatar"
                    style={
                      !hasImage(user?.user_image) && {
                        bgcolor: user?.profile_color?.background,
                        color: user?.profile_color?.text,
                      }
                    }
                  >
                    {userName}
                  </Avatar>
                  <Typography className="user-name">
                    {user?.first_name} {user.last_name}
                  </Typography>
                  <img
                    src={gearIcon}
                    style={{ width: "18px", color: "#1b3e61" }}
                    alt="ion"
                  />
                </Button>
              </Stack>

              <Menu
                id="account-menu"
                className="profile-header"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      left: 14,
                      width: 14,
                      height: 14,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              >
                <MenuItem disableRipple sx={{ cursor: "default" }}>
                  <Stack className="profile-container">
                    <UploadImage
                      user={user}
                      userName={userName}
                      setAnchorEl={setAnchorEl}
                    />

                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography className="profile-dropdown-name">
                            {user.first_name} {user.last_name}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography className="profile-dropdown-email">
                            {user.email}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    <img
                      src={LogoutIcon}
                      alt="logout"
                      className="logout-icon"
                      onClick={handleLogoutDialog}
                    />
                  </Stack>
                </MenuItem>
                {/* <Divider /> */}
                {isAdmin() && (
                  <MenuItem
                    onClick={() => {
                      navigate("/admin-user?users-status=active");
                      setAnchorEl(null);
                      dispatch(setSideBarDrawer(false));
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "26px !important" }}>
                      <img src={userIcon} alt="user" />
                    </ListItemIcon>
                    Users
                  </MenuItem>
                )}
                {isAdmin() && (
                  <MenuItem
                    onClick={() => {
                      navigate("/admin-board?boards-type=active");
                      setAnchorEl(null);
                      dispatch(setSideBarDrawer(false));
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "26px !important" }}>
                      <img src={boardsIcon} alt="board" />
                    </ListItemIcon>
                    Boards
                  </MenuItem>
                )}
                {isAdmin() && (
                  <MenuItem
                    onClick={() => {
                      navigate("/admin-workflow");
                      setAnchorEl(null);
                      dispatch(setSideBarDrawer(false));
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "26px !important" }}>
                      <img src={workflowIcon} alt="workflow" />
                    </ListItemIcon>
                    Workflow
                  </MenuItem>
                )}
                {isAdmin() && (
                  <MenuItem onClick={() => handleNavigate()}>
                    <ListItemIcon sx={{ minWidth: "26px !important" }}>
                      <img src={missingStatusIcon} alt="missing status" />
                    </ListItemIcon>
                    Missing Status Report
                  </MenuItem>
                )}
                {isAdmin() && (
                  <MenuItem onClick={handleMergeBoard}>
                    <ListItemIcon sx={{ minWidth: "26px !important" }}>
                      <img src={mergeBoardIcon} alt="missing status" />
                    </ListItemIcon>
                    Merge Board
                  </MenuItem>
                )}
                {isAdmin() && (
                  <MenuItem onClick={handleSystemSetting}>
                    <ListItemIcon sx={{ minWidth: "26px !important" }}>
                      <img src={systemSettingIcon} alt="system setting" />
                    </ListItemIcon>
                    System Setting
                  </MenuItem>
                )}
              </Menu>
            </Box>
          )}
        </div>
      </Grid>
    </>
  );
}
