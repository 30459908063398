import { Divider, ListItem, Tooltip, Typography } from "@mui/material";
import React from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import assignedToMe from "../../styles/svg/assignedToMe.svg";
import assignedToTeam from "../../styles/svg/assignedToTeam.svg";
import worklogIcon from "../../styles/svg/worklogIcon.svg";
import "./myTask.scss";
import { setTaskTab } from "../../redux/SearchSlice";
import watchingIcon from "../../styles/svg/watching-icon.svg";
import { setSideBarDrawer } from "../../redux/Sidebar";
import { getLastSevenDays } from "../../utils/helpers";

function MyTask() {
  const dispatch = useDispatch();
  const location = useLocation();

  const isActive = (query) => {
    const currentPath = location.pathname; // Get current path (without query)
    const currentSearch = location.search; // Get current query parameters
    const targetPath = `/my-task`; // Define the target path

    // Compare both path and query parameters

    return currentPath.includes(targetPath) && currentSearch.includes(query);
  };

  return (
    <>
      <ListItem
        className="menu-ListItem my-task-page"
        onClick={() => {
          dispatch(setTaskTab(true));
          dispatch(setSideBarDrawer(false));
        }}
      >
        <NavLink
          to={`/my-task?tab=mine`}
          className={({ isPending }) =>
            isActive(`?tab=mine`) ? "active" : isPending ? "pending" : ""
          }
        >
          <img
            src={assignedToMe}
            alt="not found"
            className="side-bar-icon"
            //   className="sidebar-board-owner"
          />
          <p className="my-task-name-text">Assigned to me</p>
        </NavLink>
      </ListItem>
      <Divider />
      <ListItem
        className="menu-ListItem my-task-page"
        onClick={() => {
          dispatch(setTaskTab(true));
          dispatch(setSideBarDrawer(false));
        }}
      >
        <NavLink
          to={`/watching`}
          className={({ isPending }) =>
            location.pathname === "/watching"
              ? "active"
              : isPending
              ? "pending"
              : ""
          }
        >
          <img
            src={watchingIcon}
            alt="not found"
            className="side-bar-icon"
            //   className="sidebar-board-owner"
          />
          <p className="my-task-name-text">Watching</p>
        </NavLink>
      </ListItem>
      <Divider />
      <ListItem
        className="menu-ListItem my-task-page"
        onClick={() => {
          dispatch(setTaskTab(true));
          dispatch(setSideBarDrawer(false));
        }}
      >
        <NavLink
          to={`/my-task?tab=team`}
          className={({ isPending }) =>
            isActive(`?tab=team`) ? "active" : isPending ? "pending" : ""
          }
        >
          <img
            src={assignedToTeam}
            alt="not found"
            className="side-bar-icon"
            //   className="sidebar-board-owner"
          />
          <p className="my-task-name-text">Assigned to Team</p>
        </NavLink>
      </ListItem>
      <Divider />

      <ListItem
        className="menu-ListItem my-task-page"
        onClick={() => {
          dispatch(setTaskTab(true));
          dispatch(setSideBarDrawer(false));
        }}
      >
        <NavLink
          to={`/my-task?tab=worklog&date=${getLastSevenDays()}`}
          className={({ isPending }) =>
            isActive(`?tab=worklog`) ? "active" : isPending ? "pending" : ""
          }
        >
          <img
            src={worklogIcon}
            alt="not found"
            className="side-bar-icon"
            //   className="sidebar-board-owner"
          />
          <p className="my-task-name-text">Worklogs</p>
        </NavLink>
      </ListItem>
    </>
  );
}

export default MyTask;
