import * as yup from "yup";
import { removeHtmlTagsAndEntities } from "../utils/helpers";
export const schema = yup
  .object({
    work_performed: yup
      .string()
      .required("Work description is required")
      .test(
        "no-only-spaces",
        "Work description is required",
        (value) => removeHtmlTagsAndEntities(value).trim().length > 0
      ),
    hours_spent: yup
      .number()
      .typeError("Work hours must be number")
      .required("Work Hours is required"),
    date_time: yup.date().required("Time is required"),
  })
  .required();
