import React, { useState } from "react";
import {
  AvatarGroup,
  Box,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Avatar as Image } from "@mui/material";
import { useDispatch } from "react-redux";

import { setMyTaskUser } from "../../../../redux/SearchSlice";
import SearchBoard from "../../components/Filters/SearchBoard";
import {
  setAllGroupUser,
  setGroupUser,
} from "../../../../redux/TaskGroupingSlice";
import { useResponsive } from "../../../../hooks/useResponsive";
import { useUsers } from "../../../../utils/reactQuery/users";
import Avatar from "../../../../components/Avatar";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import { createAvtarName } from "../../../../utils/helpers";
import { isAdmin } from "../../../../utils/constants";

function User({ user }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const isUnassignedChecked = searchParams.get("isUnassigned") === "true";
  const isMobile = useResponsive("down", "md");
  const open = Boolean(anchorEl);
  const { users } = useUsers();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (action) => {
    setAnchorEl(null);
    setSearchQuery("");
  };

  const filteredItems = users
    ? searchQuery
      ? users.data.data.responseData.filter((item) =>
          item.first_name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : users.data.data.responseData
    : [];

  const allUser = filteredItems.filter((el) => el.active);

  const selectAll = () => {
    dispatch(
      setAllGroupUser(
        user.length == allUser.length
          ? []
          : [{ team: "Multiple", users: allUser }]
      )
    );
  };

  const selectUnassigned = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (isUnassignedChecked) {
      newSearchParams.delete("isUnassigned");
    } else {
      newSearchParams.set("isUnassigned", "true");
    }

    setSearchParams(newSearchParams);
  };

  return (
    <div className="d-flex align-items-center ">
      {isMobile ? (
        <Box sx={{ width: "100%" }}>
          <div onKeyDown={(e) => e.stopPropagation()}>
            <SearchBoard
              onSearch={(val) => setSearchQuery(val)}
              searchQuery={searchQuery}
            />
          </div>

          {!searchQuery && (
            <>
              <MenuItem
                onClick={selectUnassigned}
                sx={{
                  paddingLeft: "0px",
                  minHeight: "40px",
                  paddingBottom: "0px",
                }}
              >
                <label class="custom-checkbox">
                  <input
                    type="checkbox"
                    id={`board-list-my-task-checkbox-all-board`}
                    checked={isUnassignedChecked}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  />
                  <span class="checkmark"></span>
                  Select unassigned tasks
                </label>
              </MenuItem>

              <MenuItem
                onClick={selectAll}
                sx={{
                  paddingLeft: "0px",
                  minHeight: "40px",
                  paddingTop: "0px",
                }}
              >
                <label class="custom-checkbox">
                  <input
                    type="checkbox"
                    id={`board-list-my-task-checkbox-all-board`}
                    checked={user.length == allUser.length}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  />
                  <span class="checkmark"></span>
                  Select All Users
                </label>
              </MenuItem>
            </>
          )}

          {filteredItems
            .filter((el) => el.active)
            .sort(function (a, b) {
              return a.first_name.localeCompare(b.first_name);
            })
            .sort(
              (a, b) =>
                user.some((element) => element.user_id == b.user_id) -
                user.some((element) => element.user_id == a.user_id)
            )
            .map((el) => {
              return (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  onClick={() => {
                    dispatch(setGroupUser(el));
                    setSearchQuery("");
                  }}
                  bgcolor={
                    user.some((element) => element.user_id == el.user_id)
                      ? "#C5DCF3"
                      : "rgb(242, 244, 246)"
                  }
                  p={"5px"}
                  borderRadius={1}
                  fullWidth
                  mb={1}
                >
                  <Avatar
                    key={`user-avtar-${el.user_id}`}
                    username={`${el.first_name} ${el.last_name}`}
                    user_image={el.user_image}
                    title={`${el.first_name} ${el.last_name}`}
                    className="user-avtar-dropdown user-avatar mr-8"
                    style={{
                      bgcolor: el?.profile_color?.background,
                      color: el?.profile_color?.text,
                    }}
                  >
                    {createAvtarName(`${el.first_name} ${el.last_name}`)}
                  </Avatar>
                  <Typography fontSize={13} fontWeight={400}>
                    {`${el.first_name} ${el.last_name}`}{" "}
                  </Typography>
                  {/* <Checkbox
              checked={ele.user_id == filterUserId}
              sx={{ padding: "3px" }}
            /> */}
                </Stack>
              );
            })}
        </Box>
      ) : (
        <div>
          {/* {openDialog && (
          <ConfirmationDialog
            open={openDialog}
            handleClose={() => setOpenDialog(false)}
            message={`Are you sure to assign ${userId.username} as Board Owner?`}
            //   callback={callback}
            loading={loading}
            btnTitle={"Assign"}
            title={"Assign Board Owner"}
          />
        )} */}
          <div
            onClick={handleClick}
            // className={
            //   boardName?.data?.responseData?.is_owner || isAdmin()
            //     ? "board-owner-avtar-click"
            //     : ""
            // }
            className="cursor-pointer"
          >
            <AvatarGroup max={4} className="avatar-group">
              {user.map((el) => (
                <Avatar
                  // key={`user-avtar-${el.user_id}`}
                  username={`${el.first_name} ${el.last_name}`}
                  user_image={el.user_image}
                  title={`${el.first_name} ${el.last_name}`}
                  className="user-avtar-dropdown user-avatar"
                  style={{
                    bgcolor: el?.profile_color?.background,
                    color: el?.profile_color?.text,
                  }}
                >
                  {createAvtarName(`${el.first_name} ${el.last_name}` || "")}
                </Avatar>
              ))}
              {user.length < 1 && <Image />}
            </AvatarGroup>
          </div>

          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,

                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 14,
                  width: 14,
                  height: 14,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            // anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            className="assign-board-owner-list"
          >
            <div onKeyDown={(e) => e.stopPropagation()}>
              <SearchBoard
                onSearch={(val) => setSearchQuery(val)}
                searchQuery={searchQuery}
              />
            </div>
            {!searchQuery && (
              <>
                <MenuItem onClick={selectUnassigned}>
                  <label class="custom-checkbox">
                    <input
                      type="checkbox"
                      id={`board-list-my-task-checkbox-all-board`}
                      checked={isUnassignedChecked}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                    <span class="checkmark"></span>
                    Select unassigned tasks
                  </label>
                </MenuItem>

                <MenuItem onClick={selectAll}>
                  <label class="custom-checkbox">
                    <input
                      type="checkbox"
                      id={`board-list-my-task-checkbox-all-board`}
                      checked={user.length == allUser.length}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                    <span class="checkmark"></span>
                    Select All Users
                  </label>
                </MenuItem>
              </>
            )}
            {filteredItems
              .filter((el) => el.active)
              .sort(function (a, b) {
                return a.first_name.localeCompare(b.first_name);
              })
              .sort(
                (a, b) =>
                  user.some((element) => element.user_id == b.user_id) -
                  user.some((element) => element.user_id == a.user_id)
              )
              .map((el) => {
                return (
                  <MenuItem
                    key={`user-avtar-dropdown-admin-assign-${el.user_id}`}
                    onClick={() => {
                      dispatch(setGroupUser(el));
                      setSearchQuery("");
                    }}
                  >
                    <label class="custom-checkbox">
                      <input
                        type="checkbox"
                        id={`user-check-box-${el.user_id}`}
                        // checked={el.user_id == user.user_id}
                        checked={user.some(
                          (element) => element.user_id == el.user_id
                        )}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      />
                      <span class="checkmark"></span>

                      <Avatar
                        key={`user-avtar-${el.user_id}`}
                        username={`${el.first_name} ${el.last_name}`}
                        user_image={el.user_image}
                        title={`${el.first_name} ${el.last_name}`}
                        className="user-avtar-dropdown user-avatar mr-8"
                        style={{
                          bgcolor: el?.profile_color?.background,
                          color: el?.profile_color?.text,
                        }}
                      >
                        {createAvtarName(`${el.first_name} ${el.last_name}`)}
                      </Avatar>

                      {`${el.first_name} ${el.last_name}`}
                    </label>
                  </MenuItem>
                );
              })}
          </Menu>
        </div>
      )}
      {!isMobile && (
        <div>
          <p className="select-user">{user.length < 1 ? "Select User" : ""}</p>
        </div>
      )}
    </div>
  );
}

export default User;
