import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, MenuItem, Stack } from "@mui/material";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Field from "../../../../../components/field/index";
import { useUsers } from "../../../../../utils/reactQuery/users";
import { assignUser } from "../../../../../sevices/apiBoards";
import { useParams } from "react-router-dom";
import Loader from "../../../../../components/Loader";

function Form({ onClose, boardUsers, isMobile }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { isLoading: isUserLoading, users, isError: isUserError } = useUsers();
  const [userOption, setUserOption] = useState([]);
  const [apiError, setApiError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (!isUserLoading && !isUserError) {
      let filteredArray = users.data.data.responseData
        .filter((el) => el.active)
        .sort(function (a, b) {
          return a.first_name.localeCompare(b.first_name);
        });
      setUserOption(
        filteredArray.map((el) => {
          return {
            label: `${el.first_name} ${el.last_name} (${el.email})`,
            value: el.user_id,
          };
        })
      );
    }
  }, [users, isUserLoading]);

  const roles = [
    {
      label: "User",
      value: "User",
    },
    {
      label: "Board Admin",
      value: "Board Admin",
    },
  ];

  function removeEmailsFromUserOptions(userOption) {
    if (!Array.isArray(userOption)) {
      throw new TypeError("Expected an array of user options");
    }

    return userOption.map((option) => {
      if (typeof option.label !== "string") {
        throw new TypeError("Each option must have a string `label` field");
      }

      // Regular expression to match and capture the email address within parentheses
      const emailRegex = /\s\([^)]*\)$/;

      // Remove email address and parentheses from the label
      const newLabel = option.label.replace(emailRegex, "").trim();

      // Return a new object with the updated label
      return {
        ...option,
        label: newLabel,
      };
    });
  }

  const updatedUserOptions = removeEmailsFromUserOptions(userOption).filter(
    (item) => !boardUsers?.some((el) => el.user_id == item.value)
  );

  // React Hook Form
  const { register, handleSubmit, reset, getValues, formState, control } =
    useForm({
      defaultValues: {
        board_id: id,
      },
      // resolver: yupResolver(schema),
    });
  const { errors } = formState;

  const { mutate: assignUserMutate } = useMutation({
    mutationFn: assignUser,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`boardUsers`, id],
      });
      queryClient.invalidateQueries({
        queryKey: [`boardName`, id],
      });
      queryClient.invalidateQueries({
        queryKey: [`boards`],
      });
      setLoading(false);
      //    reset();
      toast.success(res.data.message);
      //    onClose();
      onClose();
    },
    onError: (error) => {
      console.log(error);
      //   console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
      //   handleClose();
    },
  });

  const submit = (data) => {
    /**
     * Create Board
     */

    setApiError("");
    setLoading(true);

    assignUserMutate(data);
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(submit)}
        noValidate
        sx={{
          // marginTop: 4,
          padding: isMobile ? "8px 5px !important" : "8px",
          // borderRadius: "16px",
          // boxShadow: "0px 4px 30px 0px rgba(27, 62, 97, 0.06)",
        }}
      >
        <Stack spacing={3}>
          {apiError && <Alert severity="error">{apiError}</Alert>}
          <Controller
            name="user_id"
            control={control}
            rules={{ required: "User is required" }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={updatedUserOptions}
                getOptionLabel={(option) => option.label || ""}
                onChange={(_, value) => {
                  field.onChange(value?.value || ""); // Update form state with selected option's value
                }}
                value={
                  updatedUserOptions.find(
                    (option) => option.value === field.value
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User"
                    required
                    error={Boolean(errors?.user_id)}
                    helperText={errors?.user_id?.message}
                  />
                )}
              />
            )}
          />
          <Field
            label={"Role"}
            required
            id="user_role"
            name="user_role"
            error={errors?.user_role ? true : false}
            helperText={errors?.user_role && errors.user_role.message}
            select
            className={isMobile ? "padding-top-16" : ""}
            {...register("user_role")}
          >
            {roles.map((option) => (
              <MenuItem
                key={`workflow-option-${option.value}`}
                id={`workflow-option-id-${option.value}`}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Field>

          <Box
            sx={{
              alignSelf: "end",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "#1B3E61",
                mr: "10px",
                fontSize: isMobile ? "14px" : "16px",
                padding: isMobile ? "8px 16px" : "10px 16px",
                fontWeight: "600",
                textTransform: "capitalize",
                minWidth: isMobile ? "107px" : "130px",
              }}
              disabled={loading}
            >
              Add
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: "#1B3E61",
                border: "1px solid #1B3E61",
                textTransform: "capitalize",
                fontSize: isMobile ? "14px" : "16px",
                padding: isMobile ? "8px 16px" : "10px 16px",
                fontWeight: "600",
                opacity: "0.6",
                minWidth: isMobile ? "107px" : "130px",
              }}
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
}

export default Form;
