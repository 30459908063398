import { useQuery } from "@tanstack/react-query";
import {
  getAdminWorkflow,
  getGenericWorkflowStatus,
  getUserWorkflows,
  getWorkflowById,
  getWorkflows,
  getWorkflowStepNameByWorkflowStepId,
} from "../../sevices/apiWorkflows";

export function useWorkflow() {
  const {
    isLoading,
    data: workflows,
    error,
    isError,
  } = useQuery({
    queryKey: ["workflows"],
    queryFn: getWorkflows,
  });

  return { isLoading, error, workflows, isError };
}

export function useWorkflowById(id, board_id) {
  const { isLoading, data, error, isError, refetch } = useQuery({
    queryKey: [`workflow`, board_id],
    queryFn: () => getWorkflowById(id, board_id),
    enabled:  (id !="undefined" && !!id),
  });

  return { isLoading, error, data, isError, refetch };
}

export function useAdminWorkflow() {
  const {
    isLoading,
    data: adminWorkflows,
    error,
    isError,
  } = useQuery({
    queryKey: ["adminWorkflow"],
    queryFn: getAdminWorkflow,
  });

  return { isLoading, error, adminWorkflows, isError };
}

export function useUserWorkflowList() {
  const {
    isLoading,
    data: userWorkflows,
    error,
    isError,
  } = useQuery({
    queryKey: ["userWorkflowList"],
    queryFn: getUserWorkflows,
  });

  return { isLoading, error, userWorkflows, isError };
}

export function useGenericWorkflowStatus() {
  const {
    isLoading,
    data: genericWorkflowStatus,
    error,
    isError,
  } = useQuery({
    queryKey: ["getGenericWorkflowStatus"],
    queryFn: getGenericWorkflowStatus,
  });

  return { isLoading, error, genericWorkflowStatus, isError };
}

export function useWorkflowStepNameByWorkflowStepId(
  workflow_step_id,
  board_id
) {
  const {
    isLoading,
    data: workflowStepName,
    error,
    isError,
  } = useQuery({
    queryKey: [`workflow_step_name`, workflow_step_id],
    queryFn: () =>
      getWorkflowStepNameByWorkflowStepId(workflow_step_id, board_id),
    enabled: !!(workflow_step_id && board_id)
  });

  return { isLoading, error, workflowStepName, isError };
}
