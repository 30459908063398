import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { adminSettingLogoSchemas } from "../../../schemas/adminSettingPage";
import RHFFileInputButton from "../../../components/RFH/RFHfileInput";
import { getAPIBaseUrl } from "../../../utils/constants";
import { useAssets } from "../../../utils/reactQuery/domainAssets";
import useMutate from "../../../hooks/useMutate";
import { updateTenantLogos } from "../../../sevices/apiSystemSettings";
import Pensil from "../../../styles/svg/pensil.svg";
import { useQueryClient } from "@tanstack/react-query";
import TenantSiteName from "../../../components/TenantSiteName";

function BrandingLogo() {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const { mutate } = useMutate(updateTenantLogos, [], () => {
    setLoading(false);
    queryClient.invalidateQueries({
      queryKey: ["logo"],
    });
  });
  const methods = useForm({
    resolver: yupResolver(adminSettingLogoSchemas),
  });
  const { watch } = methods;
  const logoBlob = watch("logo");
  const feviconBlob = watch("favicon");
  const apiUrl = getAPIBaseUrl();
  const { logo, icon } = useAssets();
  const onSubmit = (formData) => {
    setLoading(true);
    mutate(formData);
  };

  const formValues = methods.watch();

  // Function to check if any field has a value
  const isFieldFilled = (name) => {
    return (
      formValues[name] !== undefined &&
      formValues[name] !== "" &&
      formValues[name] !== null
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <div className="mt-32 form-section  ">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div>
              <Typography className="setting-heading mb-4 ml-0">
                Branding
              </Typography>
              {/* <Stack
                direction="row"
                justifyContent="space-between"
                spacing={2.5}
              >
                <div className="flex-grow-1">
                  <Typography>Logo</Typography>
                  <Stack direction="row" className="branding-cont">
                    <Stack className="branding-icon-cont">
                      <img
                        src={
                          logoBlob
                            ? URL.createObjectURL(logoBlob)
                            : `${apiUrl}/${logo}`
                        }
                        alt="logo"
                      />
                    </Stack>
                    <div className="mt-11">
                      <RHFFileInputButton
                        name="logo"
                        label="Upload"
                        startIcon={<CloudUploadIcon />}
                      />
                      <Typography className="mt-4">
                        Please upload image size less than 50KB.
                      </Typography>
                    </div>
                  </Stack>
                </div>
                <div className="flex-grow-1">
                  <Typography>Favicon</Typography>
                  <Stack direction="row" className="branding-cont">
                    <Stack className="branding-icon-cont">
                      <img
                        src={
                          feviconBlob
                            ? URL.createObjectURL(feviconBlob)
                            : `${apiUrl}/${icon}`
                        }
                        alt="logo"
                        style={{ height: "24px", margin: "auto" }}
                      />
                    </Stack>
                    <div className="mt-11">
                      <RHFFileInputButton
                        name="favicon"
                        label="Upload"
                        startIcon={<CloudUploadIcon />}
                      />
                      <Typography className="mt-4">
                        Upload a 30x30 pixel ICO,PNG to display in browser tab.{" "}
                      </Typography>
                    </div>
                  </Stack>
                </div>
              </Stack> */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <div className="flex-grow-1">
                    <Typography>Logo</Typography>
                    <Stack direction="row" className="branding-cont">
                      <Stack className="branding-icon-cont">
                        {logo || logoBlob ? (
                          <img
                            src={
                              logoBlob
                                ? URL.createObjectURL(logoBlob)
                                : `${apiUrl}/${logo}`
                            }
                            alt="logo"
                          />
                        ) : (
                          <TenantSiteName />
                        )}
                        {logo ? (
                          ""
                        ) : (
                          <Box className="hover-button">
                            <RHFFileInputButton
                              name="logo"
                              label=""
                              showError={false}
                              startIcon={<CloudUploadIcon />}
                              accept="image/*"></RHFFileInputButton>
                          </Box>
                        )}
                      </Stack>
                      <div className="logos-pensil">
                        <RHFFileInputButton
                          name="logo"
                          label=""
                          showError={false}
                          accept="image/*">
                          <img src={Pensil} className="cursor-pointer" />
                        </RHFFileInputButton>
                      </div>
                    </Stack>
                  </div>
                  <div className="mt-1">
                    {methods.formState.errors.logo && (
                      <FormHelperText error={true}>
                        {methods.formState.errors.logo.message}
                      </FormHelperText>
                    )}
                    <Typography className="logo-note">
                      Please upload image size less than 200KB.
                    </Typography>
                    <div>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ padding: "8px 32px" }}
                        className="theme-bg-color"
                        disabled={loading || !isFieldFilled("logo")}>
                        Save
                      </Button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="flex-grow-1">
                    <Typography>Favicon</Typography>
                    <Stack direction="row" className="branding-fav-cont">
                      <Stack className="branding-favicon-cont">
                        <img
                          src={
                            feviconBlob
                              ? URL.createObjectURL(feviconBlob)
                              : `${apiUrl}/${icon}`
                          }
                          alt="logo"
                        />
                      </Stack>
                      <div className="logos-pensil">
                        <RHFFileInputButton
                          name="favicon"
                          label=""
                          showError={false}
                          accept="image/*">
                          <img src={Pensil} className="cursor-pointer" />
                        </RHFFileInputButton>
                      </div>
                    </Stack>
                  </div>
                  <div className="mt-1">
                    {methods.formState.errors.favicon && (
                      <FormHelperText error={true}>
                        {methods.formState.errors.favicon.message}
                      </FormHelperText>
                    )}
                    <Typography className="logo-note">
                      Upload a 30x30 pixel ICO,PNG to display in browser tab.{" "}
                    </Typography>
                    <div>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ padding: "8px 32px" }}
                        className="theme-bg-color"
                        disabled={loading || !isFieldFilled("favicon")}>
                        Save
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </FormProvider>
    </>
  );
}

export default BrandingLogo;
