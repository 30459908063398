import React, { useEffect, useState } from "react";
import { useWorkflowById } from "../../../../utils/reactQuery/workflows";
import Loader from "../../../../components/Loader";
import Kanban from "./Kanban";
import { getMyCards } from "../../../../sevices/apiCards";
import { useDispatch, useSelector } from "react-redux";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useMineCards } from "../../../../utils/reactQuery/cards";
import { setAllGroupUser } from "../../../../redux/TaskGroupingSlice";
import { addAllBoard } from "../../../../redux/SearchSlice";
import { useSearchParams } from "react-router-dom";
import OpenCardDetail from "../../../board/components/Kanban/openCardDetail";

function Workflow() {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector((state) => state.search.myTaskUser);
  const checkedList = useSelector((state) => state.search.boardSearch);
  const epicSearch = useSelector((state) => state.search.epicSearch);
  const [reporterCards] = useCustomSearchParams("reporter");
  const workflow_id = searchParams.get("workflow_id");
  const { data } = useWorkflowById(workflow_id, workflow_id);
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState([]);
  const {
    data: mineCards,
    isLoading,
    refetch,
    isRefetching,
  } = useMineCards(
    currentUser,
    checkedList,
    epicSearch,
    reporterCards,
    workflow_id
  );

  useEffect(() => {
    refetch();
  }, [user, checkedList, epicSearch, reporterCards]);

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, [user]);

  useEffect(() => {
    return () => {
      dispatch(setAllGroupUser([]));
      dispatch(addAllBoard([]));
    };
  }, []);

  return (
    <>
    <OpenCardDetail/>
      {data && !isLoading ? (
        <Kanban
          cards={mineCards?.data.data.responseData||[]}
          workflowSteps={data.data.data.responseData
            .filter((el) => !el.is_completed)
            .sort((a, b) => a.step_sequence - b.step_sequence)}
        />
      ) : (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
    </>
  );
}

export default Workflow;
