import { useResponsive } from "../../../../hooks/useResponsive";
import MobileKanban from "../../../board/components/Kanban/mobileKanban";
import KanbanBoard from "./KanbanBoard";
// import "./kanban.scss";

function Kanban({ workflowSteps, cards, callback }) {
  const isMobile = useResponsive("down", "md");
  const style = {
    paddingTop: isMobile ? "0px" : "30px",
    // paddingTop: "65px",
  };

  return (
    <div style={style}>
      {isMobile ? (
        <MobileKanban workflow={workflowSteps} cards={cards} />
      ) : (
        <KanbanBoard
          workflowSteps={workflowSteps}
          cards={cards}
          callback={callback}
        />
      )}
    </div>
  );
}

export default Kanban;
