import { Navigate, Outlet, useLocation } from "react-router-dom";
import { localStorageInstance } from "../../utils/localStorage";
import Loader from "../../components/Loader";
import { useAssets } from "../../utils/reactQuery/domainAssets";

const Protected = () => {
  const location = useLocation();
  const auth =
    localStorageInstance.getItem("token") &&
    localStorageInstance.getItem("userId");

  if (location.pathname === "/resetpassword/") {
    localStorageInstance.clear();
    return <Outlet />;
  }

  return auth ? <Navigate to="/board" /> : <Outlet />;
};

export default Protected;
