import { Button, Menu, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import DownArrow from "../../../../styles/svg/downArrow.svg";
import { useUserBoardEpic } from "../../../../utils/reactQuery/epics";
import { useSelector } from "react-redux";
import { addAllEpic, addEpic } from "../../../../redux/SearchSlice";
import { useDispatch } from "react-redux";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import apicIcon from "../../../../styles/svg/updt_milestone.svg";
import { useResponsive } from "../../../../hooks/useResponsive";
function Epics() {
  const dispatch = useDispatch();
  const checkedList = useSelector((state) => state.search.epicSearch);
  const board_id = useSelector((state) => state.search.boardSearch)[0];
  const { epic } = useUserBoardEpic(board_id);
  const epicResponseData = epic?.data?.data?.responseData || [];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const isMobile = useResponsive("down", "md");

  const [epics, updateSearchParams, removeSearchParam] =
    useCustomSearchParams("epic");

  useEffect(() => {
    if ((epics, epic)) {
      const urlEpic = epics?.split(",").map((el) => el * 1);
      if (urlEpic)
        dispatch(
          addAllEpic(
            epicResponseData
              ?.filter((el) => urlEpic.includes(el.epic_id))
              .map((el) => el.epic_id)
          )
        );
    }
  }, [epic]);

  const selectEpic = (event, id) => {
    dispatch(addEpic(id));
  };
  const selectAll = (e) => {
    if (e.target.checked) {
      dispatch(addAllEpic(epicResponseData?.map((el) => el.epic_id)));
    } else {
      dispatch(addAllEpic([]));
    }
  };

  return (
    <div className="mr-2">
      {isMobile ? (
        <>
          <div className="milestone_mbl">
            <p className="filter-text">Select Milestone:</p>
            {epic && epicResponseData?.length > 0 && (
              <MenuItem className="custom-border">
                <label class="custom-checkbox">
                  <input
                    type="checkbox"
                    id={`board-list-my-task-checkbox-all-board`}
                    onChange={selectAll}
                  />
                  <span class="checkmark"></span>
                  <p className="board-name-filter">Select all milestone</p>
                </label>
              </MenuItem>
            )}
            {epic &&
              epicResponseData?.length > 0 &&
              epicResponseData?.map((el, idx) => {
                return (
                  <MenuItem
                    key={`my-task-epic-${idx}`}
                    className="custom-border"
                    onClick={(event) => selectEpic(event, el.epic_id)}
                  >
                    <label class="custom-checkbox">
                      <input
                        type="checkbox"
                        id={`board-list-my-task-checkbox-all-board`}
                        checked={checkedList.includes(el.epic_id)}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      />
                      <span class="checkmark"></span>
                      <p className="board-name-filter">{el.epic_name}</p>
                      {/* <p>
                    {el.epic_id}
                  </p> */}
                    </label>
                  </MenuItem>
                );
              })}
            {epic && !epicResponseData?.length && (
              <MenuItem>No milestone found</MenuItem>
            )}
          </div>
        </>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          id="epics-board-dropdown"
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<img src={DownArrow} />}
          // className="white-button epics-btn"
          className="filter-btn white-button"
          sx={{
            height: "38px",
            "&:hover": {
              outline: "none",
              backgroundColor: "rgb(240 249 254)",
              border: "1px solid #1b3e61",
            },
          }}
        >
          <img
            src={apicIcon}
            className="header-filter-icon mr-8"
            alt="not found"
          />
          {/* <img src={apicIcon} className="header-filter-icon" alt="not found" />{" "} */}
          <p className="header-action-button-text mr-1">Milestones</p>
        </Button>
      )}
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "epics-board-dropdown",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        className="assign-board-owner-list"
      >
        {epic && epicResponseData?.length > 0 && (
          <MenuItem>
            <label class="custom-checkbox">
              <input
                type="checkbox"
                id={`board-list-my-task-checkbox-all-board`}
                onChange={selectAll}
              />
              <span class="checkmark"></span>
              Select all milestone
            </label>
          </MenuItem>
        )}
        {epic &&
          epicResponseData?.length > 0 &&
          epicResponseData?.map((el, idx) => {
            return (
              <MenuItem
                key={`my-task-epic-${idx}`}
                className="custom-border"
                onClick={(event) => selectEpic(event, el.epic_id)}
              >
                <label class="custom-checkbox">
                  <input
                    type="checkbox"
                    id={`board-list-my-task-checkbox-all-board`}
                    checked={checkedList.includes(el.epic_id)}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  />
                  <span class="checkmark"></span>
                  <p className="board-name-filter">{el.epic_name}</p>
                  {/* <p>
                    {el.epic_id}
                  </p> */}
                </label>
              </MenuItem>
            );
          })}
        {epic && !epicResponseData?.length && (
          <MenuItem>No milestone found</MenuItem>
        )}
      </Menu>
    </div>
  );
}
export default Epics;
