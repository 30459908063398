import React from "react";
import KanbanColumn from "./KanbanColumn";

function KanbanContainor({ workflowSteps, boardName, projects }) {
  const priorityOrder = {
    Critical: 1,
    High: 2,
    Medium: 3,
    Low: 4,
  };

  const groupedCardByWorkFlowSteps = projects.reduce((acc, item) => {
    const stepId = item?.card?.workflow_step_id;
    if (!acc[stepId]) {
      acc[stepId] = [];
    }
    acc[stepId].push(item);
    return acc;
  }, {});

  return (
    <div className="board-card-scroll-btn d-flex ">
      {workflowSteps.map((column) => {
        const filteredProjects =
          groupedCardByWorkFlowSteps ? groupedCardByWorkFlowSteps[
            column?.workflow_step_id
          ]?.sort(
            (a, b) =>
              priorityOrder[a?.card?.priority] -
              priorityOrder[b?.card?.priority]
          ) : [];
        return (
          <KanbanColumn
            key={column?.workflow_step_id}
            boardName={boardName}
            id={column?.workflow_step_id}
            name={column?.status}
            stage={column?.workflow_step_id}
            projects={filteredProjects || []}
          // onDragEnter={handleOnDragEnter}
          // onDragEnd={handleOnDragEnd}
          />
        );
      })}

    </div>
  );
}

export default KanbanContainor;
