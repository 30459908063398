import { useQuery } from "@tanstack/react-query";
import {
  getBoards,
  getBoardName,
  getAttachments,
  getDownloadAttachment,
  getLebels,
  getBoardsWithSameWorkFlowId,
} from "../../sevices/apiBoards";
import { getCommentsBoard } from "../../sevices/apiComment";

export function useBoards() {
  const {
    isLoading,
    data: boards,
    error,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["boards"],
    queryFn: getBoards,
  });

  return { isLoading, error, boards, isError, refetch };
}

export function useBoardName(id) {
  const {
    isLoading,
    data: boardName,
    error,
    isError,
  } = useQuery({
    queryKey: ["boardName", id],
    queryFn: () => getBoardName(id),
    enabled: !!id,
  });

  return { isLoading, error, boardName, isError };
}

export function useAttachments(boardId) {
  const {
    isLoading,
    data: attachments,
    error,
    isError,
    refetch,
  } = useQuery({
    queryKey: [`attachments-${boardId}`],
    queryFn: () => getAttachments(boardId),
  });
  return { isLoading, error, attachments, isError, refetch };
}

export function useDownloadAttachment(attachmentId) {
  const {
    isLoading,
    data: downloadAttachment,
    error,
    isError,
  } = useQuery({
    queryKey: ["AttachmentDownload", attachmentId],
    queryFn: () => getDownloadAttachment(attachmentId),
  });
  return { isLoading, error, downloadAttachment, isError };
}

export function useLebels(boardId) {
  const {
    isLoading,
    data: lebels,
    error,
    isError,
  } = useQuery({
    queryKey: ["lebels", boardId],
    queryFn: () => getLebels(boardId),
  });
  return { isLoading, error, lebels, isError };
}

export function useBoardsWithSameWorkflow(boradId) {
  const {
    isLoading,
    data: board,
    error,
    isError,
  } = useQuery({
    queryKey: ["boardNameWithSameWorkflow", boradId],
    queryFn: () => getBoardsWithSameWorkFlowId(boradId),
  });

  return { isLoading, error, board, isError };
}

export function useCommentBoard(id, milestoneId) {
  const {
    isLoading,
    data: commentBoard,
    error,
    isError,
  } = useQuery({
    queryKey: [`commentBoard`, id, milestoneId],
    queryFn: () => getCommentsBoard(id, milestoneId),
  });

  return { isLoading, error, commentBoard, isError };
}
