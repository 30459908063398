import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { internalInviteSchema, schema } from "../../../../schemas/inviteUser";
import {
  inviteExternalUser,
  inviteInternalUser,
} from "../../../../sevices/apiUser";
import { useInternalLdapUser } from "../../../../utils/reactQuery/users";
import { useResponsive } from "../../../../hooks/useResponsive";

function InviteUserForm({ onClose }) {
  const [apiError, setApiError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState("externalUser");
  const { data } = useInternalLdapUser();
  const queryClient = useQueryClient();
  const isMobile = useResponsive("down", "md");

  const handleChange = (event) => {
    setTab(event.target.value);
  };

  const { register, handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(
      tab === "internalUser" ? internalInviteSchema : schema
    ),
  });
  const { errors } = formState;

  const { mutate } = useMutation({
    mutationFn:
      tab === "internalUser" ? inviteInternalUser : inviteExternalUser,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["LDAPinternalList"],
      });
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      setLoading(false);
      toast.success(res.data.message);
      onClose();
    },
    onError: (error) => {
      console.error("Error Invite USer", error);
      toast.error(error.response.data.message);
      setLoading(false);
    },
  });

  const submit = (formData) => {
    setApiError("");
    setLoading(true);
    if (tab === "internalUser") {
      const selected = data?.data?.data?.responseData?.filter(
        (ele, idx) => idx == formData.user
      )[0];
      mutate({
        first_name: selected.first_name,
        last_name: selected.last_name,
        email: selected.mail || "null",
      });
    } else {
      mutate(formData);
    }
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(submit)}
      noValidate
      sx={
        {
          // padding: "14px",
        }
      }
    >
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={tab}
          onChange={handleChange}
        >
          <FormControlLabel
            sx={{ marginRight: !isMobile && "8px" }}
            value="internalUser"
            control={<Radio />}
            label="Internal(LDAP) User"
            className={
              tab === "internalUser" ? "active-radio-btn" : "radio-btn-label"
            }
          />
          <FormControlLabel
            value="externalUser"
            control={<Radio sx={{ marginLeft: isMobile ? "10px" : "20px" }} />}
            label="External User"
            className={
              tab === "externalUser" ? "active-radio-btn" : "radio-btn-label"
            }
          />
        </RadioGroup>
      </FormControl>

      <Stack spacing={3} mt={2}>
        {apiError && <Alert severity="error">{apiError}</Alert>}

        {tab === "internalUser" ? (
          <Stack spacing={1}>
            <Select
              onChange={(event) => setValue("user", event.target.value)}
              fullWidth
            >
              {data?.data?.data?.responseData.map((ele, idx) => {
                return (
                  <MenuItem value={idx} key={idx}>
                    {ele.first_name} {ele.last_name}
                  </MenuItem>
                );
              })}
            </Select>
            <p style={{ color: "red" }}>{formState.errors?.user?.message}</p>
          </Stack>
        ) : (
          <Stack spacing={3}>
            <TextField
              label={"First Name"}
              required
              fullWidth
              id="first_name"
              name="first_name"
              error={errors?.first_name ? true : false}
              helperText={errors?.first_name && errors.first_name.message}
              {...register("first_name")}
              onChange={(e) => {
                const value = e.target.value;
                setValue("first_name", value.trimStart(), {
                  shouldValidate: true,
                });
              }}
            />
            <TextField
              label={"Last Name"}
              required
              fullWidth
              id="last_name"
              name="last_name"
              error={errors?.last_name ? true : false}
              helperText={errors?.last_name && errors.last_name.message}
              {...register("last_name")}
              onChange={(e) => {
                const value = e.target.value;
                setValue("last_name", value.trimStart(), {
                  shouldValidate: true,
                });
              }}
            />
            <TextField
              label={"Email Address"}
              required
              fullWidth
              id="email"
              name="email"
              error={errors?.email ? true : false}
              helperText={errors?.email && errors.email.message}
              {...register("email")}
              onChange={(e) => {
                const value = e.target.value;
                setValue("email", value.trimStart(), { shouldValidate: true });
              }}
            />
          </Stack>
        )}
        <Box
          sx={{
            alignSelf: "end",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{
              bgcolor: "#1B3E61",
              mr: "10px",
              fontSize: isMobile ? "14px" : "16px",
              padding: isMobile ? "5px 8px" : "10px 16px",
              fontWeight: "600",
              textTransform: "capitalize",
              minWidth: "130px",
            }}
            disabled={loading}
          >
            Add
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#1B3E61",
              border: "1px solid #1B3E61",
              textTransform: "capitalize",
              fontSize: isMobile ? "14px" : "16px",
              padding: isMobile ? "5px 8px" : "10px 16px",
              fontWeight: "600",
              opacity: "0.6",
              minWidth: "130px",
            }}
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default InviteUserForm;
