import React from "react";
import { useWorkflowById } from "../../../../utils/reactQuery/workflows";
import Kanban from ".";
import Loader from "../../../../components/Loader";
import { useCards } from "../../../../utils/reactQuery/cards";
import { useParams } from "react-router-dom";
// import moment from "moment";
import { useResponsive } from "../../../../hooks/useResponsive";
import { Box } from "@mui/material";
import MobileKanban from "./mobileKanban";

function Workflows({ workflowId, boardName }) {
  const { id } = useParams();
  const { data } = useWorkflowById(workflowId, id);
  const isMobile = useResponsive("down", "md");
  const { cards } = useCards(id);

  function sortCards(cards) {
    cards.sort((a, b) => {
      // Check if both cards have due_date
      if (a.due_date && b.due_date) {
        return new Date(a.due_date) - new Date(b.due_date);
      }

      // If only one of the cards has due_date, it comes first
      if (a.due_date) return -1;
      if (b.due_date) return 1;

      // If neither card has a due_date, sort by date_created
      return new Date(a.date_created) - new Date(b.date_created);
    });

    return cards;
  }
  return (
    <>
      {data?.data?.data?.responseData && cards?.data?.data?.responseData ? (
        isMobile ? (
          <Box>
            <MobileKanban
              workflow={data?.data?.data?.responseData}
              data={cards?.data?.data?.responseData}
              cards={sortCards(cards?.data?.data?.responseData)}
            />
          </Box>
        ) : (
          <Kanban
            boardName={boardName}
            id={id}
            workflowSteps={data?.data?.data?.responseData.sort(
              (a, b) => a.step_sequence - b.step_sequence
            )}
            cards={sortCards(cards?.data?.data?.responseData)}
          />
        )
      ) : (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
    </>
  );
}

export default Workflows;
