import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import "./datePickerCalender.scss";
const ProSpan = styled("span")({
  display: "inline-block",
  height: "1em",
  width: "1em",
  verticalAlign: "middle",
  marginLeft: "0.3em",
  marginBottom: "0.08em",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundImage: "url(https://mui.com/static/x/pro.svg)",
});

function Label({ componentName, valueType, isProOnly }) {
  const content = (
    <span>
      {/* <strong>{componentName}</strong> for {valueType} editing */}
    </span>
  );

  if (isProOnly) {
    return (
      <Stack direction="row" spacing={0.5} component="span">
        <Tooltip title="Included on Pro package">
          <a
            href="https://mui.com/x/introduction/licensing/#pro-plan"
            aria-label="Included on Pro package"
          >
            <ProSpan />
          </a>
        </Tooltip>
        {/* {content} */}
      </Stack>
    );
  }

  return content;
}

export default function CommonlyUsedComponents({ setDate, date, register }) {
  // const [dateTime, setDateTime] = useState('');
  // const currentDate = moment()
  // const newDate = date ? date : moment(currentDate).format('YYYY-MM-DDTHH:mm:ss')

  // const  localDate = (date)=>{
  //   const newDate = moment.utc(date);
  //   const localDate = newDate.local().toDate();
  //   return localDate

  // }

  const newDate = moment.utc(date);
  const localDate = newDate.local().toDate();

  // const newDate = moment.utc(date);
  // const localDate = newDate.local().toDate();

  const handleDateChange = (date) => {
    const utcStart = new moment(date).utc();
    const selectedDate = date ? utcStart.format() : moment();

    // setDateTime(selectedDate)
    setDate(utcStart);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="datepicker">
        <DemoContainer className="datepicker" components={["DatePicker"]}>
          <DemoItem
            label={
              <Label componentName="DateTimePicker" valueType="date time" />
            }
          >
            <DateTimePicker
              defaultValue={date ? moment(localDate) : moment().local()}
              onChange={handleDateChange}
              className="mobile-datepicker"
            />
          </DemoItem>
        </DemoContainer>
      </div>
    </LocalizationProvider>
  );
}
