import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import toast from "react-hot-toast";
import { updateUser } from "../../../../sevices/apiUser";
import { objectToFormData } from "../../../../utils/helpers";
import { localStorageInstance } from "../../../../utils/localStorage";

function Active({ isActive, id }) {
  const [status, setStatus] = useState(isActive);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [activeDialog, setActiveDialog] = useState(false);
  const userId = localStorageInstance.getItem("userId");

  const handleCloseDialog = () => {
    setActiveDialog(false);
  };

  const { mutate: userMutate } = useMutation({
    mutationFn: updateUser,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      //    queryClient.invalidateQueries({
      //      queryKey: ["boardName", id],
      //    });
      setStatus((data) => !data);
      setLoading(false);
      toast.success(res.data.message);
      handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error User Mutate", error);
      toast.error(error.response.data.message);
      setLoading(false);
      handleCloseDialog();
    },
  });

  const callback = () => {
    const data = objectToFormData({ active: !isActive });
    setLoading(true);
    userMutate({ id, data });
  };
  return (
    <>
      <div
        className="w-100 d-flex justify-content-center"
        style={{
          cursor: userId === id ? "not-allowed" : "pointer",
          opacity: userId === id ? 0.6 : 1,
        }}>
        <label className="switch">
          <input
            type="checkbox"
            value={status}
            checked={status}
            onChange={(e) => {
              userId !== id && setActiveDialog(true);
            }}
          />
          <div
            className={"slider"}
            style={{
              cursor: userId === id ? "not-allowed" : "pointer",
            }}></div>
        </label>
        {activeDialog && (
          <ConfirmationDialog
            open={activeDialog}
            handleClose={handleCloseDialog}
            message={
              isActive
                ? "Are you sure you want to Disable this user ?"
                : "Are you sure you want to Enable this user ?"
            }
            callback={callback}
            loading={loading}
            btnTitle={isActive ? "Disable" : "Enable"}
            title={isActive ? "Disable User" : "Enable User"}
          />
        )}
      </div>
    </>
  );
}

export default Active;
