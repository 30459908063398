import React from "react";
import { useSelector } from "react-redux";

import UsersKanban from "./UsersKanban";
import Team from "./Team";
import { useSearchParams } from "react-router-dom";
import OpenCardDetail from "../../../board/components/Kanban/openCardDetail";

function GroupingBoards() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isChecked = searchParams.get("switch");
  const groupingUser = useSelector(
    (state) => state.groupingUser.allocationUsers
  );

  const users = groupingUser.map((item) => item.users).flat();
  return (
    <div>
      <OpenCardDetail/>
      {isChecked == "true" ? (
        <Team groupingUser={users} />
      ) : (
        groupingUser && users && <UsersKanban users={users} />
      )}
    </div>
  );
}

export default GroupingBoards;
