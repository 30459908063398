import { Box, TextField, Grid } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";

import InputFileUpload from "../../../../components/FileUploadButton";

function Form({ name, file, handleNameChange, handleFileChange, isMobile }) {
  const { register, watch, getValues, formState } = useForm();
  const { errors } = formState;

  const onFormSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      {file?.map((file, index) => (
        <div key={index} className="attachments-form-container">
          <Box
            component="form"
            noValidate
            className="d-flex attachment-form-box justify-content-space-between"
            onSubmit={(e) => onFormSubmit(e)}
            sx={{
              padding: isMobile ? "0px" : "8px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              className="remove-container-width"
              justifyContent={"center"}
              alignItems={"center"}
              columnSpacing={2}
            >
              <Grid
                item
                xs={isMobile ? 12 : 5}
                md={isMobile ? 12 : 6}
                className="pl-0"
              >
                <TextField
                  label={""}
                  placeholder="Name"
                  required
                  id="name"
                  name="name"
                  fullWidth={isMobile ? true : false}
                  value={name[index]?.name || ""}
                  error={errors?.name ? true : false}
                  helperText={errors?.name && errors.name.message}
                  className={`attachment-name-field ${isMobile && "mb-2"}`}
                  onChange={(e) => handleNameChange(e, index)}
                />
              </Grid>
              <Grid
                item
                xs={isMobile ? 12 : 5}
                md={isMobile ? 12 : 6}
                className={isMobile ? "padding-left-0" : ""}
              >
                <InputFileUpload
                  fileName={file?.name}
                  watch={watch}
                  register={register}
                  getValues={getValues}
                  fileChange={(e) => handleFileChange(e, index)}
                  isMobile={isMobile}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      ))}
    </>
  );
}

export default Form;
