import * as yup from "yup";

export const schema = yup
  .object({
    board_name: yup.string().required("Board name is required"),
    key: yup
      .string()
      .max(6, "Key cannot be more than 6 letters")
      .required("Board Key is required"),
  })
  .required();
