import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { useParams, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import manageColumnIcon from "../../../../styles/svg/Vector.svg";
import { useWorkflowById } from "../../../../utils/reactQuery/workflows";
import { dataGridColumn } from "../../../../components/dataGridColumn";
import OpenCardDetail from "../Kanban/openCardDetail";
import addIcon from "../../../../styles/svg/addIcon.svg";
import CreateCardDialogListview from "./CreateCardDialogListview";
import { currentUserId, isAdmin } from "../../../../utils/constants";
import ChildTaskData from "./ChildTaskData";
import Loader from "../../../../components/Loader";
import { manageColumnOrder } from "../../../../sevices/apiCards";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { objectToFormData } from "../../../../utils/helpers";
import {
  setWorkflowIds,
  setMilestoneIds,
  setAssigneeIds,
} from "../../../../redux/ListViewColumnHeaderFilterSclice";
const GlobalTableComponent = React.lazy(() =>
  import("../../../../components/Table/Table/Table")
);

export default function ListView({ workflowId, boardName, cards, isLoading }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { id: boardId } = useParams();
  const [expandcardId, setExpandCardId] = useState(null);
  const [subtaskCards, setSubtaskCards] = useState([]);
  const [isSubtaskLoading, setIsSubtaskLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [boardCards, setBoardCards] = useState(cards?.data?.data?.responseData);
  const { data: workflows } = useWorkflowById(workflowId, boardId);
  const [openCreateCard, setOpenCreateCard] = useState(false);
  const filterUser = searchParams.get("search");
  const noMileStoneSearch = searchParams.get("noMilestone");
  const epicSearch = useSelector((state) => state.epic.searchEpic);
  const searchText = useSelector((state) => state.search.searchText);
  const cardId = searchParams.get("card_id");
  const statusSearch = searchParams.get("workflow_Step");
  const columnHeaderStatus = searchParams.get("statusFilter");
  const columnHeaderMilestone = searchParams.get("columnMilestone");
  const columnHeaderAssignee = searchParams.get("assigneeFilter");
  const milestoneIsSelected = searchParams.get("milestone");
  const currentUserIdVariable = currentUserId();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const havePermissionToEdit = (data) => {
    if (
      isAdmin() ||
      boardName?.owner_user_id == currentUserIdVariable ||
      data?.assigned_to_user_id == currentUserIdVariable ||
      data?.reporter == currentUserIdVariable
    ) {
      return true;
    } else {
      return false;
    }
  };
  const [columns, setColumns] = useState(
    dataGridColumn(boardName, havePermissionToEdit)
  );

  const reorderColumns = (dataGridColumns, columnSequence) => {
    if (!columnSequence) return dataGridColumns;
    return dataGridColumns.sort(
      (a, b) => columnSequence[a.id] - columnSequence[b.id]
    );
  };
  const reorderedColumns = reorderColumns(
    dataGridColumn(boardName, havePermissionToEdit),
    boardName?.card_column_sequence
  );

  useEffect(() => {
    if (boardName?.card_column_sequence) {
      setColumns(reorderedColumns);
    }
  }, [boardName?.card_column_sequence]);

  const handleSelectColumn = (columnId) => {
    setColumns(
      columns.map((item) =>
        item.id === columnId ? { ...item, isHidden: !item.isHidden } : item
      )
    );
  };

  const workflowSteps = workflows?.data?.data?.responseData;

  useEffect(() => {
    let project;

    if (subtaskCards && boardCards) {
      //  const newSubtask = subtaskCards.map((item) => ({ ...item }));
      project = boardCards?.map((item, idx) => {
        if (item?.card?.card_id === expandcardId) {
          return { ...item, subRow: subtaskCards };
        }

        return item;
      });
      setBoardCards(project);
    }
  }, [subtaskCards]);

  useEffect(() => {
    const debounceDelay = 1000;
    let allFilteredCards = cards?.data?.data?.responseData;
    const debouncedFilter = debounce(() => {
      if (searchText) {
        allFilteredCards = allFilteredCards?.filter(
          (cardData) =>
            (cardData?.card?.title
              ? `${cardData?.card?.title}`.toLowerCase().includes(searchText)
              : "") ||
            cardData?.card?.card_id
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchText) ||
            (cardData?.card.assigned_to_user?.first_name
              ? `${cardData?.card.assigned_to_user?.first_name}`
                  .toLowerCase()
                  .includes(searchText)
              : "")
        );
        setBoardCards(allFilteredCards);
      }
    }, debounceDelay);
    if (filterUser) {
      searchParams.set("assigneeFilter", "");
      searchParams.delete("assigneeFilter");
      setSearchParams(searchParams);
      allFilteredCards = allFilteredCards?.filter(
        (cardData) => cardData?.card?.assigned_to_user_id == filterUser
      );
    }
    if (epicSearch && epicSearch?.length > 0) {
      searchParams.set("columnMilestone", "");
      searchParams.delete("columnMilestone");
      setSearchParams(searchParams);
      allFilteredCards = allFilteredCards?.filter((cardData) =>
        epicSearch.includes(cardData?.card?.epic_id)
      );
    }

    if (noMileStoneSearch === "true") {
      allFilteredCards = allFilteredCards?.filter(
        (cardData) => cardData?.card?.epic_id == null
      );
    }

    if (searchText) {
      debouncedFilter();
    }

    if (statusSearch) {
      searchParams.set("statusFilter", "");
      searchParams.delete("statusFilter");
      setSearchParams(searchParams);
      allFilteredCards = allFilteredCards?.filter((cardData) => {
        return cardData?.card?.workflow_step_id == statusSearch;
      });
    }
    if (columnHeaderStatus) {
      const columnStatusArray = columnHeaderStatus?.split(","); // Assuming comma-separated IDs
      allFilteredCards = allFilteredCards?.filter((cardData) =>
        columnStatusArray?.includes(
          cardData?.card?.workflow_step_id?.toString()
        )
      );
    }

    if (columnHeaderMilestone) {
      const columnMilestoneArray = columnHeaderMilestone?.split(","); // Assuming comma-separated IDs
      allFilteredCards = allFilteredCards?.filter((cardData) =>
        columnMilestoneArray?.includes(
          cardData?.card?.epic?.epic_id?.toString()
        )
      );
    }

    if (columnHeaderAssignee) {
      const columnAssigneeArray = columnHeaderAssignee?.split(","); // Assuming comma-separated IDs
      allFilteredCards = allFilteredCards?.filter((cardData) =>
        columnAssigneeArray?.includes(
          cardData?.card?.assigned_to_user?.user_id?.toString()
        )
      );
    }

    setBoardCards(allFilteredCards);

    return () => {
      debouncedFilter.cancel();
    };
  }, [
    searchText,
    filterUser,
    epicSearch,
    boardId,
    cards,
    statusSearch,
    noMileStoneSearch,
    columnHeaderStatus,
    columnHeaderMilestone,
    columnHeaderAssignee,
  ]);
  const handleClickOpen = () => {
    setOpenCreateCard(true);
  };

  const handleExpandedData = (cardId) => {
    setExpandCardId(cardId);
  };

  const handleSelectAll = () => {
    if (
      columns.every((value) =>
        value.id === "cardId" ||
        value.id === "title" ||
        value.id === "assigned_to_user_name"
          ? true
          : value.isHidden === true
      )
    ) {
      setColumns(
        columns.map((item) =>
          item.id === "cardId" ||
          item.id === "title" ||
          item.id === "assigned_to_user_name"
            ? { ...item }
            : { ...item, isHidden: false }
        )
      );
    } else {
      setColumns(
        columns.map((item) =>
          item.id === "cardId" ||
          item.id === "title" ||
          item.id === "assigned_to_user_name"
            ? { ...item }
            : { ...item, isHidden: true }
        )
      );
    }
  };

  const loader = {
    style: { color: "black" || "#ffffff" },
    size: 20,
  };

  const subTableProps = {
    isCollapsible: true,
    isHiddenRowExpandIcon: true,
    isHeaderHidden: true,
    isLoading: isSubtaskLoading,
    loadingProps: loader,
  };

  const { mutate } = useMutation({
    mutationFn: manageColumnOrder,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["boardName", id],
      });
      toast.success(res.data.message);
    },
    onError: (error) => {
      console.error("column sequence not updated", error);
      toast.error(error.response.data.message);
    },
  });

  const handleColumOrder = (data) => {
    const newArrangement = {};
    data?.map((item, index) => {
      newArrangement[item.id] = index;
    });

    mutate(
      objectToFormData({
        field_sequence: JSON.stringify(newArrangement),
      })
    );
  };

  useEffect(() => {
    const updateWorkflowIds = () => {
      let ids = [];
      const parsedStatusSearch = Number(statusSearch);
      if (parsedStatusSearch) {
        // If `statusSearch` exists, use it as `ids`
        ids = [parsedStatusSearch];
      } else if (cards?.data?.data?.responseData?.length > 0) {
        // If `cards` data is available, extract unique `workflow_step_id`
        ids = [
          ...new Set(
            cards?.data?.data?.responseData?.map(
              (item) => item?.card?.workflow_step_id
            )
          ),
        ];
      }

      if (ids.length > 0) {
        dispatch(
          setWorkflowIds({
            stepIds: ids,
            workflowId: boardName?.workflow_id,
          })
        );
      }
    };

    updateWorkflowIds();
  }, [
    statusSearch,
    cards?.data?.data?.responseData,
    dispatch,
    boardName?.workflow_id,
  ]);

  useEffect(() => {
    let ids = [];
    const parsedMilestoneSearch = Number(epicSearch);
    if (parsedMilestoneSearch) {
      if (cards?.data?.data?.responseData?.length > 0) {
        ids = [
          ...new Map(
            cards.data.data.responseData
              .map((item) => item.card?.epic) // Extract the epic object
              .filter((epic) => epic?.epic_id === parsedMilestoneSearch) // Filter out epics with null epic_id
              .map((epic) => [epic.epic_id, epic]) // Map epic_id to the epic object to ensure uniqueness
          ).values(),
        ];
      }
    } else if (cards?.data?.data?.responseData.length > 0) {
      ids = [
        ...new Map(
          cards.data.data.responseData
            .map((item) => item.card?.epic) // Extract the epic object
            .filter((epic) => epic?.epic_id != null) // Filter out epics with null epic_id
            .map((epic) => [epic.epic_id, epic]) // Map epic_id to the epic object to ensure uniqueness
        ).values(),
      ];
    }
    if (ids?.length > 0) {
      dispatch(setMilestoneIds(ids));
    }
  }, [cards?.data?.data?.responseData, dispatch, epicSearch]);

  useEffect(() => {
    let ids = [];
    const parsedAssigneeSearch = Number(filterUser);
    if (parsedAssigneeSearch) {
      if (cards?.data?.data?.responseData?.length > 0) {
        ids = [
          ...new Map(
            cards?.data?.data?.responseData
              .map((item) => item?.card?.assigned_to_user) // Extract the epic object
              .filter((assignee) => assignee?.user_id === parsedAssigneeSearch)
              .map((assignee) => [assignee?.user_id, assignee]) // Map epic_id to the epic object to ensure uniqueness
          ).values(),
        ];
      }
    } else if (cards?.data?.data?.responseData.length > 0) {
      ids = [
        ...new Map(
          cards?.data?.data?.responseData
            .map((item) => item?.card?.assigned_to_user) // Extract the epic object
            .filter((assignee) => assignee !== null)
            .map((assignee) => [assignee?.user_id, assignee]) // Map epic_id to the epic object to ensure uniqueness
        ).values(),
      ];
    }
    if (ids?.length > 0) {
      dispatch(setAssigneeIds(ids));
    }
  }, [cards?.data?.data?.responseData, dispatch, filterUser]);

  return (
    <>
      {expandcardId && (
        <ChildTaskData
          cardId={expandcardId}
          setSubtaskCards={setSubtaskCards}
          setIsSubtaskLoading={setIsSubtaskLoading}
        />
      )}
      {boardCards && cardId && <OpenCardDetail cardsOnBoard={boardCards} />}
      {openCreateCard && workflowSteps && (
        <CreateCardDialogListview
          open={openCreateCard}
          handleClose={() => setOpenCreateCard(false)}
          workflowSteps={workflowSteps}
        />
      )}
      <div>
        <Stack
          direction="row"
          className="d-flex justify-content-end align-items-center"
          mb={3}
        >
          <Button
            onClick={handleClickOpen}
            className="theme-bg-color capitalize mr-3"
            variant="contained"
            startIcon={<img src={addIcon} alt="addSubtaskIcone" />}
            sx={{
              position: "static",
              "&:hover": {
                backgroundColor: "#1B3E61",
                border: "none",
                boxShadow: 0,
              },
            }}
          >
            Create Card
          </Button>
          <Stack
            direction="row"
            justifyContent="end"
            onClick={(event) => setAnchorEl(event.currentTarget)}>
            <img
              src={manageColumnIcon}
              width={14}
              alt="manage column"
              style={{ cursor: "pointer" }}
            />
            <Typography className="manage-board">
              Manage Board Columns
            </Typography>
          </Stack>
        </Stack>
        <Box>
          <Suspense
            fallback={
              <div className="loader-center">
                <Loader color={"black"} />
              </div>
            }
          >
            <GlobalTableComponent
              data={
                boardCards
                  ? boardCards?.map((boardCard) => {
                      return {
                        id: boardCard?.card?.card_id,
                        cardId: boardCard?.card?.card_id,
                        title: boardCard?.card?.title,
                        date_created: boardCard?.card?.date_created,
                        assigned_to_user_name:
                          boardCard?.card?.assigned_to_user?.first_name || "z",
                        ...boardCard,
                        subRow:
                          boardCard?.subRow && boardCard?.subRow?.length > 0
                            ? boardCard?.subRow?.map((cardSubTask, idx) => {
                                return {
                                  id: cardSubTask?.card_id,
                                  ...cardSubTask,
                                  key: boardCard?.key,
                                  board_name: boardCard?.board_name,
                                  board_admin: boardCard?.board_admin,
                                };
                              })
                            : null,
                      };
                    })
                  : []
              }
              headers={columns}
              subHeader={columns.map((item, index) =>
                index === 0
                  ? {
                      ...item,
                      alignContent: "center",
                    }
                  : item
              )}
              isCollapsible={true}
              isDraggableColumn={true}
              isPagination={false}
              rowCount={10}
              handleExpandedData={handleExpandedData}
              subTableProps={subTableProps}
              tableBoxClass={
                milestoneIsSelected        
                  ? "parent-milestone-table-box"
                  : "parent-table-box"
              }
              tableContainerClass={"parent-table-boarder"}
              isLoading={isLoading}
              loadingProps={loader}
              loadingClass={"h-70vh"}
              headerClass={"table-header-listview"}
              handleColumOrder={handleColumOrder}
              defaultSortBy="date_created"
            />
          </Suspense>
        </Box>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(false)}
          MenuListProps={{
            "aria-labelledby": "epics-board-dropdown",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 14,
                height: 14,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          className="assign-board-owner-list"
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.every((value) => value.isHidden === false)}
                  onChange={handleSelectAll}
                />
              }
              label="Select All"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col3" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col3")}
                />
              }
              label="Status"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col6" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col6")}
                />
              }
              label="Label"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col7" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col7")}
                />
              }
              label="Milestone"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col8" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col8")}
                />
              }
              label="Allocated Hours"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col9" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col9")}
                />
              }
              label="Hours Spent"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col10" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col10")}
                />
              }
              label="Time Tracking"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col11" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col11")}
                />
              }
              label="Due Date"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col12" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col12")}
                />
              }
              label="Created Date"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col13" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col13")}
                />
              }
              label="Reporter"
            />
          </FormGroup>
        </Menu>
      </div>
    </>
  );
}
