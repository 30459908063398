import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, Stack, TextField } from "@mui/material";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateBoard } from "../../../../../sevices/apiBoards";
import { schema } from "../../../../../schemas/updateKeyBoard";

function KeyForm({ params, onClose }) {
  const [apiError, setApiError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const queryClient = useQueryClient();
  // React Hook Form
  const { register, handleSubmit, reset, getValues, formState, setValue } =
    useForm({
      defaultValues: {
        board_name: params.row.board_name,
        key: params.row.key,
      },
      resolver: yupResolver(schema),
    });
  const { errors } = formState;

  const { mutate } = useMutation({
    mutationFn: updateBoard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["boards"],
      });
      queryClient.invalidateQueries({
        queryKey: ["boardName", `${params.row.board_id}`],
      });
      setLoading(false);
      toast.success(res.data.message);
      onClose();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
    },
  });

  const submit = (data) => {
    /**
     * Create Board
     */
    setApiError("");
    setLoading(true);

    mutate({ data, board_id: params.row.board_id });
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(submit)}
      noValidate
      sx={{
        padding: "14px",
      }}>
      <Stack spacing={3}>
        {apiError && <Alert severity="error">{apiError}</Alert>}
        <TextField
          label={"Board Name"}
          required
          disabled
          id="board_name"
          name="board_name"
          error={errors?.board_name ? true : false}
          helperText={errors?.board_name && errors.board_name.message}
          {...register("board_name")}
        />
        <TextField
          label={"Key"}
          required
          id="key"
          name="key"
          error={errors?.key ? true : false}
          helperText={errors?.key && errors.key.message}
          {...register("key")}
          onChange={(e) => {
            const value = e.target.value;
            setValue("key", value.trimStart(), {
              shouldValidate: true,
            });
          }}
        />
        <Box
          sx={{
            alignSelf: "end",
          }}>
          <Button
            type="submit"
            variant="contained"
            sx={{
              bgcolor: "#1B3E61",
              mr: "10px",
              fontSize: "16px",
              padding: "10px 16px",
              fontWeight: "600",
              textTransform: "capitalize",
              minWidth: "130px",
            }}
            disabled={loading}>
            Update
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#1B3E61",
              border: "1px solid #1B3E61",
              textTransform: "capitalize",
              fontSize: "16px",
              padding: "10px 16px",
              fontWeight: "600",
              opacity: "0.6",
              minWidth: "130px",
            }}
            onClick={onClose}
            disabled={loading}>
            Cancel
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default KeyForm;
