import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useForm } from "react-hook-form";
import { MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTheme } from "@mui/material/styles";
import { useParams, useSearchParams } from "react-router-dom";

import arrow from "../../../../../../styles/svg/arrow.svg";
import Field from "../../../../../../components/field";
import { updateBoard } from "../../../../../../sevices/apiCards";
import {
  useWorkflowStepNameByWorkflowStepId,
  useWorkflowById,
} from "../../../../../../utils/reactQuery/workflows";
import "./ChangeBoardToDifferentWorkflow.scss";
import { useResponsive } from "../../../../../../hooks/useResponsive";

export default function ChangeBoardToDifferentWorkflow({
  open,
  close,
  card,
  currentBoardName,
  targetBoardName,
  workflowId,
  cardId,
  targetBoardId,
  currentBoardWorkflowId,
  currentWorkflowStepId,
}) {
  const [currentWorkflowStepName, setCurrentWorkflowStepName] = useState(null);
  const [workflowSteps, setWorkflowSteps] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { id } = useParams();
  const queryClient = useQueryClient();
  const isMobile = useResponsive("down", "md");
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, refetch } = useWorkflowById(workflowId, workflowId);
  const { workflowStepName } = useWorkflowStepNameByWorkflowStepId(
    card?.data?.data?.responseData?.workflow_step_id,
    card?.data?.data?.responseData?.board_id
  );
  const { register, handleSubmit: updateBoardSubmit, watch} = useForm({
    targetStep: data?.data?.data?.responseData[0]?.workflow_step_id || "",
  });

  const targetWorkflowStep = watch("targetStep");
  useEffect(() => {
    if (data) {
      setWorkflowSteps(data);
    }
  }, [workflowId, data]);

  useEffect(() => {
    if (card?.data?.data?.responseData?.workflow_step_id) {
      setCurrentWorkflowStepName(workflowStepName);
    }
  }, [card, workflowStepName]);

  const { mutate: updateMutate, isPending } = useMutation({
    mutationFn: updateBoard,
    onSuccess: (res) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("brd_id", targetBoardId);
      setSearchParams(newSearchParams);
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: [`boards`],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey : ["watching"]
      })
      queryClient.invalidateQueries({
        queryKey : ["mineCards"]
      })
      close(false);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
    },
  });

  const onSubmit = (data) => {
    updateMutate({
      card_id: cardId,
      board_id: targetBoardId,
      workflow_step_id: data?.targetStep ?? currentWorkflowStepId,
    });
  };

  const handleClose = () => {
    close(false);
  };

  return (
    <React.Fragment>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh !important",
            "& .MuiPaper-root": {
              borderRadius: "8px",

              maxWidth: "494px",
            },
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="move-ticket-dialog-title"
        >
          Move task to another Board
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 10,
            top: isMobile ? 1 : 9,
            padding: "8px",
            color: (theme) => theme.palette.grey[500],
            "&:hover": {},
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="move-ticket-dialog-content">
          {
            currentBoardWorkflowId !== workflowId ? <DialogContentText className="move-ticket-dialog-contentText">
            Origin and Destination boards use different workflow steps, select
            the step you want to put this task in when moving it to the
            destination board.
          </DialogContentText> : 
           <DialogContentText className="move-ticket-dialog-contentText">
           Origin and Destination boards use the same workflow steps, 
           so the task will automatically move to its corresponding step 
           in the destination board.
           </DialogContentText>
          }
         
        </DialogContent>

        <form onSubmit={updateBoardSubmit(onSubmit)}>
          {currentBoardWorkflowId !== workflowId && (
            <DialogContent className="move-ticket-form-dialogContent">
              <div
                className={
                  isMobile
                    ? "mobile-ticket-reasign-step-container"
                    : "ticket-reasign-step-container"
                }
              >
                <div
                  className={
                    isMobile
                      ? "mobile-current-target-board-name"
                      : "current-target-board-name"
                  }
                >
                  <p> {currentBoardName} </p>
                  <div className="initial-step">
                    <TextField
                      className="mui-status-field"
                      disabled
                      value={
                        currentWorkflowStepName?.data?.data?.responseData
                          ?.status
                      }
                    />
                  </div>
                </div>
                {/* </div> */}
                <img src={arrow} alt="" />
                <div
                  className={
                    isMobile
                      ? "mobile-current-target-board-name"
                      : "current-target-board-name"
                  }
                >
                  <p>{targetBoardName}</p>
                  <div className="initial-step">
                    <Field
                      sx={{
                        width: isMobile ? "100%" : "180px",
                      }}
                      className="mui-status-field"
                      required
                      select
                      {...register(`targetStep`)}
                    >
                      {workflowSteps?.data?.data?.responseData.map((option) => (
                        <MenuItem
                          key={`option.workflow_step_id`}
                          id={`option.workflow_step_id`}
                          value={option.workflow_step_id}
                        >
                          {option.status}
                        </MenuItem>
                      ))}
                    </Field>
                  </div>
                </div>
              </div>
            </DialogContent>
          )}
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginTop: "30px",
              marginBottom: "30px",
              padding: "0px 22px 0px 22px",
            }}
            className={
              currentBoardWorkflowId !== workflowId
                ? "move-ticket-dialogActions"
                : "move-ticket-dialogActions-sameWorkflow"
            }
          >
            <Button
              className="remove-step-btn-save"
              autoFocus
              variant="contained"
              disabled={
                (currentBoardWorkflowId !== workflowId || isPending) &&
                (!targetWorkflowStep || isPending)
              }
              onClick={updateBoardSubmit(onSubmit)}
              // type="submit"
            >
              <div className="btn-content">Move</div>
            </Button>
            <Button
              className="remove-step-btn-cancel"
              autoFocus
              variant="outlined"
              //   disabled={isloading}
              onClick={handleClose}
              // onClick={()=>close(false)}
            >
              <div className="btn-content">Cancel</div>
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
