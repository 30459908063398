import React, { useEffect, useState } from "react";
import { Box, Menu, MenuItem, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useUsers } from "../../../../utils/reactQuery/users";
import Loader from "../../../../components/Loader";
import MuiTable from "../../board/components/BoardTable/MuiTbale";
import Active from "./Active";
import { getLastSevenDays, relativeTime } from "../../../../utils/helpers";
import Team from "./Team";
import Logs from "./Logs";
import UserName from "./UserName";
import Admin from "./Admin";
import { getAPIBaseUrl, isAdmin } from "../../../../utils/constants";
import { useResponsive } from "../../../../hooks/useResponsive";
import resetPasswordIcon from "../../../../styles/svg/reset-password.svg";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import { sendResetMail } from "../../../../sevices/apiUser";
import { useAssets } from "../../../../utils/reactQuery/domainAssets";
import assignedToTeam from "../../../../styles/svg/assignedToTeam.svg";
import worklogIcon from "../../../../styles/svg/worklogIcon.svg";
import { setGroupUser } from "../../../../redux/TaskGroupingSlice";

function UserTable() {
  const [userType] = useCustomSearchParams("users-type");
  const [userStatus] = useCustomSearchParams("users-status");
  const { isLoading, error, users, isError } = useUsers();
  const data = users?.data?.data?.responseData;
  const isMobile = useResponsive("down", "md");
  const [rows, setRows] = useState([]);
  const searchText = useSelector((state) => state.search.searchText);
  const [resetPassDialog, setResetPassDialog] = useState(false);
  const [selectedMail, setSelectedMail] = useState("");
  const { icon } = useAssets();
  const apiUrl = getAPIBaseUrl();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.logInUser);

  const [clickedUser, setClickedUser] = useState(null);
  useEffect(() => {
    const usersArray = data
      ?.map((el, idx) => {
        return {
          ...el,
          id: idx,
          actions: { email: el?.email, userType: el?.user_type },
        };
      })
      ?.filter(
        (user) =>
          user.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
          user.last_name.toLowerCase().includes(searchText.toLowerCase())
      );

    let users = usersArray;
    if (userStatus) {
      if (userStatus == "all") users = usersArray;
      if (userStatus == "active") {
        users = users?.filter((el) => el.active);
      }
      if (userStatus == "inactive") {
        users = users?.filter((el) => !el.active);
      }
    }
    if (userType) {
      if (userType == "all") users = users;
      if (userType == "internal") {
        users = users?.filter((el) => el.user_type == "Internal");
      }
      if (userType == "external") {
        users = users?.filter((el) => el.user_type == "External");
      }
    }
    setRows(users);
  }, [searchText, userType, userStatus, data]);

  const handleResetPass = (mail) => {
    setSelectedMail(mail);
    setResetPassDialog(true);
  };

  const callback = () => {
    sendResetMail({ email: selectedMail })
      .then((res) => {
        toast.success(res?.data?.data?.responseData);
        setResetPassDialog(false);
      })
      .catch((err) => {
        console.log("sendresetmail error", err);
      });
  };

  const handleClick = (event, params) => {
    setClickedUser(params?.row);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAssignTask = () => {
    navigate(`/my-task?tab=team&user=true`);
    dispatch(setGroupUser(clickedUser));
  };
  const handleWorklog = (event) => {
    navigate(`/my-task?tab=worklog&date=${getLastSevenDays()}`);
    dispatch(setGroupUser(clickedUser));
  };

  const columns = [
    {
      field: "user_type",
      headerName: "Type",
      minWidth: 80,
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <p className="size-14 text-color weight-500">Type</p>
        </div>
      ),
      headerClassName: "center-header",
      renderCell: (params) => {
        return (
          <div className="w-100 d-flex justify-content-center">
            {icon ? (
              <img
                src={`${apiUrl}/${icon}`}
                alt="internal user"
                className={
                  params.value == "Internal"
                    ? "internal_user_icon"
                    : "external_user_icon"
                }
              />
            ) : (
              params.value
            )}
          </div>
        );
      },
      flex: 0.6,
      sortable: false,
    },
    {
      field: "first_name",
      headerName: "Name",
      minWidth: 250,
      flex: 1.5,
      // editable: true,
      renderCell: (params) => {
        return <UserName params={params} />;
      },
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
      flex: 1.5,
      // editable: true,
      renderCell: (params) => {
        return <p style={{ wordBreak: "break-word" }}>{params?.value}</p>;
      },
    },
    {
      field: "teams",
      headerName: "Team",
      minWidth: 150,
      renderCell: (params) => {
        return <Team params={params} />;
      },
      flex: 1,
      sortable: false,
    },
    {
      field: "cardlog_required",
      headerName: "Logs",
      minWidth: 100,
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <p className="size-14 text-color weight-500">Logs</p>
        </div>
      ),
      renderCell: (params) => {
        return <Logs logRequired={params.value} params={params} />;
      },
      flex: 0.7,
      sortable: false,
      headerClassName: "center-header",
    },
    {
      field: "role",
      headerName: "Admin",
      minWidth: 100,
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <p className="size-14 text-color weight-500">Admin</p>
        </div>
      ),
      //   renderHeader: (cell) => <img src={ActiveIcon} />,
      renderCell: (params) => {
        return (
          <div className="w-100 d-flex justify-content-center">
            {isAdmin() && (
              <Admin isSuperAdmin={params.value} id={params.row.user_id} />
            )}
            {/* {params.value == "Super Admin" ? (
              <img src={AdminLogo} />
            ) : (
              <img src={UserLogo} />
            )} */}
          </div>
        );
      },
      headerClassName: "center-header",
      flex: 1,
      sortable: false,
    },
    {
      field: "active",
      headerName: "Active",
      minWidth: 85,
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <p className="size-14 text-color weight-500">Active</p>
        </div>
      ),
      renderCell: (params) => {
        return <Active isActive={params.value} id={params.row.user_id} />;
      },
      flex: 1,
      sortable: false,
      headerClassName: "center-header",
    },
    {
      field: "last_seen",
      headerName: "Last Active",
      minWidth: 100,
      //   renderHeader: (cell) => <img src={ActiveIcon} />,
      renderCell: (params) => {
        return <p>{relativeTime(params.value)}</p>;
      },
      flex: 1.2,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      //   renderHeader: (cell) => <img src={ActiveIcon} />,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <MenuIcon
              className="reset_pass_icon"
              onClick={(e) => handleClick(e, params)}
            />
            {params?.value.userType !== "Internal" && (
              <img
                src={resetPasswordIcon}
                alt="reset password"
                className="reset_pass_icon"
                onClick={() => handleResetPass(params?.value.email)}
              />
            )}
          </Stack>
        );
      },
      flex: 1.2,
      sortable: false,
    },
  ];
  return (
    <>
      {resetPassDialog && (
        <ConfirmationDialog
          open={resetPassDialog}
          handleClose={() => setResetPassDialog(false)}
          message={
            "Are you sure you want to send a password reset email to this user?"
          }
          callback={callback}
          btnTitle={"Confirm"}
          title={"Send Email"}
        />
      )}

      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>Error</p>
        </div>
      )}
      {!isLoading && !isError && (
        <Box p={isMobile && 1} mt={isMobile && 1}>
          <MuiTable
            initialRows={rows || []}
            columns={columns}
            defaultSort={"first_name"}
            headerBgColor={"#F2F4F6"}
          />
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleAssignTask} className="filter-heading">
          <img src={assignedToTeam} alt="assign to team" className="mr-1" />
          Assign Task
        </MenuItem>
        <MenuItem onClick={handleWorklog} className="filter-heading">
          <img src={worklogIcon} alt="worklog" className="mr-1" />
          Worklogs
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserTable;
