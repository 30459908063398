import React, { useEffect, useState } from "react";
import { useWorkflowById } from "../../../../utils/reactQuery/workflows";
import { getMyCards } from "../../../../sevices/apiCards";
import Kanban from "../../components/KanbanBoard/Kanban";
import Loader from "../../../../components/Loader";
import { useMineCards } from "../../../../utils/reactQuery/cards";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

function UsersKanban({ users }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const workflow_id = searchParams.get("workflow_id");
  const unassigned_task = searchParams.get("isUnassigned");
  const { data } = useWorkflowById(workflow_id, workflow_id);
  const boards = searchParams.get("board_id");
  const {
    data: mineCards,
    isLoading,
    refetch,
    isRefetching,
  } = useMineCards(
    users,
    [],
    [],
    false,
    workflow_id,
    Boolean(unassigned_task),
    boards
  );

  useEffect(() => {
    // setLoading(true);
    // getMyCards(users,[],[])
    //   .then((res) => {
    //     setCards(res.data.data.responseData);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     if (err.code != "ERR_CANCELED") setLoading(false);
    //     setCards([]);
    //   });
    refetch();
  }, [users]);

  return (
    <>
      {data && !isLoading && mineCards?.data.data.responseData ? (
        <Kanban
          cards={mineCards?.data.data.responseData}
          workflowSteps={data.data.data.responseData
            .filter((el) => !el.is_completed)
            .sort((a, b) => a.step_sequence - b.step_sequence)}
        />
      ) : (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
    </>
  );
}

export default UsersKanban;
