import React from "react";

import AssignBoardOwner from "./AssignBoardOwner";
import UpdateBoardName from "./UpdateName";
import OwnerLogo from "./OwnerLogo";

function BoardName({ boardName }) {
  return (
    <>
      <AssignBoardOwner boardName={boardName} />
      <UpdateBoardName boardName={boardName} />
      {/* <OwnerLogo boardName={boardName} /> */}
    </>
  );
}

export default BoardName;
