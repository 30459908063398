import React from "react";
import Loader from "../../../components/Loader";
import { useBoards } from "../../../utils/reactQuery/boards";
import BoardList from "./BoardList";
import { useDispatch } from "react-redux";
import { setActiveBoards } from "../../../redux/ActiveBoardSlice";

function Navigation({ boards, isLoading, isError }) {
  return (
    <>
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && <BoardList boards={[]} />}
      {!isLoading && !isError && <BoardList boards={boards} />}
    </>
  );
}

export default Navigation;
