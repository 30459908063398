import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  Grid,
  Stack,
  styled,
  Typography,
  useScrollTrigger,
} from "@mui/material";

import CreateBoard from "../../../dashboard/components/CreateBoard";
import DialogCreateBoard from "../../../dashboard/components/DialogCreateBoard";
import Vector from "../../../../styles/svg/create-board.svg";
import FilterArchive from "./FilterArchive";
import { useResponsive } from "../../../../hooks/useResponsive";
import filterIcon from "../../../../styles/svg/epic-icon.svg";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { searchCard } from "../../../../redux/SearchSlice";
import GlobalSearch from "../../../../components/Search";

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 250,
  transition: "transform 1s ease-in-out",
}));

function Header() {
  const [open, setOpen] = React.useState(false);
  const isMobile = useResponsive("down", "md");
  const [filterDrawer, setFilterDrawer] = useState(false);
  const trigger = useScrollTrigger();
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const value = event.target.value.trimStart();
    setInputValue(value);
    debouncedSearch(value);
  };
  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchCard(value));
    }, 300) // 300ms delay
  ).current;

  useEffect(() => {
    return () => {
      dispatch(searchCard(""));
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <Grid
          container
          xs={12}
          className="remove-container-width fixed-ui"
          sx={{ top: trigger ? "0px" : isMobile ? "51px" : "" }}
          justifyContent={"center"}
          boxShadow={" 0px 1px 7px rgba(0, 0, 0, 0.3)"}
          pb={"16px !important"}
          pt={trigger ? "24px !important" : "20px !important"}
          alignItems={"center"}>
          <Grid
            item
            xs={6}
            md={12}
            lg={3}
            className="d-flex align-items-center">
            <Typography fontWeight={600}>Board Management</Typography>
          </Grid>
          <Grid item xs={6} md={7} lg={9}>
            <Stack direction="row" justifyContent="end">
              <img
                src={filterIcon}
                alt="filter"
                style={{ marginRight: "8px" }}
                onClick={() => setFilterDrawer(true)}
              />
            </Stack>{" "}
          </Grid>
        </Grid>
      ) : (
        <div className="d-flex justify-content-space-between mb-4">
          <p className="admin-header-heading">Board Management</p>
          <div className="d-flex align-items-center ">
            <div className="d-flex align-items-center mr-2">
              <p className="mr-1 filter-heading">Filter by Status:</p>
              <FilterArchive />
            </div>
            <div className="mr-2">
              <GlobalSearch
                placeholder={"Search Board"}
                searchText={inputValue}
                handleChange={handleChange}
              />
            </div>
            <div>
              <Button
                onClick={handleClickOpen}
                className="theme-bg-color capitalize"
                variant="contained"
                startIcon={<img src={Vector} />}>
                Create Board
              </Button>
              {open && (
                <DialogCreateBoard open={open} handleClose={handleClose} />
              )}
            </div>
          </div>
        </div>
      )}
      {filterDrawer && (
        <Drawer
          open={filterDrawer}
          anchor="right"
          onClose={() => setFilterDrawer(false)}>
          <AnimatedDrawerContent
            style={{
              transform: filterDrawer ? "translateX(0)" : "translateX(100%)",
            }}>
            <Box p={1}>
              <div className="mt-2">
                <p className="filter-text">Filter by Status:</p>
                <div className="mt-2">
                  <FilterArchive />
                </div>
              </div>
              <div className="mt-4">
                <GlobalSearch
                  placeholder={"Search Board"}
                  searchText={inputValue}
                  handleChange={handleChange}
                />
              </div>

              <div className="mt-4">
                <div className="mt-2 d-flex justify-content-end">
                  <Button
                    onClick={handleClickOpen}
                    className="theme-bg-color capitalize"
                    variant="contained"
                    startIcon={<img src={Vector} />}>
                    Create Board
                  </Button>
                  {open && (
                    <DialogCreateBoard open={open} handleClose={handleClose} />
                  )}
                </div>
              </div>
            </Box>
          </AnimatedDrawerContent>
        </Drawer>
      )}
    </>
  );
}

export default Header;
