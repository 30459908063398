import React from "react";
import Avatar from "../../../../components/Avatar";
import { createAvtarName, fixedDigitsAfterDecimal, hasImage } from "../../../../utils/helpers";
import { Grid, Stack, Tooltip } from "@mui/material";
import moment from "moment";
import CustomizedProgressBars from "../../../board/components/progressBar/ProgressBar";
import { useNavigate } from "react-router-dom";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import lateIcon from "../../../../styles/svg/warning-icon.svg";
import { useResponsive } from "../../../../hooks/useResponsive";

function Item({ worklog }) {
  const navigate = useNavigate();
  const [data, updateSearchParams] = useCustomSearchParams("card_id");
  const isMobile = useResponsive("down", "md");

  const openBoard = () => {
    navigate(`/board/${worklog?.cardlog?.card?.board_id}`);
  };

  const localCreatedAt = moment.utc(worklog?.cardlog?.created_at).local();
  const localDateTime = moment.utc(worklog?.cardlog?.date_time).local();

  // Function to check if two moments are on the same day
  function isSameDay(moment1, moment2) {
    return moment1.isSame(moment2, "day");
  }
 
  // Compare the dates
  const sameDay = isSameDay(localCreatedAt, localDateTime);
  return (
    <>
      {worklog ? (
        <Grid
          container
          spacing={2}
          px={isMobile && "5px"}
          sx={{ backgroundColor: "#fff", borderRadius: "4px", width: "100%" }}
          className="worklog-item d-flex justify-content-space-between"
        >
          {isMobile ? (
            <Grid
              item
              xs={12}
              md={9}
              sx={{
                paddingLeft: "4px !important",
                paddingRight: "4px !important",
              }}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                flexWrap={"wrap"}
              >
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Avatar
                    // key={`user-avtar-${el.user_id}`}
                    username={`${worklog?.cardlog?.user?.first_name} ${worklog?.cardlog?.user?.last_name}`}
                    user_image={worklog?.cardlog?.user?.user_image}
                    title={`${worklog?.cardlog?.user?.first_name} ${worklog?.cardlog?.user?.last_name}`}
                    className="user-avtar-dropdown user-avatar mr-8"
                    style={
                      !hasImage(worklog?.cardlog?.user?.user_image) && {
                        bgcolor:
                          worklog?.cardlog?.user?.profile_color?.background,
                        color: worklog?.cardlog?.user?.profile_color?.text,
                      }
                    }
                  >
                    {createAvtarName(
                      `${worklog?.cardlog?.user?.first_name} ${worklog?.cardlog?.user?.last_name}` ||
                        ""
                    )}
                  </Avatar>
                  <div className="d-flex align-items-center">
                    <Tooltip title={worklog?.board_name}>
                      <a
                        className="board-anquor cursor-pointer"
                        onClick={openBoard}
                      >
                        {`${worklog?.key}`.toUpperCase()}
                      </a>
                    </Tooltip>
                    -
                    <a
                      className="board-anquor cursor-pointer mr-1"
                      onClick={() => {
                        updateSearchParams(
                          "card_id",
                          worklog?.cardlog?.card?.card_id
                        );
                      }}
                    >
                      {worklog?.cardlog?.card?.card_id}:
                    </a>
                  </div>
                  {worklog.epic_name && (
                    <div className="card-epic-tag mr-1">
                      <p className="card-epic-tag-text">{worklog?.epic_name}</p>
                    </div>
                  )}
                </Stack>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={3}
                  justifyContent={"end"}
                  sx={{ marginLeft: "auto", alignSelf: "start" }}
                >
                  <p className="hours">{fixedDigitsAfterDecimal(worklog?.cardlog?.hours_spent)} Hour</p>
                  <p className="worklog-status">{worklog?.workflow_step_name}</p>
                </Stack>
              </Stack>
            </Grid>
          ) : (
            <Grid item xs={12} md={9}>
              <div className="d-flex w-100">
                <Avatar
                  // key={`user-avtar-${el.user_id}`}
                  username={`${worklog?.cardlog?.user?.first_name} ${worklog?.cardlog?.user?.last_name}`}
                  user_image={worklog?.cardlog?.user?.user_image}
                  title={`${worklog?.cardlog?.user?.first_name} ${worklog?.cardlog?.user?.last_name}`}
                  className="user-avtar-dropdown user-avatar mr-8"
                  style={
                    !hasImage(worklog?.cardlog?.user?.user_image) && {
                      bgcolor: worklog?.cardlog?.user?.profile_color?.background,
                      color: worklog?.cardlog?.user?.profile_color?.text,
                    }
                  }
                >
                  {createAvtarName(
                    `${worklog?.cardlog?.user?.first_name} ${worklog?.cardlog?.user?.last_name}` ||
                      ""
                  )}
                </Avatar>
                <div className="w-100">
                  <div className="d-flex justify-content-space-between align-items-baseline">
                    <div className="d-flex align-items-baseline">
                      <Tooltip title={worklog?.board_name}>
                        <a
                          className="board-anquor cursor-pointer"
                          onClick={openBoard}
                        >
                          {`${worklog?.key}`.toUpperCase()}
                        </a>
                      </Tooltip>
                      -
                      <a
                        className="board-anquor cursor-pointer mr-1"
                        onClick={() => {
                          updateSearchParams(
                            "card_id",
                            worklog?.cardlog?.card?.card_id
                          );
                        }}
                      >
                        {worklog?.cardlog?.card?.card_id}:
                      </a>
                      <p className="worklog-title mr-2">
                        {worklog?.cardlog?.card?.title}
                      </p>
                      {worklog?.epic_name && (
                        <div className="card-epic-tag mr-2">
                          <p className="card-epic-tag-text">
                            {worklog?.epic_name}
                          </p>
                        </div>
                      )}
                    </div>
                    <p className="hours min-width-60">{fixedDigitsAfterDecimal(worklog?.cardlog?.hours_spent)} Hour</p>
                  </div>
                  <p
                    className="worklog-worklog"
                    dangerouslySetInnerHTML={{
                      __html: worklog?.cardlog?.work_performed,
                    }}
                  ></p>
                </div>
              </div>
            </Grid>
          )}
          {isMobile ? (
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                paddingLeft: "4px !important",
                paddingRight: "4px !important",
              }}
            >
              <p className="worklog-title mr-2 ml-2">
                {worklog?.cardlog?.card?.title}
              </p>
              <p
                className="worklog-worklog ml-4"
                dangerouslySetInnerHTML={{
                  __html: worklog?.cardlog?.work_performed,
                }}
              ></p>
              <div className="ml-2 mr-2 mt-2">
                <p className="worklog-date-time">
                  {!sameDay && <img src={lateIcon} className="late-icon" />}
                  {moment
                    .utc(worklog?.cardlog?.date_time)
                    .local()
                    .format("MMMM D, YYYY [at] h:mm A")}
                </p>
                <CustomizedProgressBars
                  allocatedHours={worklog?.cardlog?.card?.allocated_hours}
                  hoursSpent={fixedDigitsAfterDecimal(worklog?.cardlog?.hours_spent)}
                />
                <p className="created-date-worklog">
                  {`Last modified ${moment
                    .utc(worklog?.cardlog?.modified_at)
                    .local()
                    .format("MMMM D, YYYY [at] h:mm A")}`}
                </p>
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} md={3}>
              <div className="right-details">
                <div className="d-flex justify-content-end">
                  <p className="worklog-status">{worklog?.workflow_step_name}</p>
                </div>
                <p className="worklog-date-time">
                  {!sameDay && (
                    <img src={lateIcon} className="late-icon" alt="not found" />
                  )}
                  {moment
                    .utc(worklog?.cardlog?.date_time)
                    .local()
                    .format("MMMM D, YYYY [at] h:mm A")}
                </p>
                <CustomizedProgressBars
                  allocatedHours={worklog?.cardlog?.card?.allocated_hours}
                  hoursSpent={fixedDigitsAfterDecimal(worklog?.cardlog?.hours_spent)}
                />
                <p className="created-date-worklog">
                  {`Last modified ${moment
                    .utc(worklog?.cardlog?.modified_at)
                    .local()
                    .format("MMMM D, YYYY [at] h:mm A")}`}
                </p>
              </div>
            </Grid>
          )}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default Item;
