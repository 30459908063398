import React from "react";
import Divider from "@mui/material/Divider";

import VectorArchived from "../../../styles/svg/Vector-archived.svg";
import Vector from "../../../styles/svg/Vector.svg";
import AccordionNav from "./Accordion";
import CreateBoard from "./CreateBoard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeExpand } from "../../../redux/boardListSlice";
import { isAdmin } from "../../../utils/constants";
import MyTask from "../../myTask";

function BoardList({ boards }) {
  const dispatch = useDispatch();
  // Redux state
  const expanded = useSelector((state) => state.boardList.expanded);
  const searchBoard = useSelector((state) => state.search.boardSearchText);

  const handleChange = (panel) => (event, isExpanded) => {
    event.stopPropagation();
    dispatch(changeExpand(panel, isExpanded));
  };

  return (
    <>
      <div
        className={`sidebar-board-list ${
          isAdmin()
            ? "sidebar-board-list-for-create-button"
            : "sidebar-board-list-for-not-create-button"
        }`}
      >
        <MyTask />

        <Divider />
        <AccordionNav
          expanded={expanded.includes("panel1") || expanded === "allPanel"}
          onChange={handleChange("panel1")}
          heading={"My Boards"}
          icon={Vector}
          listing={
            boards
              ? boards
                  .filter(
                    (el) => !el.is_archive && el.is_super_admin_and_board_user
                  )
                  .sort(function (a, b) {
                    return a.board_name.localeCompare(b.board_name);
                  })
                  .filter((el) =>
                    el.board_name.toLowerCase().includes(searchBoard)
                  )
              : []
          }
          defaultExpanded={true}
          sx={{
            marginBottm: "0px",
          }}
          uniqueKey={"active-projects"}
        />
        {isAdmin() ? (
          <>
            <Divider />
            <AccordionNav
              expanded={expanded.includes("panel3") || expanded === "allPanel"}
              onChange={handleChange("panel3")}
              heading={"All Boards"}
              icon={Vector}
              listing={
                boards
                  ? boards
                      .filter((el) => !el.is_archive)
                      .sort(function (a, b) {
                        return a.board_name.localeCompare(b.board_name);
                      })
                      .filter((el) =>
                        el.board_name.toLowerCase().includes(searchBoard)
                      )
                  : []
              }
              defaultExpanded={true}
              sx={{
                marginBottm: "0px",
              }}
              uniqueKey={"active-projects"}
            />
          </>
        ) : (
          ""
        )}
        <Divider />

        <AccordionNav
          expanded={expanded.includes("panel2") || expanded === "allPanel"}
          onChange={handleChange("panel2")}
          heading={"Archived Boards"}
          icon={VectorArchived}
          listing={
            boards
              ? boards
                  .filter((el) => el.is_archive)
                  .sort(function (a, b) {
                    return a.board_name.localeCompare(b.board_name);
                  })
                  .filter((el) =>
                    el.board_name.toLowerCase().includes(searchBoard)
                  )
              : []
          }
          uniqueKey={"inactive-projects"}
        />
      </div>
      {isAdmin() && <CreateBoard />}
    </>
  );
}

export default BoardList;
