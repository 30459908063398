import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ImportCardDataGrid from "./ImportCardDataGrid";
import MuiSelectDropdown from "./MuiSelectDropdown";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { createCardsFromCSV } from "../../../../../sevices/apiCards";
import { useResponsive } from "../../../../../hooks/useResponsive";
export default function ImportCardFromCSV({
  openImportCard,
  closeImportCard,
  data,
  closeCSV,
}) {
  const [userId, setUserId] = useState(null);
  const [isValidForSubmit, setIsValidForSubmit] = useState(true);
  const { id } = useParams();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useResponsive("down", "md");
  const handleClose = () => {
    closeImportCard(false);
  };

  const handleUserId = (Id) => {
    setUserId(Id);
  };

  const formatDescriptionAsHTML = (description) => {
    // Here you can customize the HTML formatting as needed
    return `<p>${description.replace(/\n/g, "</p><p>")}</p>`;
  };

  const { mutate: createCardsFromCSVMutate } = useMutation({
    mutationFn: createCardsFromCSV,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`boards`],
      });

      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
      closeImportCard(false);
      closeCSV(false);
      toast.success(res.data.message);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
    },
  });

  const handleSubmit = () => {
    if (isValidForSubmit) {
      createCardsFromCSVMutate({
        assigned_to_user_id: userId,
        board_id: id,
        cards_info: data.map((item) => ({
          title: item.title,
          description: formatDescriptionAsHTML(item.description),
          allocated_hours: item.allocated_hours ? item.allocated_hours : null,
        })),
      });
    } else {
      setIsValidForSubmit(true);
      toast.error("Please fix the errors before submitting.");
    }
  };

  return (
    <React.Fragment>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderRadius: isMobile ? "0px" : "8px",
              width: "749px",
            },
          },
        }}
        fullScreen={fullScreen}
        open={openImportCard}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="importCard-title" id="responsive-dialog-title">
          {"Import Tasks"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 10,
            top: isMobile ? 1 : 8,
            padding: "8px",
            color: (theme) => theme.palette.grey[500],
            "&:hover": {},
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            paddingBottom: "0px",
          }}
        >
          <MuiSelectDropdown handleUserId={handleUserId} />
          <ImportCardDataGrid
            data={data}
            setIsValidForSubmit={setIsValidForSubmit}
          />
        </DialogContent>
        <DialogActions
          sx={{
            paddingTop: "0px",
            paddingRight: "20px",
            paddingBottom: "20px",
          }}
        >
          <Button
            variant="contained"
            style={{
              borderRadius: "4px",
              backgroundColor: "#1B3E61",
              fontWeight: "700",
              fontSize: "16px",
              padding: "5.5px 18.5px",
              letterSpacing: "0px !important",
              textTransform: "capitalize",
            }}
            disabled={!isValidForSubmit ? true : false}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
