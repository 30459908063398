import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import CustomizedDialogs from "../details";

const OpenCardDetail = ({ cardsOnBoard = [] }) => {
  const [showDetails, setShowDetails] = useState(true);
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const cardId = searchParams.get("card_id");

  useEffect(() => {
    if (cardId) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  }, [cardId]);

  return (
    <div>
      {cardId && cardsOnBoard && (
        <CustomizedDialogs
          cardId={cardId}
          boardId={id}
          cardsOfSameWorkflowStepId={cardsOnBoard}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
        />
      )}
    </div>
  );
};

export default OpenCardDetail;
