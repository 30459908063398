import * as React from "react";
import { useState, useEffect } from "react";
// import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
// import Stack from '@mui/material/Stack';
import { DataGrid, gridClasses } from "@mui/x-data-grid";
// import { useDemoData } from '@mui/x-data-grid-generator';
import workflowArrow from "../../../../styles/svg/workflowArrow.svg";
import adminWorkflowEdit from "../../../../styles/svg/adminWorkflowEdit.svg";
import adminWorkflowDelete from "../../../../styles/svg/adminWorkflowDelete.svg";
import Button from "@mui/material/Button";
import { useAdminWorkflow } from "../../../../utils/reactQuery/workflows";
import RemoveStep from "./RemoveStep";
import CreateWorkflow from "./createWorkflowPopup/CreateWorkflow";
import DeleteWorkflow from "./DeleteWorkflow";
// import CreateWorkflow from './createWorkflowPopup/CreateWorkflow';
export default function WorkFlowTable() {
  const [showEditWorkflow, setShowEditWorkflow] = useState(false);
  const [editWorkflowData, setEditWorkflowData] = useState(null);
  const [workflowId, setWorkflowId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [steps, setSteps] = useState([]);
  const [sortModel, setSortModel] = useState([{ field: "col1", sort: "asc" }]);
  // const [editableData, setEditableData] = useState()
  const { adminWorkflows } = useAdminWorkflow();
  const data = adminWorkflows?.data?.data?.responseData;
  useEffect(() => {
    if (
      adminWorkflows &&
      adminWorkflows.data &&
      adminWorkflows.data.data.responseData
    ) {
      const formattedData = adminWorkflows.data.data.responseData.map(
        (workflow) => ({
          id: workflow.workflow_id,
          col1: workflow.workflow_name,
          // col2: workflow.steps.map(step => step.status,),
          col2: workflow.steps.map((step) => ({
            status: step.status,
            workflowstep_id: step.workflow_step_id,
            is_completed: step.is_completed,
            generic_step_id: step.generic_step_id,
          })),
          col3: "", // You can handle actions separately if needed
        })
      );
      setSteps(formattedData);
    }
  }, [adminWorkflows]);
  // console.log("adminWorkflow",data)

  const formateStep = (step) => {
    const newStep = step.replace("-", " ");
    return newStep.replace(" ", "-");
  };

  const renderSteps = (params) => {
    const { value } = params;
    // console.log("value",params.row)
    return (
      <div
        className="d-flex"
        style={{
          overflowX: "auto",
        }}
      >
        {value.map((step, index) => (
          <React.Fragment key={index}>
            <div className="workflow-steps">{formateStep(step.status)}</div>
            {index < value.length - 1 && (
              <img src={workflowArrow} alt="" className="stepArrow" />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  // const renderSteps = (params) => {
  //   const { value } = params;
  //   return (
  //     <div className="steps-container">
  //       {value.map((step, index) => (
  //         <React.Fragment key={index}>
  //           <div className='workflow-steps'>{step}</div>
  //           {index < value.length - 1 && <img src={workflowArrow} alt="" className='stepArrow' />}
  //         </React.Fragment>
  //       ))}
  //     </div>
  //   );
  // };
  const handleEditWorkflowClose = () => {
    setShowEditWorkflow(false);
  };
  const handleEditWorkflow = (row, id) => {
    setWorkflowId(id);
    setEditWorkflowData(row);
    setShowEditWorkflow(true);
  };
  const handleDeleteWorkflow = (id) => {
    setWorkflowId(id);
    setShowDelete(true);
  };

  const handleDeleteClose = () => {
    setShowDelete(false);
  };

  const renderAction = (params) => {
    const { id, row } = params;

    // console.log("params",params)
    return (
      <div className="d-flex ">
        <img
          src={adminWorkflowEdit}
          alt=""
          className="adminWorkflowEdit"
          onClick={() => handleEditWorkflow(row, id)}
        />
        <img
          src={adminWorkflowDelete}
          alt=""
          className="adminWorkflowDelete"
          onClick={() => handleDeleteWorkflow(id)}
        />
      </div>
    );
  };

  const columns = [
    {
      field: "col1",
      headerName: "Workflow Name",
      minWidth: 150,
      flex: 0.45,
      cellClassName: "name-wrap",
    },
    {
      field: "col2",
      headerName: "Steps",
      minWidth: 250,
      flex: 1.3,
      renderCell: renderSteps,
      disableSort: true,
      sortable: false,
    },
    {
      field: "col3",
      headerName: "Actions",
      minWidth: 100,
      flex: 0.3,
      renderCell: renderAction,
      disableSort: true,
    },
  ];

  return (
    <>
      {showDelete && (
        <DeleteWorkflow
          workflowId={workflowId}
          showDelete={showDelete}
          handleDeleteClose={handleDeleteClose}
        />
      )}

      {showEditWorkflow && steps && (
        <CreateWorkflow
          workflowId={workflowId}
          open={showEditWorkflow}
          close={handleEditWorkflowClose}
          workflowData={editWorkflowData}
          edit={true}
        />
      )}
      <Box sx={{ width: "100%" }} className="table-datagrid">
        <DataGrid
          autoHeight
          pagination={false}
          rows={steps}
          columns={columns}
          hideFooter={true}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          getRowId={(row) => row.id}
          classes={{
            cell: "data-grid-cell",
            columnHeader: "table-header-class",
          }}
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
              {
                outline: "none",
              },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              {
                outline: "none",
              },
          }}
        />
      </Box>
    </>
  );
}
