import { useQuery } from "@tanstack/react-query";
import { getTenantInfo } from "../../sevices/apiSystemSettings";

export function useTenant() {
  const {
    isLoading,
    data,
    error,
    isError,
  } = useQuery({
    queryKey: ["tenant-details"],
    queryFn: getTenantInfo,
  });

  return { isLoading, error, data, isError };
}