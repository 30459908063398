import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";

import mediumPriorityIcon from "../../../../../../../styles/svg/medium_priority.svg";
import highPriorityIcon from "../../../../../../../styles/svg/high_priority.svg";
import lowPriorityIcon from "../../../../../../../styles/svg/low_priority.svg";
import criticalPriorityIcon from "../../../../../../../styles/svg/critical_priority.svg";
import checkMark from "../../../../../../../styles/svg/checkMark.svg";
import AssignCard from "../../../../Kanban/AssignCard";
import EditTitle from "../../../../Kanban/EditTitle";
import CustomizedProgressBars from "../../../../progressBar/ProgressBar";
import CustomDateFormatter from "../../../../dateTimeFormateConverter/dateTimeFormateConverter";
import { formatTimeFromNow } from "../../../../../../../utils/helpers";

function CardViewTooltip({ project, boardName }) {
  const [isEditing, setIsEditing] = useState(false);

  const tooltipName = `${project?.card?.assigned_to_user?.first_name || ""} ${
    project?.card?.assigned_to_user?.last_name || ""
  }`;
  const firstName = project?.card?.assigned_to_user?.first_name[0];
  const lastName = project?.card?.assigned_to_user?.last_name[0];
  const name = `${firstName || ""}${lastName || ""}`;

  return (
    <div className="card-style">
      {project?.card?.parent_card_id && (
        <span className="related_time parent-title-id mb-2">
          {boardName}-{project?.card?.parent_card_id}
        </span>
      )}

      <div className="d-flex justify-content-space-between">
        <div className="d-flex align-items-center">
          <div className="avatar-container">
            {project?.assigned_user_last_seen && (
              <Tooltip
                title={
                  <CustomDateFormatter
                    date={project?.assigned_user_last_seen}
                  />
                }
                arrow
              >
                {/* <IconButton> */}
                <img
                  src={checkMark}
                  alt="checkMark"
                  className="cursor-pointer checkMark-position"
                />
                {/* </IconButton> */}
              </Tooltip>
            )}
            <div>
              <AssignCard
                tooltipName={tooltipName}
                project={project?.card}
                name={name}
                cardId={project?.card.card_id}
                className="cursor-default"
              />
            </div>
          </div>
          <div className="ml-1">
            <p className="d-flex align-items-center">
              {/* <img src={CheckBox} className="mr-1" />{" "} */}
              <p className="card-id-number">
                {boardName}-{project?.card?.card_id}
              </p>
            </p>
            <p className="related_time">
              {formatTimeFromNow(project?.card?.date_created)}
            </p>
          </div>
          <img
            className="priority_icon"
            src={
              project?.card?.priority === "Critical"
                ? criticalPriorityIcon
                : project?.card?.priority === "High"
                ? highPriorityIcon
                : project?.card?.priority === "Medium"
                ? mediumPriorityIcon
                : project?.card?.priority === "Low"
                ? lowPriorityIcon
                : ""
            }
            alt="priority"
          />
        </div>
      </div>
      <div className="subtasks subtasks-uline ml-44">
        {project?.sub_tasks?.length > 0 &&
          project?.sub_tasks?.map((subtask) => (
            <div
              key={subtask?.card_id}
              className="subtask d-flex align-items-center justify-content-space-between "
            >
              <div className="w-70">
                <span className="related_time">
                  {boardName}-{subtask?.card_id}
                </span>
              </div>
              <div className="related_time w-38">
                {subtask?.total_hours_spent !== null
                  ? subtask?.total_hours_spent
                  : 0}
                /
                {subtask?.allocated_hours !== null
                  ? subtask?.allocated_hours
                  : 0}
              </div>
              <div className="related_time w-23">
                {subtask?.allocated_hours && subtask?.total_hours_spent
                  ? Math.round(
                      (subtask?.total_hours_spent / subtask?.allocated_hours) *
                        100
                    )
                  : 0}
                %
              </div>
            </div>
          ))}
      </div>

      <div className="mt-2">
        <EditTitle
          project={project}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isCardTitle={true}
        />
      </div>

      <div className="d-flex">
        {project?.card?.labels?.[0] != "" && (
          <div className="d-flex">
            {project?.labels?.map((el) => (
              <div className="card-level-tag mr-1">
                <p className="card-level-tag-text">{el}</p>
              </div>
            ))}
          </div>
        )}

        {project?.card?.epic && (
          <div className="d-flex">
            {/* {project.labels.map((el) => ( */}
            <div className="card-epic-tag mr-1">
              <p className="card-epic-tag-text">
                {project?.card?.epic?.epic_name}
              </p>
            </div>
            {/* ))} */}
          </div>
        )}
      </div>
      <CustomizedProgressBars
        allocatedHours={project?.card?.allocated_hours}
        hoursSpent={project?.total_hours_spent ? project?.total_hours_spent : 0}
        showOnCardOnBoard={true}
      />
    </div>
  );
}

export default CardViewTooltip;
